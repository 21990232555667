import PropTypes from 'prop-types';

const BugRateReportItem = {
  projectKey: PropTypes.string,
  nbInternalBugs: PropTypes.number,
  internalBugRatio: PropTypes.number,
  totalTimeSpentInternalBugs: PropTypes.number,
  timeSpentInternalBugsRatio: PropTypes.number,
  nbBugs: PropTypes.number,
  bugRatio: PropTypes.number,
  totalTimeSpentBugs: PropTypes.number,
  timeSpentBugsRatio: PropTypes.number,
  nbTotalIssues: PropTypes.number,
  totalTimeSpent: PropTypes.number,
};

export default BugRateReportItem;
