import axios from 'axios';
import { pushModalMessageError } from '.';
import { startFetching, stopFetching, pushToastMessage, setRouterState } from './appActions';
import sectionsAndPages from '../helpers/sectionsAndPages';
import { SET_CLIENTS, SET_CLIENT, UPDATE_CLIENT_ARCHIVE } from './types';

export const getClients = () => async dispatch => {
  dispatch(startFetching('clients'));
  try {
    const response = await axios.get('/clients');
    dispatch({ type: SET_CLIENTS, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('clients'));
  }
};

export const getClient = id => async dispatch => {
  dispatch(startFetching('fetchClient'));
  try {
    const response = await axios.get(`/clients/${id}`);
    dispatch({ type: SET_CLIENT, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('fetchClient'));
  }
};

export const setClient = client => ({ type: SET_CLIENT, payload: client });

export const updateClient = client => async dispatch => {
  dispatch(startFetching('client'));
  try {
    await axios.put(`/clients/${client.id}`, client);
    dispatch(setRouterState(sectionsAndPages.clients));
    dispatch(pushToastMessage('updated', 'client'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('client'));
  }
};

export const updateClientArchive = client => async dispatch => {
  dispatch(startFetching('clients'));
  try {
    const response = await axios.put(`/clients/${client.id}/archive`, {});
    dispatch({ type: UPDATE_CLIENT_ARCHIVE, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('clients'));
  }
};

export const createClient = client => async dispatch => {
  dispatch(startFetching('client'));
  try {
    await axios.post('/clients', client);
    dispatch(setRouterState(sectionsAndPages.clients));
    dispatch(pushToastMessage('created', 'client'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('client'));
  }
};
