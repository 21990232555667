import PropTypes from 'prop-types';
import HarvestClient from './HarvestClient';

const HarvestProject = {
  id: PropTypes.number,
  code: PropTypes.string,
  name: PropTypes.string,
  client: PropTypes.exact(HarvestClient),
};

export default HarvestProject;
