import i18n from 'i18next';
import React from 'react';

// TODO: Decide what to do with this page
const Home = () => (
  <>
    <h1 className="view__title">{`${i18n.t('message.hello')}`}</h1>
  </>
);

export default Home;
