import axios from 'axios';
import i18n from 'i18next';
import { pushModalMessageError } from '.';
import { startFetching, stopFetching, pushToastMessage, setRouterState, pushModalMessage } from './appActions';
import {
  RESET_EMPLOYEE,
  SET_EXISTING_BAMBOO_EMPLOYEES,
  SET_EXISTING_HARVEST_EMPLOYEES,
  SET_EXISTING_AZURE_AD_EMPLOYEES,
  SET_EXISTING_GOOGLE_APPS_EMPLOYEES,
  SET_EXISTING_UBIK_EMPLOYEES,
  REMOVE_EMPLOYEE,
  SET_EMPLOYEES,
  SET_EMPLOYEE,
} from './types';
import sectionsAndPages from '../helpers/sectionsAndPages';

export const getEmployees = () => async dispatch => {
  dispatch(startFetching('employees'));
  try {
    const response = await axios.get('/employees');
    dispatch({ type: SET_EMPLOYEES, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('employees'));
  }
};

export const createEmployee = values => async dispatch => {
  try {
    const { data } = await axios.post('/employees', values);
    dispatch({ type: SET_EMPLOYEE, payload: data.result });
    dispatch(setRouterState(sectionsAndPages.employees, { employeeId: data.result.id }));
    dispatch(pushToastMessage('created', 'employee'));
    if (data.errors.length) {
      dispatch(pushModalMessage(data.errors.reduce((string, curr) => `${string}\n ${curr}`), true));
    }
  } catch (error) {
    if (error.response && error.response.status === 400 && error.response.data === 'emailAlreadyExists') {
      dispatch(pushModalMessage(i18n.t(`employee.${error.response.data}`), true));
    } else {
      dispatch(pushModalMessageError(error));
    }
  }
};

export const removeEmployee = id => async dispatch => {
  try {
    await axios.post(`/employees/${id}/status`);
    dispatch({
      type: REMOVE_EMPLOYEE,
      payload: id,
    });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  }
};

export const getEmployee = id => async dispatch => {
  dispatch(startFetching('fetchEmployee'));
  try {
    const response = await axios.get(`/employees/${id}`);
    dispatch({ type: SET_EMPLOYEE, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('fetchEmployee'));
  }
};

export const resetEmployee = () => async dispatch => dispatch({ type: RESET_EMPLOYEE });

export const getExistingBambooEmployees = () => async dispatch => {
  dispatch(startFetching('employee-emails-bamboo'));
  try {
    const response = await axios.get('/employees/bamboo/emails');
    dispatch({ type: SET_EXISTING_BAMBOO_EMPLOYEES, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('employee-emails-bamboo'));
  }
};

export const getExistingHarvestEmployees = () => async dispatch => {
  dispatch(startFetching('employee-emails-harvest'));
  try {
    const response = await axios.get('/employees/harvest/emails');
    dispatch({ type: SET_EXISTING_HARVEST_EMPLOYEES, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('employee-emails-harvest'));
  }
};

export const getExistingAzureADEmployees = () => async dispatch => {
  dispatch(startFetching('employee-emails-azure-ad'));
  try {
    const response = await axios.get('/employees/azure-ad/emails');
    dispatch({ type: SET_EXISTING_AZURE_AD_EMPLOYEES, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('employee-emails-azure-ad'));
  }
};

export const getExistingGoogleAppsEmployees = () => async dispatch => {
  dispatch(startFetching('employee-emails-google-apps'));
  try {
    const response = await axios.get('/employees/google-apps/emails');
    dispatch({ type: SET_EXISTING_GOOGLE_APPS_EMPLOYEES, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('employee-emails-google-apps'));
  }
};

export const getExistingUbikEmployees = () => async dispatch => {
  dispatch(startFetching('employee-emails-ubik'));
  try {
    const response = await axios.get('/employees/ubik/emails');
    dispatch({ type: SET_EXISTING_UBIK_EMPLOYEES, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('employee-emails-ubik'));
  }
};

export default getEmployees;
