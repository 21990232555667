import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import Tooltip from '@vooban/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfirmModal } from '@vooban/modals';
import _orderBy from 'lodash.orderby';
import TimeComparisonType from '../../../types/TimeComparison';
import User from '../../../types/User';
import TimeComparisonListRow from './TimeComparisonListRow';

class TimeComparisonList extends PureComponent {
  static propTypes = {
    comparedProjectName: PropTypes.string.isRequired,
    onSendEmail: PropTypes.func.isRequired,
    timeComparisons: PropTypes.arrayOf(PropTypes.exact(TimeComparisonType)).isRequired,
    administrator: PropTypes.shape(User).isRequired,
  };

  confirmSendEmailToAllModalRef = React.createRef();

  get unbalancingComparisons() {
    return this.props.timeComparisons.filter(
      timeComparison => this.calculateDifference(timeComparison.jiraHours, timeComparison.harvestHours) > 0
    );
  }

  calculateDifference = (jiraHours, harvestHours) => Math.abs(jiraHours.toFixed(2) - harvestHours.toFixed(2));

  handleSendEmailToAllClick = () => this.confirmSendEmailToAllModalRef.current.open();

  handleIndividualEmailClick = timeComparison => {
    const emailInfo = [
      {
        employeeEmail: timeComparison.email,
        employeeName: timeComparison.name,
        projectName: this.props.comparedProjectName,
        jiraHours: timeComparison.jiraHours.toFixed(2),
        harvestHours: timeComparison.harvestHours.toFixed(2),
        administrator: this.props.administrator.name,
      },
    ];
    this.props.onSendEmail(emailInfo);
  };

  handleConfirmEmailToAllClick = () => {
    const emailInfos = this.unbalancingComparisons.map(timeComparison => ({
      employeeEmail: timeComparison.email,
      employeeName: timeComparison.name,
      projectName: this.props.comparedProjectName,
      jiraHours: timeComparison.jiraHours.toFixed(2),
      harvestHours: timeComparison.harvestHours.toFixed(2),
      administrator: this.props.administrator.name,
    }));

    this.props.onSendEmail(emailInfos);
  };

  render() {
    return (
      <>
        <section className="grid__item -span-8">
          <div className="tile">
            <div className="grid">
              <h6 className="grid__heading -span-4">{i18n.t('timeComparison.name')}</h6>
              <h6 className="grid__heading -span-2">{i18n.t('timeComparison.jiraHours')}</h6>
              <h6 className="grid__heading -span-2">{i18n.t('timeComparison.harvestHours')}</h6>
              <h6 className="grid__heading -span-2 -align-center">{i18n.t('timeComparison.gap')}</h6>
              <h6 className="grid__heading -span-1 -align-center">{i18n.t('timeComparison.balancing')}</h6>
              {this.unbalancingComparisons && (
                <h6 className="grid__heading -span-1 -align-center">
                  <Tooltip content="Send email to all unbalancing">
                    <button type="button" onClick={this.handleSendEmailToAllClick}>
                      <FontAwesomeIcon icon="envelope" className="envelope" />
                    </button>
                  </Tooltip>
                </h6>
              )}
              {_orderBy(this.props.timeComparisons, [tc => tc.email]).map(timeComparison => (
                <TimeComparisonListRow
                  key={timeComparison.email}
                  timeComparison={timeComparison}
                  timeDifference={this.calculateDifference(timeComparison.jiraHours, timeComparison.harvestHours)}
                  onSendEmail={this.handleIndividualEmailClick}
                />
              ))}
            </div>
          </div>
        </section>
        <ConfirmModal
          ref={this.confirmSendEmailToAllModalRef}
          onConfirm={this.handleConfirmEmailToAllClick}
          title={i18n.t('timeComparison.confirmSendToAll')}
          cancelLabel={i18n.t('button.no')}
          confirmLabel={i18n.t('button.yes')}
        />
      </>
    );
  }
}

export default TimeComparisonList;
