import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from '@vooban/loader';
import ClientInfo from '../client/ClientInfo';
import ContactList from '../contact/ContactList';
import {
  getEmployees,
  getClient,
  getContactsByClientId,
  deleteContact,
  createContact,
  updateContact,
  createClient,
  updateClient,
  setClient,
} from '../../actions';
import { isFetchingEntities } from '../../helpers';
import { getEmployeesSelector, getContactsSelector } from '../../selectors/selectors';
import { Client, Employee, Contact } from '../../types';

class ClientDetailsPage extends React.PureComponent {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    clientId: PropTypes.string,
    client: PropTypes.exact(Client),
    contacts: PropTypes.arrayOf(PropTypes.shape(Contact)).isRequired,
    employees: PropTypes.arrayOf(PropTypes.exact(Employee)).isRequired,
    setClient: PropTypes.func.isRequired,
    getEmployees: PropTypes.func.isRequired,
    getClient: PropTypes.func.isRequired,
    createContact: PropTypes.func.isRequired,
    deleteContact: PropTypes.func.isRequired,
    updateContact: PropTypes.func.isRequired,
    getContactsByClientId: PropTypes.func.isRequired,
    createClient: PropTypes.func.isRequired,
    updateClient: PropTypes.func.isRequired,
  };

  static defaultProps = {
    clientId: '',
    client: {},
  };

  componentDidMount() {
    this.props.getEmployees();
    if (this.props.clientId) {
      this.props.getClient(this.props.clientId);
      this.props.getContactsByClientId(this.props.clientId);
    }
  }

  componentWillUnmount() {
    this.props.setClient([]);
  }

  handleClientSave = editedClient => this.props.updateClient(editedClient);

  handleClientCreate = newClient => this.props.createClient(newClient);

  handleContactSave = contact => this.props.createContact({ ...contact, clientId: this.props.clientId });

  handleContactDelete = contact => this.props.deleteContact(contact);

  handleContactUpdate = contact => this.props.updateContact(contact);

  render() {
    if (this.props.isFetching) return <Loader />;
    return (
      <>
        <ClientInfo
          clientId={this.props.clientId}
          client={this.props.client}
          employees={this.props.employees}
          onSave={this.handleClientSave}
          onCreate={this.handleClientCreate}
        />
        {this.props.clientId && (
          <div className="grid">
            <div className="grid__item -span-8">
              <ContactList
                contacts={this.props.contacts}
                onDelete={this.handleContactDelete}
                onSave={this.handleContactSave}
                onUpdate={this.handleContactUpdate}
                noTop={false}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { clientId } = props.transition.params();
  return {
    isFetching: isFetchingEntities(state, ['client', 'contacts', 'fetchClient']),
    client: state.client.client,
    clientId,
    contacts: getContactsSelector(state),
    employees: getEmployeesSelector(state),
  };
};

export default connect(
  mapStateToProps,
  {
    getEmployees,
    getClient,
    getContactsByClientId,
    createClient,
    createContact,
    deleteContact,
    updateContact,
    updateClient,
    setClient,
  }
)(ClientDetailsPage);
