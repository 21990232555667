import { AbilityBuilder } from '@casl/ability';
import sectionsAndPages from '../../sectionsAndPages';
import commonActions from '../abilityActions';
import { isInRole } from '../abilityHelpers';
import userRoles from '../userRoles';

const { employeeOvertimeReport } = sectionsAndPages;

export default function getRules(user) {
  const { rules, can } = AbilityBuilder.extract();
  const { navigateTo, getList } = commonActions;
  const { accountant, admin, projectManager, teamLead } = userRoles;

  if (isInRole(user, [accountant, admin, projectManager, teamLead])) {
    can(navigateTo, employeeOvertimeReport);
    can(getList, employeeOvertimeReport);
  }

  return rules;
}
