import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
    tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
    className: PropTypes.string,
    tabClassName: PropTypes.string,
    keepActiveTab: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    tabClassName: '',
    keepActiveTab: false,
  };

  state = {
    activeTab: 0,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.tabs !== this.props.tabs) {
      const activeTab = this.props.keepActiveTab ? this.state.activeTab : 0;
      this.handleClickTabItem(activeTab);
    }
  }

  handleClickTabItem = activeTab => this.setState({ activeTab });

  render() {
    const { activeTab } = this.state;

    return (
      <>
        <ol className={`tabs ${this.props.className}`}>
          {this.props.tabs.map((tab, i) => {
            const limitedLength = 32;
            const toShow = tab.length >= limitedLength ? `${tab.substring(0, limitedLength)}...` : tab;
            return (
              <button
                type="button"
                key={tab}
                className={`tabs__tab ${this.props.tabClassName} ${activeTab === i && '-active'}`}
                onClick={() => this.handleClickTabItem(i)}>
                {toShow}
              </button>
            );
          })}
        </ol>
        {this.props.children[activeTab]}
      </>
    );
  }
}

export default Tabs;
