import React from 'react';
import i18n from 'i18next';

const OvertimeDetailsHeader = () => (
  <div className="grid">
    <aside className="grid__item -span-6">
      <h1 className="view__title ">{i18n.t('title.overtimeRequest')}</h1>
    </aside>
    <aside className="grid__item -span-6 -align-right">
      <button type="submit" className="button -primary" form="overtimeRequestForm">
        {i18n.t('button.save')}
      </button>
    </aside>
  </div>
);

export default OvertimeDetailsHeader;
