import _keyBy from 'lodash.keyby';
import _omit from 'lodash.omit';
import PropTypes from 'prop-types';
import {
  CLEAR_TIME_COMPARISONS,
  GENERATE_BUG_RATE_REPORT,
  SET_ISSUE_SUGGESTIONS,
  SET_TIME_COMPARISONS,
  SET_TIME_ENTRIES,
  SET_TIME_SPENT,
  PUSH_SPENT_ON_ISSUE_QUERY,
  SET_SPENT_ON_ISSUE_QUERIES,
  REMOVE_SPENT_ON_ISSUE_QUERY,
} from '../actions/types';
import withPropTypes from '../hoc/withPropTypes';
import { HarvestTimesheet, HarvestWeek } from '../types';
import TimeComparison from '../types/TimeComparison';
import TimeSpent from '../types/TimeSpent';
import IssueSuggestion from '../types/IssueSuggestion';
import BugRateReportItem from '../components/bugRateReport/BugRateReportItem';
import SpentOnIssueQuery from '../types/SpentOnIssueQuery';

const schema = PropTypes.shape({
  comparedProjectName: PropTypes.string,
  harvestWeeksByEmail: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.exact(HarvestWeek))).isRequired,
  issueSuggestions: PropTypes.arrayOf(PropTypes.exact(IssueSuggestion)),
  timeComparisonsByEmail: PropTypes.objectOf(PropTypes.exact(TimeComparison)).isRequired,
  timeEntriesByEmail: PropTypes.objectOf(PropTypes.exact(HarvestTimesheet)).isRequired,
  timeSpentHistory: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.exact(TimeSpent))),
  bugRateReportItems: PropTypes.arrayOf(PropTypes.exact(BugRateReportItem)).isRequired,
  spentOnIssueQueriesById: PropTypes.objectOf(PropTypes.exact(SpentOnIssueQuery)).isRequired,
});

const initialState = {
  comparedProjectName: '',
  harvestWeeksByEmail: {},
  issueSuggestions: [],
  timeComparisonsByEmail: {},
  timeEntriesByEmail: {},
  timeSpentHistory: [],
  bugRateReportItems: [],
  spentOnIssueQueriesById: {},
};

const ReportingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ISSUE_SUGGESTIONS:
      return { ...state, issueSuggestions: payload };
    case SET_TIME_ENTRIES: {
      if (payload.length === 0) {
        return { ...state };
      }

      const harvestWeeksByEmail = { ...state.harvestWeeksByEmail };
      payload.forEach(entry => {
        if (!harvestWeeksByEmail[entry.userEmail]) {
          harvestWeeksByEmail[entry.userEmail] = entry.harvestWeeks;
        } else {
          harvestWeeksByEmail[entry.userEmail] = [...harvestWeeksByEmail[entry.userEmail], ...entry.harvestWeeks];
        }
      });

      const updatedPayload = payload.map(timeEntry => {
        const { harvestWeeks, ...newTimeEntry } = timeEntry;
        return newTimeEntry;
      });

      return {
        ...state,
        timeEntriesByEmail: { ...state.timeEntriesByEmail, ..._keyBy(updatedPayload, 'userEmail') },
        harvestWeeksByEmail: { ...harvestWeeksByEmail },
      };
    }
    case SET_TIME_SPENT:
      return { ...state, timeSpentHistory: [...payload, ...state.timeSpentHistory] };
    case SET_TIME_COMPARISONS:
      return { ...state, timeComparisonsByEmail: payload.timeComparisons, comparedProjectName: payload.projectName };
    case CLEAR_TIME_COMPARISONS:
      return { ...state, timeComparisonsByEmail: initialState.timeComparisonsByEmail };
    case GENERATE_BUG_RATE_REPORT:
      return { ...state, bugRateReportItems: payload };
    case PUSH_SPENT_ON_ISSUE_QUERY:
      return { ...state, spentOnIssueQueriesById: { ...state.spentOnIssueQueriesById, [payload.id]: payload } };
    case SET_SPENT_ON_ISSUE_QUERIES:
      return { ...state, spentOnIssueQueriesById: _keyBy(payload, 'id') };
    case REMOVE_SPENT_ON_ISSUE_QUERY:
      return { ...state, spentOnIssueQueriesById: _omit(state.spentOnIssueQueriesById, payload) };
    default:
      return state;
  }
};

export default withPropTypes('ReportingReducer', schema)(ReportingReducer);
