import _orderBy from 'lodash.orderby';
import _isEmpty from 'lodash.isempty';
import { InputReactSelect, InputText } from '@vooban/inputs';
import { ConfirmModal } from '@vooban/modals';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { Employee, Team, ProjectMapping } from '../../types';

class TeamDetails extends React.PureComponent {
  static propTypes = {
    employees: PropTypes.arrayOf(PropTypes.exact(Employee)).isRequired,
    projectMapping: PropTypes.exact(ProjectMapping),
    onDeleteTeamClick: PropTypes.func.isRequired,
    onUpdateTeamClick: PropTypes.func.isRequired,
    team: PropTypes.exact(Team),
    onReturnToProjectClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    projectMapping: {},
    team: {},
  };

  state = {
    teamName: '',
    teamLeadEmployee: null,
  };

  teamDeletionModal = React.createRef();

  static getDerivedStateFromProps(nextProps, prevState) {
    let derivedState = null;

    if (!prevState.teamName && !_isEmpty(nextProps.team)) {
      derivedState = { teamName: nextProps.team.name };
    }

    if (!prevState.teamLeadEmployee && !_isEmpty(nextProps.employees)) {
      const teamLeadEmployee = nextProps.employees.find(employee => employee.id === nextProps.team.teamLeadEmployeeId);
      derivedState = { ...derivedState, teamLeadEmployee };
    }

    if (!prevState.projectMapping && !_isEmpty(nextProps.projectMappings)) {
      const projectMapping = nextProps.projectMappings.find(project => project.id === nextProps.team.projectMappingId);
      derivedState = { ...derivedState, projectMapping };
    }

    return derivedState;
  }

  employeesToOptions = employees =>
    _orderBy(
      employees.map(o => ({ key: o.id, label: `${o.firstName} ${o.lastName} (${o.workEmail})`, value: o })),
      'value.firstName'
    );

  handleTeamLeadChange = teamLeadEmployee => this.setState({ teamLeadEmployee });

  handleTeamNameChange = e => this.setState({ teamName: e.target.value });

  handleUpdateTeamClick = () => {
    this.props.onUpdateTeamClick({
      ...this.props.team,
      name: this.state.teamName,
      teamLeadEmployeeId: this.state.teamLeadEmployee.id,
      projectMappingId: this.props.projectMapping.id,
    });
  };

  handleDeleteTeamClick = () => this.props.onDeleteTeamClick(this.props.team);

  handleReturnToProjectClick = () => this.props.onReturnToProjectClick(this.props.team.projectMappingId);

  openTeamDeletionModal = e => {
    e.stopPropagation();
    this.teamDeletionModal.current.open();
  };

  render() {
    const employeeOptions = this.employeesToOptions(this.props.employees);

    return (
      <div className="grid">
        <div className="grid__row -header">
          <h1 className="view__title grid__item -span-5">
            {i18n.t('title.team')} - {this.props.team.name}
          </h1>
          <div className="grid__item -span-2 -align-right">
            <button type="button" className="button  -primary" onClick={this.handleReturnToProjectClick}>
              {i18n.t('team.returnToProject')}
            </button>
          </div>
          <div className="grid__item -span-1 -align-right">
            <button type="button" className="button  -delete" onClick={this.openTeamDeletionModal}>
              {i18n.t('team.deleteTeam')}
            </button>
          </div>
        </div>
        <div className="grid__item -span-8">
          <div className="tile">
            <section className="form__section grid">
              <div className="grid__row">
                <div className="grid__item -span-6">
                  <InputText
                    name="teamName"
                    label={i18n.t('team.name')}
                    value={this.state.teamName}
                    onChange={this.handleTeamNameChange}
                  />
                </div>
              </div>
              <div className="grid__row">
                <div className="grid__item -span-6">
                  <InputReactSelect
                    isSearchable
                    label={i18n.t('team.teamLead')}
                    name="teamLead"
                    options={employeeOptions}
                    onChange={this.handleTeamLeadChange}
                    value={this.state.teamLeadEmployee}
                  />
                </div>
              </div>
              <div className="grid__row">
                <div className="grid__item -span-6">
                  <InputText label={i18n.t('team.project')} value={this.props.projectMapping.name} disabled />
                </div>
              </div>
              <div className="grid__row">
                <div className="grid__item -span-2">
                  <button type="button" className="button -primary" onClick={this.handleUpdateTeamClick}>
                    {i18n.t('button.save')}
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>

        <ConfirmModal
          ref={this.teamDeletionModal}
          onConfirm={this.handleDeleteTeamClick}
          title={i18n.t('team.confirmRemoveTeam', { teamName: this.props.team.name })}
          cancelLabel={i18n.t('button.no')}
          confirmLabel={i18n.t('button.yes')}
        />
      </div>
    );
  }
}

export default TeamDetails;
