import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import HarvestTask from '../../../types/HarvestTask';
import TaskDetails from './TaskDetails';
import NewTask from './NewTask';

class TaskList extends React.PureComponent {
  static propTypes = {
    harvestTasks: PropTypes.arrayOf(PropTypes.exact(HarvestTask)).isRequired,
    projectTasks: PropTypes.arrayOf().isRequired,
    projectId: PropTypes.number.isRequired,
  };

  state = {
    option: null,
    taskOptions: this.props.harvestTasks.filter(
      elem => !this.props.projectTasks.find(({ harvestTaskId }) => elem.id === harvestTaskId)
    ),
  };

  onTaskNameChange = pushFn => value => {
    this.setState(prevState => ({
      ...prevState,
      taskOptions: prevState.taskOptions.filter(task => task.id !== value.id),
      option: null,
    }));
    pushFn({ harvestTaskId: value.id, name: value.name, projectId: this.props.projectId, rate: 0 });
  };

  render() {
    return (
      <div className="grid__row">
        <FieldArray
          className="grid__item -span-6 -input"
          name="projectTasks"
          render={({ push, remove }) => (
            <>
              <div className="grid__row">
                {this.props.projectTasks.map((projectTask, index) => (
                  <TaskDetails
                    key={index} //eslint-disable-line
                    removeTask={remove}
                    index={index}
                  />
                ))}
              </div>
              <div className="grid__row">
                <NewTask
                  harvestTasks={this.state.taskOptions}
                  onTaskNameChange={this.onTaskNameChange(push)}
                  selectedOption={this.state.option}
                />
              </div>
            </>
          )}
        />
      </div>
    );
  }
}

export default TaskList;
