import axios from 'axios';
import { pushModalMessageError } from '.';
import { pushToastMessage, startFetching, setRouterState, stopFetching } from './appActions';
import { PUSH_OVERTIME_REQUEST, REMOVE_OVERTIME_REQUEST, SET_OVERTIME_REQUESTS } from './types';
import sectionsAndPages from '../helpers/sectionsAndPages';

export const createOvertimeRequest = overtimeRequest => async dispatch => {
  dispatch(startFetching('overtimeRequestCreation'));
  try {
    const response = await axios.post('/overtime-requests', overtimeRequest);
    dispatch({ type: PUSH_OVERTIME_REQUEST, payload: response.data });
    dispatch(setRouterState(sectionsAndPages.overtimeRequestList));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('overtimeRequestCreation'));
  }
};

export const deleteOvertimeRequest = overtimeRequestId => async dispatch => {
  try {
    await axios.delete(`/overtime-requests/${overtimeRequestId}`);
    dispatch({ type: REMOVE_OVERTIME_REQUEST, payload: overtimeRequestId });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  }
};
export const getOvertimeRequests = () => async dispatch => {
  dispatch(startFetching('overtimeRequests'));
  try {
    const response = await axios.get('/overtime-requests/');
    dispatch({ type: SET_OVERTIME_REQUESTS, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('overtimeRequests'));
  }
};

export const treatOvertimeRequest = overtimeRequest => async dispatch => {
  dispatch(startFetching('overtimeRequests'));
  try {
    const response = await axios.put('/overtime-requests', overtimeRequest);
    dispatch({ type: PUSH_OVERTIME_REQUEST, payload: response.data });
    dispatch(pushToastMessage('changesSaved'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('overtimeRequests'));
  }
};
