import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Modal, ConfirmModal } from '@vooban/modals';
import Can from '../ability/Can';

import { ProjectPurchaseOrder } from '../../types';
import AddPurchaseOrderModal from './AddPurchaseOrderModal';
import PurchaseOrderListRow from './PurchaseOrderListRow';
import commonActions from '../../helpers/authorization/abilityActions';
import sectionsAndPages from '../../helpers/sectionsAndPages';

class ProjectPurchaseOrders extends PureComponent {
  static propTypes = {
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onFileOpen: PropTypes.func.isRequired,
    purchaseOrders: PropTypes.arrayOf(PropTypes.exact(ProjectPurchaseOrder)),
  };

  state = { purchaseOrderToDelete: {} };

  static defaultProps = {
    purchaseOrders: [],
  };

  addPurchaseOrderModalRef = React.createRef();

  deletePurchaseOrderModalRef = React.createRef();

  handleAddPurchaseOrder = () => this.addPurchaseOrderModalRef.current.open();

  handleClosePurchaseOrderModal = () => this.addPurchaseOrderModalRef.current.close();

  handleConfirmPurchaseOrderModal = purchaseOrder => {
    this.props.onAdd(purchaseOrder);
    this.addPurchaseOrderModalRef.current.close();
  };

  handleFileOpen = purchaseOrder => this.props.onFileOpen(purchaseOrder);

  handlePurchaseOrderDeleteClick = () => this.props.onDelete(this.state.purchaseOrderToDelete.id);

  handleOpenPurchaseOrderDeletionModal = purchaseOrderToDelete => {
    this.setState({ purchaseOrderToDelete });
    this.deletePurchaseOrderModalRef.current.open();
  };

  render() {
    return (
      <div className="tile -no-top">
        <header className="tile__header">
          <h3 className="view__subtitle">{i18n.t('title.purchaseOrders')}</h3>
          <button type="button" className="button -primary" onClick={this.handleAddPurchaseOrder}>
            {i18n.t('project.addPurchaseOrder')}
          </button>
        </header>

        <section className="grid">
          <h6 className="grid__heading -span-1">{i18n.t('project.poNumber')}</h6>
          <h6 className="grid__heading -span-2">{i18n.t('project.poDate')}</h6>
          <h6 className="grid__heading -span-2">{i18n.t('project.poStartDate')}</h6>
          <h6 className="grid__heading -span-2">{i18n.t('project.poEndDate')}</h6>
          <h6 className="grid__heading -span-2">{i18n.t('project.spanMD')}</h6>
          <Can I={commonActions.getPurchaseOrderCriticalData} a={sectionsAndPages.projectDetails}>
            <h6 className="grid__heading -span-2">{i18n.t('project.spanMoney')}</h6>
          </Can>
          <h6 className="grid__heading -span-1">{i18n.t('project.actions')}</h6>
          {this.props.purchaseOrders.map(po => {
            const handleDeleteClick = () => this.handleOpenPurchaseOrderDeletionModal(po);
            const handleFileOpenClick = () => this.handleFileOpen(po.receiptId);
            return (
              <PurchaseOrderListRow
                key={po.id}
                purchaseOrder={po}
                onDelete={handleDeleteClick}
                onFileOpen={handleFileOpenClick}
              />
            );
          })}
        </section>

        <Modal ref={this.addPurchaseOrderModalRef} width="medium">
          <AddPurchaseOrderModal
            onClose={this.handleClosePurchaseOrderModal}
            onConfirm={this.handleConfirmPurchaseOrderModal}
          />
        </Modal>

        <ConfirmModal
          ref={this.deletePurchaseOrderModalRef}
          onConfirm={this.handlePurchaseOrderDeleteClick}
          title={i18n.t('project.confirmRemovePurchaseOrder', {
            purchaseOrderNumber: this.state.purchaseOrderToDelete.number,
          })}
          cancelLabel={i18n.t('button.no')}
          confirmLabel={i18n.t('button.yes')}
        />
      </div>
    );
  }
}

export default ProjectPurchaseOrders;
