import jwtDecode from 'jwt-decode';

export const userRolesList = {
  administrator: 'administrator',
  companyAdministrator: 'company-administrator',
  clinician: 'clinician',
  clinicAdministrator: 'clinic-administrator',
  patient: 'patient',
  technician: 'technician',
};

export const authorizationPolicies = {
  canAccessPatientRequirement: 1,
  isClinicAdminOrAdminRequirement: 2,
  canUpdateClinicianRequirement: 3,
  hasRoleInClinicOrAdmin: 4,
};

const getGroups = () => {
  const accessToken = window.localStorage.getItem('access_token');
  if (!accessToken) return [];

  const decodedToken = jwtDecode(accessToken);

  return decodedToken['https://vooban.com/groups/'];
};

export const getAuthorizationHeader = () => {
  const accessToken = window.localStorage.getItem('access_token');
  return accessToken ? `Bearer ${accessToken}` : '';
};

export const getUser = () => {
  const userToken = window.localStorage.getItem('id_token');
  if (!userToken) return null;
  const user = jwtDecode(userToken);
  user.groups = getGroups();
  user.accessToken = window.localStorage.getItem('access_token');
  return { ...user, email: jwtDecode(user.accessToken)['https://vooban.com/email/'] };
};
