import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { InputText } from '@vooban/inputs';
import Loader from '@vooban/loader';

class PayFileConversion extends React.PureComponent {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    onFileConvert: PropTypes.func.isRequired,
  };

  state = {
    file: null,
  };

  handleFileChange = e => {
    const file = e.currentTarget.files[0];
    this.setState({ file });
    e.currentTarget.value = '';
  };

  handleConvert = () => {
    if (this.state.file) {
      this.props.onFileConvert(this.state.file);
    }
  };

  render() {
    return (
      <>
        {this.props.isFetching && <Loader />}
        <div className="grid">
          <div className="grid__item -span-8 tile">
            <div className="grid__row">
              <div className="grid__item -span-12">
                <InputText
                  name="fileName"
                  disabled
                  label={i18n.t('accounting.fileName')}
                  value={(this.state.file && this.state.file.name) || ''}
                />
              </div>
            </div>
            <div className="grid__row">
              <div className="grid__item -span-2">
                <label id="addFileLabel" htmlFor="addFile" className="button -primary">
                  <input
                    type="file"
                    name="addFile"
                    id="addFile"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={this.handleFileChange}
                    className="input__file"
                  />
                  {i18n.t('file.choose')}
                </label>
              </div>
              <div className="grid__item -span-2">
                <button
                  type="button"
                  className="button -primary"
                  disabled={!this.state.file}
                  onClick={this.handleConvert}>
                  {i18n.t('accounting.convert')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PayFileConversion;
