import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import Loader from '@vooban/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { maxUploadSizeInMB, validateUploadSize } from '../../../helpers/fileHelpers';
import Can from '../../ability/Can';
import commonActions from '../../../helpers/authorization/abilityActions';
import sectionsAndPages from '../../../helpers/sectionsAndPages';
import UploadFeedback from '../../../types/UploadFeedback';

class PayStubsUpload extends React.PureComponent {
  static propTypes = {
    pushModalMessage: PropTypes.func.isRequired,
    onUploadFile: PropTypes.func.isRequired,
    uploadFeedback: PropTypes.arrayOf(PropTypes.exact(UploadFeedback)).isRequired,
    isFetching: PropTypes.bool.isRequired,
  };

  state = {
    zipFile: null,
  };

  handleFileChange = e => {
    const zipFile = e.currentTarget.files[0];
    this.setState({ zipFile });
    if (!zipFile) return;

    if (!validateUploadSize(zipFile)) {
      const maxUploadLimitErrorMessage = i18n.t('message.maxUploadLimit', { maximum: maxUploadSizeInMB });
      this.props.pushModalMessage(maxUploadLimitErrorMessage);
    }
    e.currentTarget.value = '';
  };

  handleFileUpload = () => {
    if (this.state.zipFile) this.props.onUploadFile(this.state.zipFile);
  };

  render() {
    return (
      <Can I={commonActions.create} a={sectionsAndPages.payStubs}>
        <h1 className="view__title">{i18n.t('title.payStubs')}</h1>
        <div className="grid">
          <div className="grid__item -span-8">
            <div className="tile">
              <section className="form__section grid">
                <div className="grid__item -span-3">
                  <label id="addZipFileLabel" htmlFor="addZipFile" className="button -primary">
                    <input
                      type="file"
                      name="addZipFile"
                      id="addZipFile"
                      accept=".zip, application/zip"
                      onChange={this.handleFileChange}
                      className="input__file"
                    />
                    {i18n.t('file.choose')}
                  </label>
                </div>
                {this.state.zipFile && (
                  <>
                    <span className="grid__item -span-3 -align-right">{this.state.zipFile.name}</span>
                    <div className="grid__item -span-5">
                      <button type="button" className="button -primary" onClick={this.handleFileUpload}>
                        {i18n.t('upload')}
                      </button>
                    </div>
                  </>
                )}
              </section>
            </div>
            <div className="tile">
              <section className="grid">
                <h6 className="grid__heading -span-4">{i18n.t('accounting.name')}</h6>
                <h6 className="grid__heading -span-6">{i18n.t('accounting.file')}</h6>
                <h6 className="grid__heading -span-2">{i18n.t('accounting.success')}</h6>
                {this.props.isFetching && <Loader />}

                {this.props.uploadFeedback.map(entry => {
                  const icon = entry.isSuccess ? 'check-circle' : 'times-circle';
                  const color = entry.isSuccess ? 'green' : 'red';
                  return (
                    <article className="grid__row" key={entry.employeeId}>
                      <span className="grid__item -span-4">{entry.employeeName}</span>
                      <span className="grid__item -span-6">{entry.fileName}</span>
                      <span className="grid__item -span-2 -align-center">
                        <FontAwesomeIcon icon={icon} style={{ color }} />
                      </span>
                    </article>
                  );
                })}
              </section>
            </div>
          </div>
        </div>
      </Can>
    );
  }
}

export default PayStubsUpload;
