import PropTypes from 'prop-types';

const User = {
  groups: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  name: PropTypes.string.isRequired,
  picture: PropTypes.isRequired,
  sub: PropTypes.isRequired,
  accessToken: PropTypes.string.isRequired,
  updated_at: PropTypes.isRequired,
};

export default User;
