import { pushStateLocationPlugin, UIRouter, UIView } from '@uirouter/react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { finishTransition, setBreadcrumbs } from '../actions';
import defineAbilitiesFor from '../helpers/authorization/abilities';
import commonActions from '../helpers/authorization/abilityActions';
import Auth from '../helpers/authorization/auth';
import requiresAuthHook from '../helpers/authorization/requiresAuthHook';
import sectionsAndPages from '../helpers/sectionsAndPages';
import App from './App';
import Callback from './callback/callback';
import Home from './Home';
import BillableHourRatiosPage from './page/BillableHourRatiosPage';
import BugRateReportPage from './page/BugRateReportPage';
import ClientListPage from './page/ClientListPage';
import ClientDetailsPage from './page/ClientDetailsPage';
import CostTrackingPage from './page/CostTrackingPage';
import EmployeeOvertimePage from './page/EmployeeOvertimePage';
import PayFileConversionPage from './page/PayFileConversionPage';
import PayStubsUploadPage from './page/PayStubsUploadPage';
import ProjectListPage from './page/ProjectListPage';
import TeamDetailsPage from './page/TeamDetailsPage';
import TimeComparisonPage from './page/TimeComparisonPage';
import TimeReportingPage from './page/TimeReportingPage';
import TimesheetListPage from './page/TimesheetListPage';
import PayFileGenerationPage from './page/PayFileGenerationPage';
import BatchUpdatesPage from './page/BatchUpdatesPage';
import ProjectDetailsPage from './page/ProjectDetailsPage';
import EmployeeInfoPage from './page/EmployeeInfoPage';
import EmployeeListPage from './page/EmployeeListPage';
import OvertimeRequestListPage from './page/OvertimeRequestListPage';
import OvertimeRequestDetailsPage from './page/OvertimeRequestDetailsPage';

const {
  batchUpdates,
  billableHourRatios,
  bugRateReport,
  callback,
  clients,
  clientInfo,
  newClient,
  costTracking,
  employeeInfo,
  employeeOvertimeReport,
  home,
  logout,
  newEmployee,
  payFileConversion,
  payFileGeneration,
  payStubs,
  projects,
  projectCreation,
  projectDetails,
  root,
  teamDetails,
  timeComparisons,
  timeReporting,
  timesheets,
  employees,
  overtimeRequestList,
  newOvertimeRequest,
} = sectionsAndPages;

const rootState = {
  name: root,
  abstract: true,
  component: App,
  url: '',
};

const states = [
  rootState,
  {
    parent: root,
    name: home,
    url: '/',
    component: Home,
  },
  {
    parent: root,
    name: costTracking,
    url: '/cost-tracking',
    component: CostTrackingPage,
  },
  {
    parent: root,
    name: timeReporting,
    url: '/time-reporting?keys&startDate&endDate',
    params: {
      keys: { type: 'string', dynamic: true },
      startDate: { type: 'string', dynamic: true },
      endDate: { type: 'string', dynamic: true },
    },
    component: TimeReportingPage,
  },
  {
    parent: root,
    name: timeComparisons,
    url: '/time-entries?{from}&{to}&{projectId}',
    component: TimeComparisonPage,
  },
  {
    parent: root,
    name: timesheets,
    url: '/timesheets',
    component: TimesheetListPage,
  },
  {
    parent: root,
    name: payFileConversion,
    url: '/pay-file-conversion',
    component: PayFileConversionPage,
  },
  {
    parent: root,
    name: payFileGeneration,
    url: '/pay-file-generation',
    component: PayFileGenerationPage,
  },
  {
    parent: root,
    name: bugRateReport,
    url: '/bug-rate-report',
    component: BugRateReportPage,
  },
  {
    parent: root,
    name: projects,
    url: '/projects',
    component: ProjectListPage,
  },
  {
    parent: root,
    name: projectCreation,
    url: '/projects/new',
    component: ProjectDetailsPage,
  },
  {
    parent: root,
    name: projectDetails,
    url: '/projects/:projectId',
    component: ProjectDetailsPage,
  },
  {
    parent: root,
    name: payStubs,
    url: '/pay-stubs',
    component: PayStubsUploadPage,
  },
  {
    parent: root,
    name: employeeOvertimeReport,
    url: '/employees/:employeeId/overtime',
    component: EmployeeOvertimePage,
  },
  {
    parent: root,
    name: billableHourRatios,
    url: '/billable-ratios',
    component: BillableHourRatiosPage,
  },
  {
    parent: root,
    name: teamDetails,
    url: '/teams/:teamId',
    component: TeamDetailsPage,
  },
  {
    parent: root,
    name: employeeInfo,
    url: '/employees/:employeeId',
    component: EmployeeInfoPage,
  },
  {
    parent: root,
    name: newEmployee,
    url: '/employees/new',
    component: EmployeeInfoPage,
  },
  {
    parent: root,
    name: batchUpdates,
    url: '/batch-updates',
    component: BatchUpdatesPage,
  },
  {
    parent: root,
    name: employees,
    url: '/employees',
    component: EmployeeListPage,
  },
  {
    parent: root,
    name: overtimeRequestList,
    url: '/overtime-requests',
    component: OvertimeRequestListPage,
  },
  {
    parent: root,
    name: newOvertimeRequest,
    url: '/overtime-requests/new',
    component: OvertimeRequestDetailsPage,
  },
  {
    parent: root,
    name: clients,
    url: '/clients',
    component: ClientListPage,
  },
  {
    parent: root,
    name: newClient,
    url: '/clients/new',
    component: ClientDetailsPage,
  },
  {
    parent: root,
    name: clientInfo,
    url: '/clients/:clientId',
    component: ClientDetailsPage,
  },
  {
    name: callback,
    url: '/callback',
    component: Callback,
    allowAnonymous: true,
  },
  {
    name: logout,
    url: '/logout',
    allowAnonymous: true,
  },
];

const plugins = [pushStateLocationPlugin];

class AppRouter extends Component {
  static propTypes = {
    finishTransition: PropTypes.func.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
  };

  uiRouter = undefined;

  alwaysAuthorizedRouterStates = [sectionsAndPages.home, sectionsAndPages.callback, sectionsAndPages.logout];

  configRouter = router => {
    router.urlRouter.otherwise('/404');
    this.setTitle();

    router.transitionService.onBefore(requiresAuthHook.criteria, requiresAuthHook.callback);
    router.transitionService.onBefore({ to: logout }, transition => {
      const auth = new Auth();
      auth.logout();
      return transition.router.stateService.target(logout);
    });

    router.transitionService.onBefore({ to: callback }, transition => {
      if (!Auth.isAuthenticated()) return null;
      return transition.router.stateService.target(home);
    });

    router.transitionService.onStart({}, transition => {
      const toTransition = transition.to();
      if (this.alwaysAuthorizedRouterStates.includes(toTransition.name)) return true;
      const ability = defineAbilitiesFor(this.props.user);
      const canIBeHere = ability.can(commonActions.navigateTo, toTransition.name);
      return canIBeHere ? true : transition.router.stateService.target(home);
    });

    router.transitionService.onFinish({}, this.handleTransitionFinish);
    router.transitionService.onError({}, this.handleTransitionError);

    this.uiRouter = router;
  };

  handleTransitionFinish = transition => {
    const params = transition.params();
    const toTransition = transition.to();
    this.props.finishTransition(toTransition.name || '', params);

    if (toTransition.areBreadcrumbsControlled) this.updateBreadcrumbs(toTransition.breadcrumbs || [], params);
  };

  handleTransitionError = transition => {
    const error = transition.error();
    // eslint-disable-next-line
    if (error.type === 6) console.error(error);
  };

  setTitle = () => {
    document.title = i18n.t(`title.appTitle`);
  };

  updateBreadcrumbs = (breadcrumbs, params) => {
    const formattedBreadcrumbs = breadcrumbs.map(breadcrumb => {
      let label = breadcrumb.label || '';
      params.forEach((param, key) => {
        label = label.replace(`:${key}`, param);
      });
      return { ...breadcrumb, label };
    });
    this.props.setBreadcrumbs(formattedBreadcrumbs);
  };

  render() {
    return (
      <UIRouter plugins={plugins} states={states} config={this.configRouter} router={this.uiRouter}>
        <UIView />
      </UIRouter>
    );
  }
}

const mapStateToProps = state => ({
  user: state.app.auth.user,
});

export default connect(
  mapStateToProps,
  { finishTransition, setBreadcrumbs }
)(AppRouter);
