import { InputReactSelect } from '@vooban/inputs';
import i18n from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { DateRangePicker } from 'react-dates';
import Loader from '@vooban/loader';
import _orderBy from 'lodash.orderby';
import { ProjectMapping } from '../../../types';

const falseFunc = () => false;
class CostTracking extends React.PureComponent {
  static propTypes = {
    onGenerateCostTrackingDocument: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    projectMappings: PropTypes.arrayOf(PropTypes.exact(ProjectMapping)),
  };

  static defaultProps = {
    isFetching: false,
    projectMappings: [],
  };

  errors = {};

  initialValues = {
    startDate: null,
    endDate: null,
    focusedInput: null,
    projectIds: [],
    errors: { startDate: false, endDate: false, projectId: false },
  };

  state = this.initialValues;

  get projectMappingsOptions() {
    const mappedProjects = this.props.projectMappings.map(project => ({
      key: project.id,
      value: project.jiraProjects.map(jp => jp.id),
      label: project.name,
    }));
    return _orderBy(mappedProjects, [project => project.label.toLowerCase()]);
  }

  handleProjectChange = projectIds => this.setState({ projectIds });

  handleStartDateChange = value => this.setState({ startDate: value });

  handleEndDateChange = value => this.setState({ endDate: value });

  handleClearDates = () => this.setState({ startDate: null, endDate: null });

  handleSubmitReportRequest = () => {
    if (this.validateForm()) {
      this.setState({ errors: this.initialValues.errors });
      this.props.onGenerateCostTrackingDocument({
        startDate: this.state.startDate && moment(this.state.startDate).format('YYYY-MM-DD'),
        endDate: this.state.endDate && moment(this.state.endDate).format('YYYY-MM-DD'),
        issue: this.state.projectIds,
      });
    }
  };

  validateForm = () => {
    const errors = {};
    let isValid = true;
    if (!this.state.projectIds) {
      errors.projectId = true;
      isValid = false;
    }
    this.setState({ errors });
    return isValid;
  };

  render() {
    return (
      <>
        <h1 className="view__title">{i18n.t('title.costTracking')}</h1>
        <div className="grid">
          <div className="grid__item -span-8">
            <div className="tile">
              <section className="form__section grid">
                {this.props.isFetching && <Loader />}

                <div className="grid__row">
                  <div className="grid__item -span-12">
                    <p className="view__info">{i18n.t('costTracking.emptyDates')}</p>
                  </div>
                </div>
                <div className="grid__row">
                  <div className="grid__item -span-6">
                    <DateRangePicker
                      startDate={this.state.startDate}
                      startDateId="document_start_date_id"
                      endDate={this.state.endDate}
                      endDateId="document_end_date_id"
                      onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={focusedInput => this.setState({ focusedInput })}
                      isOutsideRange={falseFunc}
                      hideKeyboardShortcutsPanel
                      noBorder
                    />
                  </div>
                  <div className="grid__item -span-2">
                    <button type="button" className="view__action button -primary" onClick={this.handleClearDates}>
                      {i18n.t('button.clear')}
                    </button>
                  </div>
                </div>

                <div className="grid__row">
                  <div className="grid__item -span-6">
                    <InputReactSelect
                      name="jiraProjects"
                      options={this.projectMappingsOptions}
                      value={this.state.projectIds}
                      className="grid__item -span-5"
                      label={i18n.t('timeComparison.project')}
                      onChange={this.handleProjectChange}
                      updateOnFormUpdate
                    />
                    {this.state.errors.projectId && (
                      <span className="input__message -error -align-right">*{i18n.t('validation.required')}</span>
                    )}
                  </div>
                </div>

                <div className="grid__row">
                  <button
                    type="button"
                    className="view__action button -primary grid__item"
                    onClick={this.handleSubmitReportRequest}>
                    {i18n.t('button.generate')}
                  </button>
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CostTracking;
