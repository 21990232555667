import i18n from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { getFailureIcon, getSuccessIcon } from '../../../helpers/authorization/iconHelpers';
import { OvertimeMarkedWeek, OvertimeWeek } from '../../../types';

const workHoursInAWeek = 35;
const halfTimeMultiplier = 0.5;
const hoursWarningThreshold = 5;
const hoursDangerThreshold = 15;
const weekIndexes = [3, 2, 1, 0];
const borderTopStyle = { borderTop: '1px solid black', paddingTop: '0.3rem' };

class WeeklyOvertime extends React.PureComponent {
  static propTypes = {
    overtimeWeeks: PropTypes.arrayOf(PropTypes.exact(OvertimeWeek)).isRequired,
    markedWeek: PropTypes.shape(OvertimeMarkedWeek),
  };

  static defaultProps = {
    markedWeek: null,
  };

  getWeekNumber = weeksToSubtract =>
    moment()
      .startOf('week')
      .subtract(weeksToSubtract, 'weeks')
      .week();

  isLastMarkedWeekAfter = weekStartDate => {
    const { markedWeek } = this.props;
    return markedWeek && moment.utc(markedWeek.lastMarkedWeek).isAfter(weekStartDate);
  };

  getOvertimeWeek = (overtimeWeeks, weeksToSubtract) => {
    const weekStartDate = moment()
      .startOf('week')
      .subtract(weeksToSubtract, 'weeks')
      .format('YYYY-MM-DD');

    if (this.isLastMarkedWeekAfter(weekStartDate)) return '-';

    return overtimeWeeks.find(week => this.formatDate(week.startDate) === weekStartDate);
  };

  isWeekPayed = weeksToSubtract => {
    if (weeksToSubtract === 3) return true;
    if (weeksToSubtract === 0) return false;

    const firstPayDate = moment.utc('2019-01-10').endOf('week');
    const now = moment();
    const numberOfWeeksSinceFirstPay = now.diff(firstPayDate, 'week');
    const isPayWeek = numberOfWeeksSinceFirstPay % 2 !== 0;
    const thursday = 4;
    const isAfterPayDay = now.weekday() >= thursday;

    if (weeksToSubtract === 2) return (isPayWeek && isAfterPayDay) || !isPayWeek;
    if (weeksToSubtract === 1) return isPayWeek && isAfterPayDay;

    return false;
  };

  getHoursColor = (hours, warningThreshold = 0, dangerThreshold = 0) => {
    if (hours > dangerThreshold) return 'red';
    if (hours > warningThreshold) return 'orange';
    return 'black';
  };

  getTotalWeeklyHoursOverLimit = overtimeWeeks => overtimeWeeks.reduce((sum, week) => sum + week.hoursOverLimit, 0);

  getTotalPaidWeeklyHoursOverLimit = overtimeWeeks =>
    overtimeWeeks.reduce((sum, week) => (week.isPaid ? sum + week.hoursOverLimit : sum), 0);

  formatDate = date => moment.utc(date).format('YYYY-MM-DD');

  render() {
    const totalWeeklyHoursOverLimit = this.getTotalWeeklyHoursOverLimit(this.props.overtimeWeeks);
    const totalPaidWeeklyHoursOverLimit = this.getTotalPaidWeeklyHoursOverLimit(this.props.overtimeWeeks);

    return (
      <div className="grid__item -span-10">
        <div className="tile">
          <section className="grid">
            <div className="grid__item -span-12">
              {/* FIXME: Create new css classes instead of overriding this one */}
              <section className="menu__content" style={{ borderTop: '0rem' }}>
                <h4 className="menu__title" style={{ margin: '0rem' }}>
                  {i18n.t('overtime.fiftyHoursRule')}
                </h4>
                <span>{i18n.t('overtime.fiftyHoursRuleExplanation')}</span>
              </section>
            </div>

            <h6 className="grid__heading -span-2 -align-center">{i18n.t('overtime.week')}</h6>
            <h6 className="grid__heading -span-2 -align-center">{i18n.t('overtime.hours')}</h6>
            <h6 className="grid__heading -span-2 -align-center">{i18n.t('overtime.overtime')}</h6>
            <h6 className="grid__heading -span-2 -align-center">{i18n.t('overtime.overLimit')}</h6>
            <h6 className="grid__heading -span-2 -align-center">{i18n.t('overtime.extra')}</h6>
            <h6 className="grid__heading -span-2 -align-center">{i18n.t('overtime.paid')}</h6>

            {weekIndexes.map(index => {
              const overtimeWeek = this.getOvertimeWeek(this.props.overtimeWeeks, index);
              const weekHours = overtimeWeek ? overtimeWeek.hours : 0;
              const weekHoursOverLimit = overtimeWeek ? overtimeWeek.hoursOverLimit : 0;
              const isWeekPaid = overtimeWeek ? overtimeWeek.isPaid : this.isWeekPayed(index);

              return (
                <article className="grid__row" key={index}>
                  <span className="grid__item -span-2 -align-center">{this.getWeekNumber(index)}</span>
                  <span
                    className="grid__item -span-2 -align-center"
                    style={{ color: this.getHoursColor(weekHours, hoursWarningThreshold, hoursDangerThreshold) }}>
                    {(workHoursInAWeek + weekHours).toFixed(2)}
                  </span>
                  <span
                    className="grid__item -span-2 -align-center"
                    style={{ color: this.getHoursColor(weekHours, hoursWarningThreshold, hoursDangerThreshold) }}>
                    {weekHours.toFixed(2)}
                  </span>
                  <span
                    className="grid__item -span-2 -align-center"
                    style={{ color: this.getHoursColor(weekHoursOverLimit) }}>
                    {weekHoursOverLimit.toFixed(2)}
                  </span>
                  <span
                    className="grid__item -span-2 -align-center"
                    style={{ color: this.getHoursColor(weekHoursOverLimit) }}>
                    {(weekHoursOverLimit * halfTimeMultiplier).toFixed(2)}
                  </span>
                  <span className="grid__item -span-2 -align-center">
                    {isWeekPaid ? getSuccessIcon() : getFailureIcon()}
                  </span>
                </article>
              );
            })}
            <article className="grid__row">
              <span className="grid__item -span-2 -align-center">{i18n.t('overtime.total')}</span>
              <span className="grid__item -span-4 -align-center" />
              <span className="grid__item -span-2 -align-center" style={borderTopStyle}>
                {totalWeeklyHoursOverLimit.toFixed(2)}
              </span>
              <span className="grid__item -span-2 -align-center" style={borderTopStyle}>
                {(totalWeeklyHoursOverLimit * halfTimeMultiplier).toFixed(2)}
              </span>
              <span className="grid__item -span-2 -align-center">
                {(totalPaidWeeklyHoursOverLimit * halfTimeMultiplier).toFixed(2)}
              </span>
            </article>
          </section>
        </div>
      </div>
    );
  }
}

export default WeeklyOvertime;
