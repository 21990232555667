export default {
  accounting: 'accounting',
  batchUpdates: 'batchUpdates',
  billableHourRatios: 'billableHourRatios',
  bugRateReport: 'bugRateReport',
  callback: 'callback',
  clients: 'clients',
  clientInfo: 'clientInfo',
  newClient: 'newClient',
  costTracking: 'costTracking',
  employeeInfo: 'employeeInfo',
  newEmployee: 'newEmployee',
  employeeOvertimeReport: 'employeeOvertimeReport',
  home: 'home',
  logout: 'logout',
  operations: 'operations',
  payFileConversion: 'payFileConversion',
  payFileGeneration: 'payFileGeneration',
  payStubs: 'payStubs',
  projects: 'projects',
  projectCreation: 'projectCreation',
  projectDetails: 'projectDetails',
  reporting: 'reporting',
  rework: 'rework',
  root: 'root',
  settings: 'settings',
  teams: 'teams',
  teamDetails: 'teamDetails',
  timeComparisons: 'timeComparisons',
  timeReporting: 'timeReporting',
  timesheets: 'timesheets',
  timeOffUpdating: 'timeOffUpdating',
  employees: 'employees',
  overtimeRequestList: 'overtimeRequestList',
  newOvertimeRequest: 'newOvertimeRequest',
};
