import PropTypes from 'prop-types';
import OvertimeWeek from "./OvertimeWeek";

const OvertimeEntry = {
  employeeId: PropTypes.number,
  fourWeeksHours: PropTypes.number,
  fourWeeksHoursOverLimit: PropTypes.number,
  fourWeeksExtra: PropTypes.number,
  totalUnpaidExtra: PropTypes.number,
  overtimeWeeks: PropTypes.arrayOf(PropTypes.shape(OvertimeWeek)),
  userEmail: PropTypes.string,
};

export default OvertimeEntry;
