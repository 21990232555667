import PropTypes from 'prop-types';
import ProjectTask from './ProjectTask';

import ProjectMappingJiraProject from './ProjectMappingJiraProject';

const ProjectMapping = {
  id: PropTypes.number,
  harvestIds: PropTypes.arrayOf(PropTypes.number),
  jiraProjects: PropTypes.arrayOf(PropTypes.exact(ProjectMappingJiraProject)),
  name: PropTypes.string,
  isBillable: PropTypes.bool,
  code: PropTypes.string,
  desjardinsCode: PropTypes.string,
  description: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  projectManagerId: PropTypes.number,
  effortInDays: PropTypes.number,
  minEffortInDollars: PropTypes.number,
  maxEffortInDollars: PropTypes.number,
  billingType: PropTypes.string,
  status: PropTypes.string,
  hourlyRate: PropTypes.number,
  contactId: PropTypes.number,
  contactName: PropTypes.string,
  contactEmail: PropTypes.string,
  contactTelephone: PropTypes.string,
  contactCellphone: PropTypes.string,
  tempoAccountKey: PropTypes.string,
  projectTasks: PropTypes.arrayOf(PropTypes.exact(ProjectTask)),
  clientId: PropTypes.number,
};

export const ProjectStatus = {
  DONE: 'Done',
  IN_PROGRESS: 'In progress',
  NEGOCIATING: 'Negociating',
};

export default ProjectMapping;
