import { AbilityBuilder } from '@casl/ability';
import commonActions from '../abilityActions';
import { isInRole } from '../abilityHelpers';
import userRoles from '../userRoles';
import sectionsAndPages from '../../sectionsAndPages';

const { billableHourRatios } = sectionsAndPages;

export default function getRules(user) {
  const { rules, can } = AbilityBuilder.extract();
  const { navigateTo } = commonActions;
  const { admin, projectManager } = userRoles;

  if (isInRole(user, [admin, projectManager])) {
    can(navigateTo, billableHourRatios);
  }

  return rules;
}
