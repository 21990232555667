export default {
  actions: 'Actions',
  confirmRemove: 'Are you sure you want to delete this entry?',
  edit: 'Edit contact',
  email: 'Email',
  name: 'Name',
  new: 'New contact',
  none: 'None',
  telephone: 'Telephone',
  cellphone: 'Cell',
  title: 'Title',
};
