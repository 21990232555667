import overtime from '../components/accounting/overtime/__i18n__/en';
import accounting from '../components/accounting/__i18n__/en';
import project from '../components/project/__i18n__/en';
import timeComparison from '../components/reporting/timeComparison/__i18n__/en';
import timeReporting from '../components/reporting/timeReporting/__i18n__/en';
import timesheet from '../components/reporting/timesheet/__i18n__/en';
import team from '../components/team/__i18n__/en';
import batchUpdates from '../components/batchUpdates/__i18n__/en';
import employee from '../components/employee/__i18n__/en';
import bugRateReport from '../components/bugRateReport/__i18n__/en';
import overtimeRequest from '../components/overtimeRequest/__i18n__/en';
import billableHourRatios from '../components/billableHourRatios/__i18n__/en';
import client from '../components/client/__i18n__/en';
import contact from '../components/contact/__i18n__/en';

export default {
  accounting,
  batchUpdates,
  billableHourRatios,
  bugRateReport,
  button: {
    add: 'Add',
    cancel: 'Cancel',
    clear: 'Clear',
    collapse: 'Collapse',
    create: 'Create',
    detailsHide: 'Hide details',
    detailsShow: 'View details',
    download: 'Download',
    export: 'Export',
    extend: 'Extend',
    generate: 'Generate',
    no: 'No',
    remove: 'Remove',
    retrieve: 'Retrieve',
    save: 'Save',
    submit: 'Submit',
    update: 'Update',
    yes: 'Yes',
  },
  client,
  contact,
  costTracking: {
    emptyDates: 'Leave dates empty for a general report.',
  },
  employee,
  file: {
    choose: 'Choose File',
    noChosen: 'No file chosen',
  },
  menuSections: {
    accounting: 'Payroll',
    operations: 'Operations',
    reporting: 'Time',
    settings: 'Settings',
    logout: 'Logout',
    overtimeRequests: 'Overtime Requests',
  },
  message: {
    created: '{{resource}} created  successfully',
    generated: 'Generated',
    maxUploadLimit: 'Maximum upload filesize is {{maximum}} Mb.',
    global: 'An error occurred, please try again!',
    hello: 'Hello',
    treated: 'Time off update request was successfully treated',
    emailSent: 'Email Successfully sent',
    emailSent_plural: 'Emails Successfully sent',
    updated: '{{resource}} successfully updated',
    changesSaved: 'Changes Saved',
    nothingFound: 'Nothing found',
    searchEmployees: 'Start typing to search',
    projectMappingCreated: 'Project has been created successfully',
    projectMappingUpdated: 'Project has been updated successfully',
    contactCreated: 'Contact created successfully',
    contactUpdated: 'Contact updated successfully',
    projectPurchaseOrderCreated: 'Project purchase order created successfully',
    projectPurchaseOrderDeleted: 'Project purchase order deleted successfully',
    projectMappingDocumentAdded: 'Project document added successfully',
    teamCreatedSuccessfully: 'Team created successfully',
    teamDeletedSuccessfully: 'Team deleted successfully',
    teamUpdatedSuccessfully: 'Team updated successfully',
  },
  overtime,
  overtimeRequest,
  project,
  resource: {
    client: 'Client',
    document: 'document',
    employee: 'Employee',
    defaultHourlyRates: 'Default hourly rates',
    costRates: 'Cost Rates',
  },
  search: 'Search',
  team,
  timeComparison,
  timeReporting,
  timesheet,
  title: {
    appTitle: 'Vooban App',
    batchUpdates: 'Batch Updates',
    billableHourRatios: 'Billable hour ratios',
    bugRateReport: 'Bug Rate Report',
    clients: 'Clients',
    contacts: 'Contacts',
    desjardinsProjectAssociations: 'Employeur D projects',
    home: 'Home',
    generate: 'Generate',
    newEmployee: 'New Employee',
    overtimeReport: 'Overtime report',
    payStubs: 'Upload Stubs',
    payFileConversion: 'Process Time Off',
    payFileGeneration: 'Create Pay File',
    projectDetail: 'Project',
    projects: 'Projects',
    purchaseOrders: 'Purchase Orders',
    costTracking: 'Cost Tracking',
    team: 'Team',
    teamMembers: 'Team Members',
    teams: 'Teams',
    timeComparisons: 'Harvest/Jira Comparison',
    timeReporting: 'Spent on issue',
    timesheets: 'Submission & Approval',
    employees: 'Employees',
    overtimeRequest: 'Overtime Request',
    overtimeRequests: 'Overtime Requests',
    documents: 'Documents',
  },
  upload: 'Upload',
  version: 'Version: {{version}}',
  validation: {
    bothDatesRequired: 'Both dates are required',
    password: 'Password must contain at least one lowercase character, one uppercase character and one number',
    passwordMinimum: 'Minimum 8 characters',
    passwordMaximum: 'Maximum 16 characters',
    phoneNumber: 'Invalid phone number. Format must be 10 digits',
    required: 'Required',
    invalidZipCode: 'Invalid ZIP code',
    dateMustBeGreater: 'Date must be greater than start date',
    dateMustBeLesser: 'Date must be before end date',
    maxMustBeGreaterThanMin: 'The maximum value must be greater than the minimum value',
    invalidEmail: 'Email address is invalid',
  },
};
