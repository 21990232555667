import React, { PureComponent } from 'react';
import i18n from 'i18next';
import { FastField, Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import _orderBy from 'lodash.orderby';
import { FormikText, FormikSelect } from '@vooban/formik-inputs';
import { validate, required } from '../../helpers/formHelpers';
import { Employee, ProjectMapping } from '../../types';

class TeamCreationModal extends PureComponent {
  static propTypes = {
    employees: PropTypes.arrayOf(PropTypes.exact(Employee)).isRequired,
    projectMapping: PropTypes.exact(ProjectMapping).isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onCreateClick: PropTypes.func.isRequired,
  };

  employeeArrayToOptions = array =>
    _orderBy(
      array.map(o => ({ key: o.id, label: `${o.firstName} ${o.lastName} (${o.workEmail})`, value: o })),
      'value.firstName'
    );

  handleSubmit = (values, { resetForm }) => {
    this.props.onCreateClick({
      name: values.teamName,
      teamLeadEmployeeId: values.selectedEmployee.id,
      teamLeadJiraId: values.selectedEmployee.jiraId,
      projectMappingId: this.props.projectMapping.id,
      harvestEmployeeId: values.selectedEmployee.harvestId,
    });
    resetForm({});
  };

  handleCancelClick = () => this.props.onCloseClick();

  render() {
    const employeeOptions = this.employeeArrayToOptions(this.props.employees);

    return (
      <>
        <section className="modal__header">
          <div className="grid">
            <h1 className="view__title grid__item -span-10">{i18n.t('team.newTeam')}</h1>
            <button type="button" className="button grid__item -align-right" onClick={this.handleCancelClick}>
              {i18n.t('button.cancel')}
            </button>
            <button type="submit" className="button -primary grid__item -align-right" form="createTeamForm">
              {i18n.t('button.add')}
            </button>
          </div>
        </section>

        <section className="modal__body">
          <Formik onSubmit={this.handleSubmit}>
            <Form id="createTeamForm" className="grid__item -span-12">
              <div className="grid__row">
                <FastField
                  component={FormikText}
                  name="teamName"
                  label={i18n.t('team.name')}
                  validate={validate([required])}
                  className="grid__item -span-8"
                />
              </div>
              <div className="grid__row">
                <FastField
                  isSearchable
                  component={FormikSelect}
                  name="selectedEmployee"
                  options={employeeOptions}
                  label={i18n.t('team.teamLead')}
                  validate={validate([required])}
                  className="grid__item -span-8"
                />
              </div>
            </Form>
          </Formik>
        </section>
      </>
    );
  }
}

export default TeamCreationModal;
