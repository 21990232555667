import { AbilityBuilder } from '@casl/ability';
import sectionsAndPages from '../../sectionsAndPages';
import commonActions from '../abilityActions';
import { isInRole } from '../abilityHelpers';
import userRoles from '../userRoles';

const { teams, teamDetails } = sectionsAndPages;

export default function getRules(user) {
  const { rules, can } = AbilityBuilder.extract();
  const { navigateTo, create, getList } = commonActions;
  const { projectManager, admin } = userRoles;

  if (isInRole(user, [projectManager, admin])) {
    can(navigateTo, [teams, teamDetails]);
    can(create, [teams, teamDetails]);
    can(getList, [teams, teamDetails]);
  }

  return rules;
}
