import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faTimesCircle,
  faEnvelope,
  faFileAlt,
  faTrashAlt,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import 'react-dates/initialize';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { setAuthUser } from './actions';
import AppRouter from './components/AppRouter';
import reducers from './reducers';
import User from './types/User';
import i18nConfig from './__i18n__';

let middlewares = [ReduxThunk];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line
  middlewares = [require('redux-immutable-state-invariant').default(), ...middlewares];
}

const composeEnhancers = (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const enhancer = composeEnhancers(applyMiddleware(...middlewares));
const store = createStore(reducers, {}, enhancer);

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

class Root extends React.PureComponent {
  static propTypes = {
    user: PropTypes.shape(User).isRequired,
  };

  state = { hasError: false, isReady: false };

  componentDidMount() {
    store.dispatch(setAuthUser(this.props.user));
    const defaultLanguage = 'en';

    i18n.init(i18nConfig, () => {
      let savedLanguage = localStorage.getItem('language');
      if (!savedLanguage) {
        localStorage.setItem('language', defaultLanguage);
        savedLanguage = defaultLanguage;
      }

      i18n.changeLanguage(savedLanguage);

      axios.defaults.headers.common['Accept-Language'] = savedLanguage;
      axios.defaults.headers.common.Authorization = `Bearer ${this.props.user.accessToken}`;

      this.setState({ isReady: true });
    });

    library.add(
      faCheck,
      faCheckCircle,
      faTimesCircle,
      faExclamationTriangle,
      faExclamationCircle,
      faEnvelope,
      faFileAlt,
      faTrashAlt,
      faSearch
    );
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <h1>An error has occurred, please contact technical support.</h1>;
    }

    if (!this.state.isReady) return null;

    return (
      <Provider store={store}>
        <AppRouter />
      </Provider>
    );
  }
}

export default Root;
