import PropTypes from 'prop-types';

const Team = {
  id: PropTypes.number,
  teamLeadEmployeeId: PropTypes.number,
  name: PropTypes.string,
  projectMappingId: PropTypes.number,
  teamLeadJiraId: PropTypes.string,
  tempoId: PropTypes.number,
  harvestEmployeeId: PropTypes.number,
};

export default Team;
