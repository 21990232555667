import i18n from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Loader from '@vooban/loader';
import PayPeriodAndYearSelection from '../PayPeriodAndYearSelection';
import { getPayEndDate, getPayStartDate } from '../../../helpers/dateHelpers';

class PayFileGeneration extends React.PureComponent {
  static propTypes = {
    onCreatePayFileDocument: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
  };

  errors = {};

  initialValues = {
    year: moment().year(),
    payNumber: Math.ceil(moment().week() / 2),
  };

  state = this.initialValues;

  handleSubmitCreatePayFileRequest = () => {
    const payEndDate = getPayEndDate(this.state.year, this.state.payNumber);
    this.props.onCreatePayFileDocument({ payEndDate, payStartDate: getPayStartDate(payEndDate) });
  };

  handlePayNumberChange = payNumber => this.setState({ payNumber });

  handleYearChange = year => this.setState({ year });

  render() {
    return (
      <>
        <h1 className="view__title">{i18n.t('title.payFileGeneration')}</h1>
        <div className="grid">
          <div className="grid__item -span-8">
            <div className="tile">
              <section className="form__section grid">
                {this.props.isFetching && <Loader />}
                <div className="grid__row">
                  <PayPeriodAndYearSelection
                    formValues={{ year: this.state.year, payNumber: this.state.payNumber }}
                    onYearChange={this.handleYearChange}
                    onPayNumberChange={this.handlePayNumberChange}
                  />
                </div>
                <div className="grid__row">
                  <button
                    type="button"
                    className="view__action button -primary grid__item"
                    onClick={this.handleSubmitCreatePayFileRequest}>
                    {i18n.t('button.create')}
                  </button>
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PayFileGeneration;
