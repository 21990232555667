import { AbilityBuilder } from '@casl/ability';
import commonActions from '../abilityActions';
import { isInRole } from '../abilityHelpers';
import userRoles from '../userRoles';
import sectionsAndPages from '../../sectionsAndPages';

const { batchUpdates } = sectionsAndPages;

export default function getRules(user) {
  const { rules, can } = AbilityBuilder.extract();
  const { navigateTo } = commonActions;
  const { accountant, admin, projectManager } = userRoles;

  if (isInRole(user, [accountant, admin, projectManager])) {
    can(navigateTo, batchUpdates);
  }

  return rules;
}
