export default {
  project: 'Project',
  total: 'Total',
  ratio: 'Ratio',
  spent: 'Spent',
  spentRatio: 'Spent ratio',
  internalBugs: 'Internal Bugs',
  bugs: 'Bugs',
  number: 'Number',
  time: 'Time',
  startDate: 'Start Date',
  endDate: 'End Date',
  selectProjects: 'Select Projects',
};
