import React, { PureComponent } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormikText, FormikSelect, FormikDatePicker } from '@vooban/formik-inputs';
import { Field, Form, Formik } from 'formik';
import _orderBy from 'lodash.orderby';
import { ProjectMapping } from '../../types';
import { maxDate, minDate, validate, required } from '../../helpers/formHelpers';
import { addDays } from '../../helpers/dateHelpers';

const today = moment().format('YYYY-MM-DD');

class OvertimeDetailsForm extends PureComponent {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    projects: PropTypes.arrayOf(PropTypes.exact(ProjectMapping)).isRequired,
  };

  render() {
    return (
      <Formik onSubmit={this.props.onSave}>
        {({ values }) => (
          <Form id="overtimeRequestForm">
            <div className="tile">
              <section className="form__section grid">
                <div className="grid__row">
                  <div className="grid__item -span-4">
                    <Field
                      component={FormikSelect}
                      name="projectId"
                      label={i18n.t('overtimeRequest.project')}
                      options={_orderBy(this.props.projects, [project => project.name.toLowerCase()], ['ASC'])}
                      labelKey="name"
                      valueKey="id"
                      validate={validate([required])}
                    />
                  </div>
                  <div className="grid__item -span-4">
                    <Field
                      component={FormikText}
                      name="reason"
                      label={i18n.t('overtimeRequest.reason')}
                      validate={validate([required])}
                    />
                  </div>
                </div>
                <div className="grid__row">
                  <div className="grid__item -span-4">
                    <Field
                      component={FormikDatePicker}
                      name="startDate"
                      label={i18n.t('overtimeRequest.startDate')}
                      minimumDate={today}
                      validate={validate([required, maxDate(addDays(values.endDate, -1))])}
                    />
                  </div>
                  <div className="grid__item -span-4">
                    <Field
                      component={FormikDatePicker}
                      name="endDate"
                      label={i18n.t('overtimeRequest.endDate')}
                      minimumDate={today}
                      validate={validate([required, minDate(addDays(values.startDate, 1))])}
                    />
                  </div>
                </div>
              </section>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default OvertimeDetailsForm;
