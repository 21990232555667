import React from 'react';
import PropTypes from 'prop-types';
import UpdateDefaultHourlyRate from './UpdateDefaultHourlyRate';
import UpdateCostRate from './UpdateCostRate';

class BatchUpdates extends React.PureComponent {
  static propTypes = {
    onUpdateDefaultHourlyRate: PropTypes.func.isRequired,
    onUpdateCostRate: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <>
        <div className="grid">
          <div className="grid__row">
            <UpdateDefaultHourlyRate
              onUpdateDefaultHourlyRate={this.props.onUpdateDefaultHourlyRate}
              isFetching={this.props.isFetching}
            />
          </div>
          <div className="grid__row">
            <UpdateCostRate onUpdateCostRate={this.props.onUpdateCostRate} isFetching={this.props.isFetching} />
          </div>
        </div>
      </>
    );
  }
}

export default BatchUpdates;
