import enTranslation from './en';
import frTranslation from './fr';

export default {
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: { translation: enTranslation },
    fr: { translation: frTranslation },
  },
};
