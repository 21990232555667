import axios from 'axios';
import download from 'js-file-download';
import moment from 'moment';
import { statusType } from '../helpers';
import { handleError, pushModalMessageError, pushToastMessage, startFetching, stopFetching } from './appActions';
import {
  CLEAR_TIME_COMPARISONS,
  SET_ISSUE_SUGGESTIONS,
  SET_TIME_COMPARISONS,
  SET_TIME_ENTRIES,
  SET_TIME_SPENT,
  GENERATE_BUG_RATE_REPORT,
  PUSH_SPENT_ON_ISSUE_QUERY,
  SET_SPENT_ON_ISSUE_QUERIES,
  REMOVE_SPENT_ON_ISSUE_QUERY,
} from './types';

export const getHoursForIssue = timeSpentRequest => async dispatch => {
  dispatch(startFetching('timeSpent'));

  try {
    const params = new URLSearchParams();
    timeSpentRequest.keys.forEach(key => params.append('keys', key));
    if (timeSpentRequest.startDate && timeSpentRequest.endDate) {
      params.append('startDate', moment(timeSpentRequest.startDate).format('YYYY-MM-DD'));
      params.append('endDate', moment(timeSpentRequest.endDate).format('YYYY-MM-DD'));
    }

    const response = await axios.get('/jira/spentTime', { params });
    const requestDate = moment(new Date()).format('YYYY-MM-DD hh:mm:ss A');

    const { data = {} } = response;
    const timeSpentMap = Object.values(data).map(timeSpentList =>
      timeSpentList.map(timeSpent => ({
        issueNumber: timeSpent.key,
        from: timeSpentRequest.startDate || '-',
        to: timeSpentRequest.endDate || '-',
        requestDate,
        ...timeSpent,
      }))
    );

    dispatch({ type: SET_TIME_SPENT, payload: timeSpentMap });
    dispatch(stopFetching('timeSpent'));
  } catch (error) {
    dispatch(handleError(error));
    dispatch(stopFetching('timeSpent'));
  }
};

export const sendUnbalancingTimeWarningEmail = emailRequest => async dispatch => {
  try {
    await axios.post('/communication/send-warning-time-comparison', emailRequest);
    dispatch(pushToastMessage('emailSent', '', emailRequest.length));
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const sendTimesheetReminder = emailRequest => async dispatch => {
  try {
    await axios.post('/communication/send-timesheet-reminder', emailRequest);
    dispatch(pushToastMessage('emailSent', '', emailRequest.length));
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getTimeComparisons = comparisonRequest => async dispatch => {
  dispatch(startFetching('timeComparisons'));
  try {
    const params = {
      startDate: comparisonRequest.startDate,
      endDate: comparisonRequest.endDate,
      harvestIds: comparisonRequest.project.harvestIds,
      jiraKeys: comparisonRequest.project.jiraProjects.map(jp => jp.key),
    };

    const response = await axios.get('/harvest/time-comparisons', { params });
    dispatch({
      type: SET_TIME_COMPARISONS,
      payload: { timeComparisons: response.data, projectName: comparisonRequest.project.name },
    });
    dispatch(stopFetching('timeComparisons'));
  } catch (error) {
    dispatch(handleError(error));
    dispatch(stopFetching('timeComparisons'));
  }
};

export const getTimeEntries = params => async dispatch => {
  dispatch(startFetching('timeEntries'));
  try {
    const response = await axios.get('/harvest/time-entries', { params });
    dispatch({ type: SET_TIME_ENTRIES, payload: response.data });
    dispatch(stopFetching('timeEntries'));
  } catch (error) {
    dispatch(handleError(error));
    dispatch(stopFetching('timeEntries'));
  }
};

export const clearComparisons = () => async dispatch => dispatch({ type: CLEAR_TIME_COMPARISONS });

export const generateCostTrackingDocument = query => async dispatch => {
  dispatch(startFetching('costTrackingDocument'));
  try {
    const params = new URLSearchParams();
    let response = {};

    query.issue.forEach(jiraId => {
      params.append('key', jiraId.toString());
    });

    if (!(query.startDate && query.endDate)) {
      response = await axios.get('/jira/fullProject', { params, responseType: 'blob' });
    } else {
      params.append('startDate', query.startDate);
      params.append('endDate', query.endDate);
      response = await axios.get('/jira/timeEntries', { params, responseType: 'blob' });
    }

    const fileName = query.startDate && query.endDate ? `${query.startDate}_${query.endDate}.xlsx` : `${moment()}.xlsx`;

    download(response.data, fileName, response.headers['content-type']);
    dispatch(pushToastMessage(statusType.generated, 'document'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('costTrackingDocument'));
  }
};

export const getIssueSuggestions = query => async dispatch => {
  try {
    const params = { query };
    const response = await axios.get('/node-jira/issue-picker-suggestions', { params });
    const mappedSuggestions = response.data.map(({ key, summaryText }) => ({ key, summary: summaryText }));
    dispatch({ type: SET_ISSUE_SUGGESTIONS, payload: mappedSuggestions });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  }
};

export const generateBugRateReport = bugRateReportRequest => async dispatch => {
  dispatch(startFetching('bugRateReport'));
  try {
    // Must use URLSearchParams not avoid having [] in the request URL
    const params = new URLSearchParams();
    bugRateReportRequest.projectIds.forEach(projectKey => {
      params.append('projectKeys', projectKey.toString());
    });
    if (bugRateReportRequest.startDate && bugRateReportRequest.endDate) {
      params.append('startDate', moment(bugRateReportRequest.startDate).format('YYYY-MM-DD'));
      params.append('endDate', moment(bugRateReportRequest.endDate).format('YYYY-MM-DD'));
    }
    const response = await axios.get('/jira/bugRateReport', { params });
    dispatch({ type: GENERATE_BUG_RATE_REPORT, payload: response.data });
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(stopFetching('bugRateReport'));
  }
};

export const exportBugRateReport = bugRateReportRequest => async dispatch => {
  dispatch(startFetching('bugRateReport'));
  try {
    // Must use URLSearchParams not avoid having [] in the request URL
    const params = new URLSearchParams();
    bugRateReportRequest.projectIds.forEach(projectKey => {
      params.append('projectKeys', projectKey.toString());
    });

    if (bugRateReportRequest.startDate && bugRateReportRequest.endDate) {
      params.append('startDate', moment(bugRateReportRequest.startDate).format('YYYY-MM-DD'));
      params.append('endDate', moment(bugRateReportRequest.endDate).format('YYYY-MM-DD'));
    }
    const response = await axios.get('/jira/exportBugRateReport', { params });
    const fileName = `bug.rate.report.${moment().format()}.csv`;
    download(response.data, fileName, response.headers['content-type']);
    dispatch(pushToastMessage(statusType.generated, 'document'));
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(stopFetching('bugRateReport'));
  }
};

export const getSpentOnIssueQueries = email => async dispatch => {
  dispatch(startFetching('spentOnIssueQuery'));
  try {
    const response = await axios.get(`/spent-on-issue-queries/${email}`);
    dispatch({ type: SET_SPENT_ON_ISSUE_QUERIES, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('spentOnIssueQuery'));
  }
};

export const addSpentOnIssueQuery = query => async dispatch => {
  dispatch(startFetching('spentOnIssueQuery'));
  try {
    const response = await axios.post('/spent-on-issue-queries', query);
    dispatch({ type: PUSH_SPENT_ON_ISSUE_QUERY, payload: response.data });
    dispatch(pushToastMessage('changesSaved'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('spentOnIssueQuery'));
  }
};

export const deleteSpentOnIssueQuery = id => async dispatch => {
  dispatch(startFetching('spentOnIssueQuery'));
  try {
    await axios.delete(`/spent-on-issue-queries/${id}`);
    dispatch({ type: REMOVE_SPENT_ON_ISSUE_QUERY, payload: id });
    dispatch(pushToastMessage('changesSaved'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('spentOnIssueQuery'));
  }
};
