import i18n from 'i18next';
import { Modal } from '@vooban/modals';
import PropTypes from 'prop-types';
import React from 'react';
import { TimeSpent, IssueSuggestion, SpentOnIssueQuery } from '../../../types';
import TimeReportingItem from './TimeReportingItem';
import TimeReportingModal from './TimeReportingModal';
import CustomQueryForm from './CustomQueryForm';
import SavedQueriesForm from './SavedQueriesForm';
import Tabs from '../../common/Tabs';

class TimeReporting extends React.PureComponent {
  static propTypes = {
    email: PropTypes.string.isRequired,
    onAddSpentOnIssueQuery: PropTypes.func.isRequired,
    onDeleteSpentOnIssueQuery: PropTypes.func.isRequired,
    timeSpentHistory: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.exact(TimeSpent))).isRequired,
    spentOnIssueQueries: PropTypes.arrayOf(PropTypes.exact(SpentOnIssueQuery)).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onGetIssueSuggestions: PropTypes.func.isRequired,
    issueSuggestions: PropTypes.arrayOf(PropTypes.exact(IssueSuggestion)).isRequired,
    initialValues: PropTypes.shape({
      keys: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }).isRequired,
  };

  tabs = [i18n.t('timeReporting.customQuery'), i18n.t('timeReporting.savedQueries')];

  modalRef = React.createRef();

  state = {
    selectedKey: null,
    selectedRow: null,
  };

  handleCloseClick = () => this.modalRef.current.close();

  handleWorklogsClick = (event, selectedKey, selectedRow) => {
    event.stopPropagation();
    this.setState({ selectedKey, selectedRow });
    this.modalRef.current.open();
  };

  render() {
    return (
      <>
        <h1 className="view__title">{i18n.t('title.timeReporting')}</h1>
        <div className="grid">
          <section className="grid__item -span-10">
            <Tabs tabs={this.tabs} className="tabs__form" tabClassName="-form">
              <CustomQueryForm
                email={this.props.email}
                initialValues={this.props.initialValues}
                onGetIssueSuggestions={this.props.onGetIssueSuggestions}
                onAddSpentOnIssueQuery={this.props.onAddSpentOnIssueQuery}
                issueSuggestions={this.props.issueSuggestions}
                onSubmit={this.props.onSubmit}
              />
              <SavedQueriesForm
                onDeleteSpentOnIssueQuery={this.props.onDeleteSpentOnIssueQuery}
                spentOnIssueQueries={this.props.spentOnIssueQueries}
                onSubmit={this.props.onSubmit}
              />
            </Tabs>

            <div className="tile">
              <section className="grid">
                <h6 className="grid__heading -span-1">{i18n.t('timeReporting.issue')}</h6>
                <h6 className="grid__heading -span-2">{i18n.t('timeReporting.summary')}</h6>
                <h6 className="grid__heading -span-2">{i18n.t('timeReporting.totalTimeSpent')}</h6>
                <h6 className="grid__heading -span-2">{i18n.t('timeReporting.maintenanceTimeSpent')}</h6>
                <h6 className="grid__heading -span-2">{i18n.t('timeReporting.newTimeSpent')}</h6>
                <h6 className="grid__heading -span-1">{i18n.t('timeReporting.from')}</h6>
                <h6 className="grid__heading -span-1">{i18n.t('timeReporting.to')}</h6>

                {this.props.timeSpentHistory.map((timeSpent, index) => {
                  const { key, requestDate } = timeSpent[0];
                  return (
                    <TimeReportingItem
                      key={`${requestDate}-${key}`}
                      timeSpent={timeSpent}
                      parentKey={key}
                      onWorklogsClick={(event, issueNumber) => this.handleWorklogsClick(event, issueNumber, index)}
                    />
                  );
                })}
              </section>
            </div>
          </section>
        </div>

        <Modal ref={this.modalRef} width="large">
          <TimeReportingModal
            onCloseClick={this.handleCloseClick}
            timeSpent={this.props.timeSpentHistory[this.state.selectedRow]}
            parentKey={this.state.selectedKey}
            selectedRow={this.state.selectedRow}
          />
        </Modal>
      </>
    );
  }
}

export default TimeReporting;
