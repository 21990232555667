import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import i18n from 'i18next';

class ProjectDocuments extends PureComponent {
  static propTypes = {
    documents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onAddDocument: PropTypes.func.isRequired,
    onDeleteDocument: PropTypes.func.isRequired,
    onDownloadDocument: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  handleFileChange = e => {
    const document = e.currentTarget.files[0];
    this.props.onAddDocument(document);
  };

  handleDeleteClick = document => {
    this.props.onDeleteDocument(document.id);
  };

  handleDownloadClick = document => {
    this.props.onDownloadDocument(document.id);
  };

  render() {
    return (
      <div className="tile -no-top">
        <header className="tile__header">
          <h3 className="view__subtitle">{i18n.t('title.documents')}</h3>
          <label className="button -primary">
            <input type="file" className="input__file" onChange={this.handleFileChange} />
            {i18n.t('project.addFile')}
          </label>
        </header>

        <section className="grid">
          <h6 className="grid__heading -span-11">{i18n.t('project.fileName')}</h6>
          <h6 className="grid__heading -span-1">{i18n.t('project.actions')}</h6>
          {this.props.documents.map(document => (
            <article key={document.id} className="grid__row -linked">
              <span className="grid__item -span-11">{document.name}</span>
              <span className="grid__item -span-1">
                <button type="button" className="button__icon" onClick={() => this.handleDownloadClick(document)}>
                  <FontAwesomeIcon icon="file-alt" />
                </button>
                <button type="button" className="button__icon" onClick={() => this.handleDeleteClick(document)}>
                  <FontAwesomeIcon icon="trash-alt" />
                </button>
              </span>
            </article>
          ))}
        </section>
      </div>
    );
  }
}

export default ProjectDocuments;
