import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateDefaultHourlyRate, updateCostRate } from '../../actions/batchUpdatesActions';
import { isFetchingEntities } from '../../helpers';
import BatchUpdates from '../batchUpdates/BatchUpdates';

class BatchUpdatesPage extends React.PureComponent {
  static propTypes = {
    updateDefaultHourlyRate: PropTypes.func.isRequired,
    updateCostRate: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
  };

  handleUpdateDefaultHourlyRate = hourlyRate => this.props.updateDefaultHourlyRate(hourlyRate);

  handleUpdateCostRate = () => this.props.updateCostRate();

  render() {
    return (
      <BatchUpdates
        onUpdateDefaultHourlyRate={this.handleUpdateDefaultHourlyRate}
        isFetching={this.props.isFetching}
        onUpdateCostRate={this.handleUpdateCostRate}
      />
    );
  }
}

const mapStateToProps = state => ({
  isFetching: isFetchingEntities(state, ['updateDefaultHourlyRate', 'updateCostRate']),
});

export default connect(
  mapStateToProps,
  { updateDefaultHourlyRate, updateCostRate }
)(BatchUpdatesPage);
