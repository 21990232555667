import Loader from '@vooban/loader';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  getEmployees,
  getExistingBambooEmployees,
  getExistingHarvestEmployees,
  getExistingAzureADEmployees,
  getExistingGoogleAppsEmployees,
  getExistingUbikEmployees,
  removeEmployee,
} from '../../actions';
import { isFetchingEntities } from '../../helpers';
import { Employee } from '../../types';
import EmployeeList from '../employee/EmployeeList';

class EmployeeListPage extends PureComponent {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    getEmployees: PropTypes.func.isRequired,
    getExistingBambooEmployees: PropTypes.func.isRequired,
    getExistingHarvestEmployees: PropTypes.func.isRequired,
    getExistingAzureADEmployees: PropTypes.func.isRequired,
    getExistingGoogleAppsEmployees: PropTypes.func.isRequired,
    getExistingUbikEmployees: PropTypes.func.isRequired,
    removeEmployee: PropTypes.func.isRequired,
    employees: PropTypes.arrayOf(PropTypes.exact(Employee)),
    existingBambooEmployees: PropTypes.arrayOf(PropTypes.string),
    existingHarvestEmployees: PropTypes.arrayOf(PropTypes.string),
    existingAzureADEmployees: PropTypes.arrayOf(PropTypes.string),
    existingGoogleAppsEmployees: PropTypes.arrayOf(PropTypes.string),
    existingUbikEmployees: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    employees: [],
    existingBambooEmployees: [],
    existingHarvestEmployees: [],
    existingAzureADEmployees: [],
    existingGoogleAppsEmployees: [],
    existingUbikEmployees: [],
  };

  componentDidMount() {
    this.props.getEmployees();
    this.props.getExistingBambooEmployees();
    this.props.getExistingHarvestEmployees();
    this.props.getExistingAzureADEmployees();
    this.props.getExistingGoogleAppsEmployees();
    this.props.getExistingUbikEmployees();
  }

  render() {
    if (this.props.isFetching) return <Loader />;

    return (
      <EmployeeList
        employees={this.props.employees}
        existingBambooEmployees={this.props.existingBambooEmployees}
        existingHarvestEmployees={this.props.existingHarvestEmployees}
        existingAzureADEmployees={this.props.existingAzureADEmployees}
        existingGoogleAppsEmployees={this.props.existingGoogleAppsEmployees}
        existingUbikEmployees={this.props.existingUbikEmployees}
        removeEmployee={this.props.removeEmployee}
      />
    );
  }
}

const mapStateToProps = state => ({
  isFetching: isFetchingEntities(state, ['employees']),
  employees: Object.values(state.employee.employeesById),
  existingBambooEmployees: state.employee.existingBambooEmployees,
  existingHarvestEmployees: state.employee.existingHarvestEmployees,
  existingAzureADEmployees: state.employee.existingAzureADEmployees,
  existingGoogleAppsEmployees: state.employee.existingGoogleAppsEmployees,
  existingUbikEmployees: state.employee.existingUbikEmployees,
});

export default connect(
  mapStateToProps,
  {
    getEmployees,
    getExistingBambooEmployees,
    getExistingHarvestEmployees,
    getExistingAzureADEmployees,
    getExistingGoogleAppsEmployees,
    getExistingUbikEmployees,
    removeEmployee,
  }
)(EmployeeListPage);
