import axios from 'axios';
import i18n from 'i18next';
import { badRequestErrorCode, errorErrorCodes, redirectionErrorCodes, warningErrorCodes } from '../helpers';
import {
  FINISH_TRANSITION,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  POP_BREADCRUMB,
  RESET_URL_TO_NAVIGATE,
  SET_AUTH_USER,
  SET_BREADCRUMBS,
  SET_ERROR,
  SET_MODAL_MESSAGE,
  SET_ROUTER_STATE,
  SET_TOAST_MESSAGE,
  SET_URL_TO_NAVIGATE,
  START_ASYNC_ACTION,
  START_FETCHING,
  STOP_ASYNC_ACTION,
  STOP_FETCHING,
  UNSET_MODAL_MESSAGE,
  UNSET_TOAST_MESSAGE,
} from './types';

export const startFetching = entityName => ({ type: START_FETCHING, payload: entityName });

export const stopFetching = entityName => ({ type: STOP_FETCHING, payload: entityName });

export const navigateToUrl = url => ({
  type: SET_URL_TO_NAVIGATE,
  payload: url,
});

export const resetNavigateToUrl = () => ({ type: RESET_URL_TO_NAVIGATE });

export const setBreadcrumbs = breadcrumbs => ({ type: SET_BREADCRUMBS, payload: breadcrumbs });

export const popBreadcrumb = () => ({ type: POP_BREADCRUMB });

export const setAuthUser = user => ({ type: SET_AUTH_USER, payload: user });

export const pushToastMessage = (resourceType, entityName, count = 1) => {
  const message = i18n.t(`message.${resourceType}`, {
    interpolation: { escapeValue: false },
    resource: i18n.t(`resource.${entityName}`),
    count,
  });
  return { type: SET_TOAST_MESSAGE, payload: message };
};

export const popToastMessage = () => ({ type: UNSET_TOAST_MESSAGE });

export const pushModalMessage = (message, isWarning = false) => ({
  type: SET_MODAL_MESSAGE,
  payload: { message, isWarning },
});

export const popModalMessage = () => ({ type: UNSET_MODAL_MESSAGE });

export const handleError = error => dispatch => {
  const errorCodeFromResponse = error && error.response ? String(error.response.status) : '';
  if (redirectionErrorCodes.includes(errorCodeFromResponse)) {
    return dispatch(navigateToUrl(`/${errorCodeFromResponse}`));
  }

  const errorCode = [...warningErrorCodes, ...errorErrorCodes].includes(errorCodeFromResponse)
    ? errorCodeFromResponse
    : 'global';

  const isWarningError = warningErrorCodes.includes(errorCode);
  const message = i18n.t(`message.${errorCode}`);

  return dispatch(pushModalMessage(message, isWarningError));
};

const arrayBufferToString = buffer => {
  if ('TextDecoder' in window) {
    // Decode as UTF-8
    const dataView = new DataView(buffer);
    const decoder = new TextDecoder('utf8');
    return JSON.parse(decoder.decode(dataView));
  }
  // Fallback decode as ASCII
  const decodedString = String.fromCharCode.apply(null, new Uint8Array(buffer));
  return JSON.parse(decodedString);
};

export const pushModalMessageError = error => dispatch => {
  const errorCodeFromResponse = error && error.response ? error.response.status : '';
  const errorCode = redirectionErrorCodes
    .concat(warningErrorCodes)
    .concat(errorErrorCodes)
    .concat(badRequestErrorCode)
    .includes(errorCodeFromResponse)
    ? errorCodeFromResponse
    : 'global';

  let msg = '';
  if (error.response && error.response.data && typeof error.response.data === 'string') msg = error.response.data;
  else if (error.response.data.error) msg = error.response.data.error;
  else msg = arrayBufferToString(error.response.data).error;

  const isWarningError = warningErrorCodes.includes(errorCode);
  const detailsMessage = error.response && error.response.data ? `\n\nDetails:\n${msg}` : '';
  const message = `${i18n.t(`message.${errorCode}`)}${detailsMessage}`;

  return dispatch(pushModalMessage(message, isWarningError));
};

export const setRouterState = (stateName, params) => ({ type: SET_ROUTER_STATE, payload: { stateName, params } });

export const finishTransition = (stateName, params) => ({ type: FINISH_TRANSITION, payload: { stateName, params } });

export const receiveError = message => ({
  type: LOGIN_FAILURE,
  payload: {
    isAsyncActionInProgress: false,
    isAuthenticated: false,
    errorMessage: message,
  },
});

export const receiveLogin = user => async dispatch => {
  try {
    dispatch({ type: START_ASYNC_ACTION });
    dispatch({ type: LOGIN_SUCCESS, payload: { isAuthenticated: true, user } });
  } catch (error) {
    dispatch({ type: SET_ERROR, payload: error });
  } finally {
    dispatch({ type: STOP_ASYNC_ACTION });
  }
};

export const setAccessToken = accessToken => async dispatch => {
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } catch (error) {
    dispatch({ type: SET_ERROR, payload: error });
  }
};
