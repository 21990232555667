import PropTypes from 'prop-types';

const Employee = {
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  workEmail: PropTypes.string,
  jiraId: PropTypes.string,
  tempoId: PropTypes.number,
  bambooHrId: PropTypes.number,
  googleAppsId: PropTypes.string,
  azureAdId: PropTypes.string,
  bambooHrEmployeeNumber: PropTypes.number,
  harvestId: PropTypes.number,
  department: PropTypes.string,
  gender: PropTypes.string,
  homeEmail: PropTypes.string,
  mobilePhone: PropTypes.string,
  extensionNumber: PropTypes.number,
  hireDate: PropTypes.string,
  salary: PropTypes.string,
};

export default Employee;
