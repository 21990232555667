import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import BillableHourRatiosDetailsRow from './BillableHourRatiosDetailsRow';
import { BillableHourRatiosEmployee } from '../../types';

class BillableHourRatiosDetails extends React.PureComponent {
  static propTypes = {
    areDetailsOpened: PropTypes.bool.isRequired,
    detailedEmployeesHourRatios: PropTypes.arrayOf(PropTypes.exact(BillableHourRatiosEmployee)).isRequired,
    onDetailsOpen: PropTypes.func.isRequired,
  };

  render() {
    return (
      <>
        <div className="grid__row">
          <div className="grid__item -span-3">
            <button type="button" className="button -action -primary" onClick={this.props.onDetailsOpen}>
              {this.props.areDetailsOpened ? i18n.t('button.detailsHide') : i18n.t('button.detailsShow')}
            </button>
          </div>
        </div>

        {this.props.areDetailsOpened && (
          <div className="tile">
            <div className="grid">
              <h6 className="grid__heading -span-3">{i18n.t('employee.name')}</h6>
              <h6 className="grid__heading -span-2">{i18n.t('billableHourRatios.billableRatio')}</h6>
              <h6 className="grid__heading -span-2">{i18n.t('billableHourRatios.nonBillableRatio')}</h6>
              <h6 className="grid__heading -span-2">{i18n.t('billableHourRatios.absenteeRatio')}</h6>

              {this.props.areDetailsOpened &&
                this.props.detailedEmployeesHourRatios.map(employeeHourRatios => (
                  <BillableHourRatiosDetailsRow key={employeeHourRatios.id} employeeHourRatios={employeeHourRatios} />
                ))}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default BillableHourRatiosDetails;
