import { ConfirmModal, Modal } from '@vooban/modals';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import _orderBy from 'lodash.orderby';
import { Employee, ProjectMapping } from '../../types';
import Team from '../../types/Team';
import TeamCreationModal from './TeamCreationModal';
import TeamListRow from './TeamListRow';
import sectionsAndPages from '../../helpers/sectionsAndPages';

class TeamList extends React.PureComponent {
  static propTypes = {
    employees: PropTypes.arrayOf(PropTypes.exact(Employee)).isRequired,
    projectMapping: PropTypes.exact(ProjectMapping).isRequired,
    onCreateTeamClick: PropTypes.func.isRequired,
    onDeleteTeamClick: PropTypes.func.isRequired,
    teams: PropTypes.arrayOf(PropTypes.exact(Team)).isRequired,
  };

  state = {
    teamToDelete: {},
  };

  teamCreationModalRef = React.createRef();

  teamDeletionModal = React.createRef();

  closeTeamCreationModal = () => this.teamCreationModalRef.current.close();

  handleCreateTeamClick = team => {
    this.props.onCreateTeamClick(team);
    this.closeTeamCreationModal();
  };

  handleSetTeamToDelete = teamToDelete => this.setState({ teamToDelete });

  handleDeleteTeamClick = () => this.props.onDeleteTeamClick(this.state.teamToDelete, this.props.projectMapping);

  openTeamCreationModal = () => this.teamCreationModalRef.current.open();

  openTeamDeletionModal = (e, teamToDelete) => {
    e.stopPropagation();
    this.handleSetTeamToDelete(teamToDelete);
    this.teamDeletionModal.current.open();
  };

  render() {
    const { employees, teams } = this.props;

    return (
      <>
        <div className="tile -no-top">
          <header className="tile__header">
            <h3 className="view__subtitle">{i18n.t('title.teams')}</h3>
            <div className="row -align-bottom -flex-children">
              <button type="button" className="button -primary" onClick={this.openTeamCreationModal}>
                {i18n.t('team.createTeam')}
              </button>
            </div>
          </header>

          <section className="grid">
            <h6 className="grid__heading -span-6">{i18n.t('team.name')}</h6>
            <h6 className="grid__heading -span-5">{i18n.t('team.teamLead')}</h6>
            <h6 className="grid__heading -span-1 -align-right">{i18n.t('team.actions')}</h6>

            {_orderBy(teams, [team => team.name.toLowerCase()], ['ASC']).map(team => {
              const teamLead = employees.find(employee => employee.id === team.teamLeadEmployeeId);
              const handleDeleteClick = e => this.openTeamDeletionModal(e, team);

              return (
                <TeamListRow
                  key={team.id}
                  link={{ to: sectionsAndPages.teamDetails, params: { teamId: team.id } }}
                  team={team}
                  teamLead={teamLead}
                  onDeleteClick={handleDeleteClick}
                />
              );
            })}
          </section>
        </div>

        <Modal ref={this.teamCreationModalRef} width="medium">
          <TeamCreationModal
            employees={this.props.employees}
            projectMapping={this.props.projectMapping}
            onCloseClick={this.closeTeamCreationModal}
            onCreateClick={this.handleCreateTeamClick}
          />
        </Modal>

        <ConfirmModal
          ref={this.teamDeletionModal}
          onConfirm={this.handleDeleteTeamClick}
          title={i18n.t('team.confirmRemoveTeam', { teamName: this.state.teamToDelete.name })}
          cancelLabel={i18n.t('button.no')}
          confirmLabel={i18n.t('button.yes')}
        />
      </>
    );
  }
}

export default TeamList;
