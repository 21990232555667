import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { FastField, Formik, Form } from 'formik';
import { FormikText } from '@vooban/formik-inputs';
import { validate, required, email } from '../../helpers/formHelpers';

class AddContactModal extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
  };

  handleCancelClick = () => this.props.onClose();

  handleSubmit = (values, { resetForm }) => {
    this.props.onConfirm(values);
    resetForm({});
  };

  render() {
    return (
      <>
        <section className="modal__header">
          <div className="grid">
            <h1 className="view__title grid__item -span-10">{i18n.t('contact.new')}</h1>
            <button type="button" className="button grid__item -align-right" onClick={this.handleCancelClick}>
              {i18n.t('button.cancel')}
            </button>
            <button type="submit" className="button -primary grid__item -align-right" form="createContactForm">
              {i18n.t('button.add')}
            </button>
          </div>
        </section>

        <section className="modal__body">
          <Formik onSubmit={this.handleSubmit}>
            <Form id="createContactForm" className="grid__item -span-12">
              <div className="grid__row">
                <FastField
                  component={FormikText}
                  name="name"
                  label={i18n.t('contact.name')}
                  validate={validate([required])}
                  className="grid__item -span-6"
                />
                <FastField
                  component={FormikText}
                  name="title"
                  label={i18n.t('contact.title')}
                  className="grid__item -span-6"
                />
              </div>
              <div className="grid__row">
                <FastField
                  component={FormikText}
                  name="email"
                  label={i18n.t('contact.email')}
                  validate={validate([email, required])}
                  className="grid__item -span-6"
                />
                <FastField
                  component={FormikText}
                  name="telephone"
                  label={i18n.t('contact.telephone')}
                  validate={validate([required])}
                  className="grid__item -span-6"
                />
                <FastField
                  component={FormikText}
                  name="cellphone"
                  label={i18n.t('contact.cellphone')}
                  className="grid__item -span-6"
                />
              </div>
            </Form>
          </Formik>
        </section>
      </>
    );
  }
}

export default AddContactModal;
