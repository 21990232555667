export default {
  absenteeRatio: 'Absentee',
  administration: 'Administration department ratios',
  billableRatio: 'Billable',
  endDate: 'End date',
  generalDates: 'Leave dates empty for a globalr ',
  nonBillableRatio: 'Non billable',
  production: 'Production department ratios',
  startDate: 'Start date',
};
