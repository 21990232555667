import i18n from 'i18next';
import _isEmpty from 'lodash.isempty';
import { maxUploadSizeInMB } from './fileHelpers';

export const validate = validations => value => {
  let result;
  validations.find(validation => {
    result = validation(value);
    return !!result;
  });

  return result;
};

export const email = value =>
  value.match(/^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/)
    ? undefined
    : i18n.t('validation.invalidEmail');

export const required = value => (!value ? i18n.t('validation.required') : undefined);

export const notEmpty = value => (_isEmpty(value) ? i18n.t('validation.required') : undefined);

export const bothDatesRequired = (startDate, endDate) =>
  (startDate && endDate) || (!startDate && !endDate) ? undefined : { dates: i18n.t('validation.bothDatesRequired') };

export const minDate = minimumDate => value => {
  if (!minimumDate) return undefined;

  return new Date(value) < new Date(minimumDate) ? i18n.t('validation.dateMustBeGreater') : undefined;
};

export const maxDate = maximumDate => value => {
  if (!maximumDate) return undefined;

  return new Date(value) > new Date(maximumDate) ? i18n.t('validation.dateMustBeLesser') : undefined;
};

export const formatPhoneNumber = phoneNumber => phoneNumber.replace(/[^0-9]/g, '');

export const cleanObject = object => {
  const newObject = {};
  Object.keys(object).forEach(property => {
    if (object[property]) newObject[property] = object[property];
  });
  return newObject;
};

export const fileSize = file => () => {
  if (!file) return undefined;
  const fileSizeInMB = Math.round((file.size / 1024 ** 2) * 100) / 100;

  return fileSizeInMB > maxUploadSizeInMB
    ? i18n.t('message.maxUploadLimit', { maximum: maxUploadSizeInMB })
    : undefined;
};

export const maxAmount = min => max => {
  if (!min || !max) return undefined;

  return min > max ? i18n.t('validation.maxMustBeGreaterThanMin') : undefined;
};
