import PropTypes from 'prop-types';
import HarvestWeek from "./HarvestWeek";

const HarvestTimesheet = {
  id: PropTypes.number,
  employeeId: PropTypes.number,
  userEmail: PropTypes.string,
  userName: PropTypes.string,
  harvestWeeks: PropTypes.arrayOf(PropTypes.shape(HarvestWeek)),
};

export default HarvestTimesheet;
