import PropTypes from 'prop-types';

const Client = {
  id: PropTypes.number,
  name: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
  currency: PropTypes.string,
  responsibleEmployeeId: PropTypes.number,
  billingName: PropTypes.string,
  billingPhone: PropTypes.string,
  billingEmail: PropTypes.string,
  address: PropTypes.string,
  zipCode: PropTypes.string,
  city: PropTypes.string,
  province: PropTypes.string,
  country: PropTypes.string,
  isArchived: PropTypes.bool,
};

export default Client;
