export default {
  balancing: 'Balance',
  confirmSendToAll: 'Envoyer un email à tous les utilisateurs ne balançant pas?',
  employeeNumber: "Numéro d'employé",
  endDate: 'Date de fin',
  enterProjectNumber: 'Saisir identifiant de projet',
  gap: 'Écart',
  harvestHours: 'Heures Harvest',
  jiraHours: 'Heures Jira',
  name: 'Nom',
  project: 'Projet',
  sendEmail: 'Envoyer Email',
  startDate: 'Date de début',
};
