import React from 'react';
import PropTypes from 'prop-types';
import BugRateReportItem from './BugRateReportItem';

const secondsInHour = 3600;
const percentMultiplier = 100;

class BugRateReportTotal extends React.PureComponent {
  static propTypes = {
    bugRateReportItems: PropTypes.arrayOf(PropTypes.exact(BugRateReportItem)).isRequired,
  };

  get totalIssues() {
    return this.props.bugRateReportItems.reduce((total, item) => total + item.nbTotalIssues, 0);
  }

  get totalInternalBugs() {
    return this.props.bugRateReportItems.reduce((total, item) => total + item.nbInternalBugs, 0);
  }

  get totalBugs() {
    return this.props.bugRateReportItems.reduce((total, item) => total + item.nbBugs, 0);
  }

  get totalTimeSpent() {
    return this.props.bugRateReportItems.reduce((total, item) => total + item.totalTimeSpent, 0);
  }

  get totalTimeSpentInHours() {
    return (this.totalTimeSpent / secondsInHour).toFixed(2);
  }

  get totalTimeSpentInternalBugs() {
    return this.props.bugRateReportItems.reduce((total, item) => total + item.totalTimeSpentInternalBugs, 0);
  }

  get totalTimeSpentInHoursInternalBugs() {
    return (this.totalTimeSpentInternalBugs / secondsInHour).toFixed(2);
  }

  get totalTimeSpentBugs() {
    return this.props.bugRateReportItems.reduce((total, item) => total + item.totalTimeSpentBugs, 0);
  }

  get totalTimeSpentInHoursBugs() {
    return (this.totalTimeSpentBugs / secondsInHour).toFixed(2);
  }

  getFormattedRatio = (numerator, denominator) =>
    !!numerator && !!denominator ? ((numerator / denominator) * percentMultiplier).toFixed(2) : '-';

  render() {
    return (
      <section className="grid__item -span-10">
        <div className="tile">
          <section className="grid__row -custom">
            <div className="grid__heading -span-2" />
            <h6 className="grid__heading -span-1 -align-center">
              {this.totalInternalBugs} / {this.totalIssues}
            </h6>
            <h6 className="grid__heading -span-1 -align-center">
              {this.getFormattedRatio(this.totalInternalBugs, this.totalIssues)}
            </h6>
            <h6 className="grid__heading -span-2 -align-center">
              {this.totalTimeSpentInHoursInternalBugs} h / {this.totalTimeSpentInHours} h
            </h6>
            <h6 className="grid__heading -span-1 -align-center">
              {this.getFormattedRatio(this.totalTimeSpentInternalBugs, this.totalTimeSpent)}
            </h6>
            <h6 className="grid__heading -span-1 -align-center">
              {this.totalBugs} / {this.totalIssues}
            </h6>
            <h6 className="grid__heading -span-1 -align-center">
              {this.getFormattedRatio(this.totalBugs, this.totalIssues)}
            </h6>
            <h6 className="grid__heading -span-2 -align-center">
              {this.totalTimeSpentInHoursBugs} h / {this.totalTimeSpentInHours} h
            </h6>
            <h6 className="grid__heading -span-1 -align-center">
              {this.getFormattedRatio(this.totalTimeSpentBugs, this.totalTimeSpent)}
            </h6>
          </section>
        </div>
      </section>
    );
  }
}

export default BugRateReportTotal;
