import { combineReducers } from 'redux';
import appReducer from './appReducer';
import clientReducer from './clientReducer';
import employeeReducer from './employeeReducer';
import projectReducer from './projectReducer';
import reportingReducer from './reportingReducer';
import teamReducer from './teamReducer';
import accountingReducer from './accountingReducer';
import overtimeRequestReducer from './overtimeRequestReducer';
import contactReducer from './contactReducer';

const rootReducer = combineReducers({
  app: appReducer,
  project: projectReducer,
  reporting: reportingReducer,
  team: teamReducer,
  employee: employeeReducer,
  overtimeRequest: overtimeRequestReducer,
  accounting: accountingReducer,
  contact: contactReducer,
  client: clientReducer,
});

export default rootReducer;
