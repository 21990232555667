export default {
  children: 'Children',
  customQuery: 'Custom Query',
  deleteQuery: 'Delete Query',
  employee: 'Author',
  emptyDates: 'Dates are optionnal',
  endDate: 'End Date',
  enterIssueNumber: 'Enter JIRA Issue Number',
  enterProjectKey: 'Enter JIRA Project Key',
  from: 'From',
  hours: 'hours',
  issue: 'Issue',
  issues: 'Issues',
  maintenanceTimeSpent: 'Maintenance (hours)',
  newTimeSpent: 'New (hours)',
  projectKey: 'Project Key',
  queryName: 'Query Name (For Save Only)',
  runQuery: 'Run Query',
  savedQueries: 'Saved Queries',
  saveQuery: 'Save Query',
  startDate: 'Start Date',
  startTyping: 'Please start typing',
  summary: 'Summary',
  totalTimeSpent: 'Total Time Spent (hours)',
  to: 'To',
  total: 'Total',
  worklogs: 'Worklogs',
};
