import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TimeComparisonType from '../../../types/TimeComparison';

class TimeComparisonListRow extends PureComponent {
  static propTypes = {
    onSendEmail: PropTypes.func.isRequired,
    timeComparison: PropTypes.exact(TimeComparisonType).isRequired,
    timeDifference: PropTypes.number.isRequired,
  };

  handleSendEmailClick = () => this.props.onSendEmail(this.props.timeComparison);

  render() {
    const { icon, color } =
      this.props.timeDifference === 0
        ? { icon: 'check-circle', color: 'green' }
        : { icon: 'times-circle', color: 'red' };
    return (
      <article className="grid__row">
        <span className="grid__item -span-4">{this.props.timeComparison.name}</span>
        <span className="grid__item -span-2">{this.props.timeComparison.jiraHours.toFixed(2)}</span>
        <span className="grid__item -span-2">{this.props.timeComparison.harvestHours.toFixed(2)}</span>
        <span className="grid__item -span-2 -align-center">
          {this.props.timeDifference !== 0 ? this.props.timeDifference.toFixed(2) : '-'}
        </span>
        <span className="grid__item -span-1 -align-center">
          <FontAwesomeIcon icon={icon} style={{ color }} />
        </span>
        {this.props.timeDifference > 0 && (
          <span className="grid__item -span-1 -align-center">
            <button type="button" onClick={this.handleSendEmailClick}>
              <FontAwesomeIcon icon="envelope" className="envelope" />
            </button>
          </span>
        )}
      </article>
    );
  }
}

export default TimeComparisonListRow;
