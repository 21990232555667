export const redirectionErrorCodes = ['401', '403', '404'];
export const warningErrorCodes = ['502', '503'];
export const errorErrorCodes = ['400', '500'];
export const badRequestErrorCode = '400';

export const statusType = {
  created: 'created',
  deleted: 'deleted',
  updated: 'updated',
  generated: 'generated',
};
