import PropTypes from 'prop-types';

const Contact = {
  id: PropTypes.number,
  title: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  telephone: PropTypes.string,
  cellphone: PropTypes.string,
  clientId: PropTypes.number,
  projectId: PropTypes.number,
};

export default Contact;
