import PropTypes from 'prop-types';

const ProjectPurchaseOrder = {
  id: PropTypes.number,
  projectId: PropTypes.number,
  receiptId: PropTypes.number,
  number: PropTypes.string,
  date: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  spanManDays: PropTypes.number,
  spanMoney: PropTypes.number,
};

export default ProjectPurchaseOrder;
