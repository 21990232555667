import React from 'react';
import { UISref } from '@uirouter/react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import sectionsAndPages from '../../../helpers/sectionsAndPages';
import {
  getDangerIcon,
  getFailureIcon,
  getSuccessIcon,
  getWarningIcon,
} from '../../../helpers/authorization/iconHelpers';
import { HarvestTimesheet, OvertimeEntry, HarvestWeek } from '../../../types';

export default class TimesheetListItem extends React.PureComponent {
  static propTypes = {
    shouldHideApprovedTimesheets: PropTypes.bool.isRequired,
    timesheet: PropTypes.exact(HarvestTimesheet).isRequired,
    onSendTimesheetReminder: PropTypes.func.isRequired,
    overtimeEntries: PropTypes.arrayOf(PropTypes.exact(OvertimeEntry)).isRequired,
    userName: PropTypes.string.isRequired,
    harvestWeek: PropTypes.exact(HarvestWeek),
  };

  static defaultProps = {
    harvestWeek: null,
  };

  getUserOvertimeEntry = userEmail => this.props.overtimeEntries.find(entry => entry.userEmail === userEmail);

  getWeekIcon = condition => (condition ? getSuccessIcon() : getFailureIcon());

  getWeekOvertimeIcon = weekOvertimeHours => {
    if (weekOvertimeHours >= 15) return getDangerIcon();
    if (weekOvertimeHours >= 5) return getWarningIcon();
    return '-';
  };

  getTotalOvertimeIcon = totalOvertimeHours => {
    if (totalOvertimeHours >= 20) return getDangerIcon();
    if (totalOvertimeHours >= 10) return getWarningIcon();
    return '-';
  };

  getUserOvertimeWeek = (overtimeEntry, startDate) => {
    if (!overtimeEntry) return null;
    return overtimeEntry.overtimeWeeks.find(week => week.startDate === startDate);
  };

  handleEnvelopeClick = (e, timesheet, startDate) => {
    e.stopPropagation();
    const emailInfo = [
      {
        employeeEmail: timesheet.userEmail,
        employeeName: timesheet.userName,
        timesheetWeek: this.formatDate(startDate),
        administrator: this.props.userName,
      },
    ];
    this.props.onSendTimesheetReminder(emailInfo);
  };

  render() {
    const { employeeOvertimeReport } = sectionsAndPages;
    const { harvestWeek, timesheet } = this.props;

    const isTimesheetApproved = harvestWeek && harvestWeek.isApproved && harvestWeek.isSubmitted;
    if (this.props.shouldHideApprovedTimesheets && isTimesheetApproved) {
      return null;
    }

    const userOvertimeEntry = this.getUserOvertimeEntry(timesheet.userEmail);
    const userOvertimeWeek = harvestWeek ? this.getUserOvertimeWeek(userOvertimeEntry, harvestWeek.startDate) : null;
    const weekOvertimeHours = userOvertimeWeek ? userOvertimeWeek.hours : 0;
    const fourWeeksOvertimeHours = userOvertimeEntry ? userOvertimeEntry.fourWeeksHours : 0;
    const overtimeUnpaidExtra = userOvertimeWeek ? userOvertimeWeek.unpaidExtra : '-';

    return (
      <UISref to={employeeOvertimeReport} params={{ employeeId: timesheet.employeeId }}>
        <article className="grid__row -linked" key={timesheet.userEmail}>
          <span className="grid__item -span-4">{timesheet.userName}</span>
          <span className="grid__item -span-1 -align-center">{harvestWeek ? harvestWeek.hours.toFixed(2) : '-'}</span>
          <span className="grid__item -span-2 -align-center">
            {harvestWeek ? this.getWeekIcon(harvestWeek.isSubmitted) : '-'}
          </span>
          <span className="grid__item -span-1 -align-center">
            {harvestWeek ? this.getWeekIcon(harvestWeek.isApproved) : '-'}
          </span>
          <span className="grid__item -span-1 -align-center">{this.getWeekOvertimeIcon(weekOvertimeHours)}</span>
          <span className="grid__item -span-1 -align-center">{this.getTotalOvertimeIcon(fourWeeksOvertimeHours)}</span>
          <span className="grid__item -span-1 -align-center">{overtimeUnpaidExtra}</span>
          {harvestWeek && !harvestWeek.isSubmitted && (
            <span className="grid__item -span-1 -align-center">
              <button type="button" onClick={e => this.handleEnvelopeClick(e, timesheet, harvestWeek.startDate)}>
                <FontAwesomeIcon icon="envelope" className="envelope" />
              </button>
            </span>
          )}
        </article>
      </UISref>
    );
  }
}
