export default {
  children: 'Enfant (s)',
  customQuery: 'Requête personnalisée',
  deleteQuery: 'Supprimer la requête',
  employee: 'Auteur (e)',
  emptyDates: 'Les dates sont optionnelles',
  endDate: 'Date de fin',
  enterIssueNumber: 'Saisir code de problème JIRA',
  enterProjectKey: 'Enter clé de projet JIRA',
  from: 'De',
  hours: 'heures',
  issue: 'Problème',
  issues: 'Problèmes',
  maintenanceTimeSpent: 'Maintenance (heures)',
  newTimeSpent: 'Nouveau (heures)',
  projectKey: 'Clé de projet',
  queryName: 'Nom de la requête (pour la sauvegarde)',
  runQuery: 'Exécuter la requête',
  savedQueries: 'Requêtes enregistrées',
  saveQuery: 'Sauvegarder la requête',
  startDate: 'Date de début',
  startTyping: 'Commencer à écrire',
  summary: 'Résumé',
  totalTimeSpent: 'Temps Passé Total (heures)',
  to: 'À',
  total: 'Total',
  worklogs: 'Carnet de travail',
};
