export default {
  actions: 'Actions',
  confirmRemove: 'Êtes-vous sûr de vouloir supprimer cette entrée?',
  edit: 'Modifier le contact',
  email: 'Courriel',
  name: 'Nom',
  new: 'Nouveau contact',
  none: 'Aucun',
  telephone: 'Téléphone',
  cellphone: 'Mobile',
  title: 'Titre',
};
