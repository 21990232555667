import React, { PureComponent } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import _orderBy from 'lodash.orderby';
import { ConfirmModal } from '@vooban/modals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UISref } from '@uirouter/react';
import sectionsAndPages from '../../helpers/sectionsAndPages';
import commonActions from '../../helpers/authorization/abilityActions';
import Client from '../../types/Client';
import Can from '../ability/Can';

class ClientList extends PureComponent {
  static propTypes = {
    clients: PropTypes.arrayOf(PropTypes.exact(Client)).isRequired,
    onArchiveClick: PropTypes.func.isRequired,
  };

  state = {
    value: '',
    clientToArchive: {},
  };

  clientArchiveModal = React.createRef();

  get filteredClients() {
    let newClientList = this.props.clients;

    if (this.state.value && this.state.value.length > 0) {
      newClientList = newClientList.filter(client => {
        const name = client.name ? client.name.toLowerCase() : '';
        const phone = client.phone ? client.phone.toLowerCase() : '';
        const website = client.website ? client.website.toLowerCase() : '';

        return (
          name.includes(this.state.value.toLowerCase()) ||
          phone.includes(this.state.value.toLowerCase()) ||
          website.includes(this.state.value.toLowerCase())
        );
      });
    }
    return _orderBy(newClientList, x => x.name.toLowerCase());
  }

  openArchiveModal = (e, client) => {
    e.stopPropagation();
    this.handleSetClientToArchive(client);
    this.clientArchiveModal.current.open();
  };

  handleSetClientToArchive = clientToArchive => this.setState({ clientToArchive });

  handleArchiveClick = () => this.props.onArchiveClick(this.state.clientToArchive);

  handleWebsiteClick = (e, website) => {
    e.stopPropagation();
    window.open(website, '_blank');
  };

  handleSearchValueChange = event => {
    const { value } = event.target;
    this.setState({ value });
  };

  render() {
    return (
      <>
        <div className="grid">
          <section className="grid__item -span-8">
            <div className="grid">
              <aside className="grid__item -span-6">
                <h1 className="view__title ">{i18n.t('title.clients')}</h1>
              </aside>
              <Can I={commonActions.navigateTo} a={sectionsAndPages.newClient}>
                <aside className="grid__item -span-6 -align-right">
                  <UISref to={sectionsAndPages.newClient}>
                    <button type="button" className="button -primary">
                      {i18n.t('client.new')}
                    </button>
                  </UISref>
                </aside>
              </Can>
              <div className="grid__row">
                <fieldset className="search">
                  <input
                    type="text"
                    className="search__field"
                    onChange={this.handleSearchValueChange}
                    placeholder={i18n.t('search')}
                  />
                  <label htmlFor="search" className="search__icon">
                    <FontAwesomeIcon icon="search" />
                  </label>
                </fieldset>
              </div>
            </div>
            <div className="tile">
              {!this.filteredClients.length && (
                <div className="grid">
                  <div className="grid__item -span-12 -align-center">
                    <p>{i18n.t('message.nothingFound')}</p>
                  </div>
                </div>
              )}
              {!!this.filteredClients.length && (
                <section className="grid">
                  <h6 className="grid__heading -span-3">{i18n.t('client.name')}</h6>
                  <h6 className="grid__heading -span-3">{i18n.t('client.companyPhone')}</h6>
                  <h6 className="grid__heading -span-5">{i18n.t('client.website')}</h6>
                  <div className="grid__heading -span-1" />

                  {this.filteredClients.map(client => (
                    <UISref to={sectionsAndPages.clientInfo} params={{ clientId: client.id }} key={client.id}>
                      <article className="grid__row -linked">
                        <span className="grid__item -span-3">{client.name}</span>
                        <span className="grid__item -span-3">{client.phone}</span>
                        <span className="grid__item -span-5">
                          <button type="button" onClick={e => this.handleWebsiteClick(e, client.website)}>
                            {client.website}
                          </button>
                        </span>
                        <span className="grid__item -span-1 -align-right">
                          <button
                            type="button"
                            className="button__icon"
                            onClick={e => this.openArchiveModal(e, client)}>
                            <FontAwesomeIcon icon="trash-alt" />
                          </button>
                        </span>
                      </article>
                    </UISref>
                  ))}
                </section>
              )}
            </div>
          </section>
        </div>
        <ConfirmModal
          ref={this.clientArchiveModal}
          onConfirm={this.handleArchiveClick}
          title={i18n.t('client.confirmArchive', { clientName: this.state.clientToArchive.name })}
          cancelLabel={i18n.t('button.no')}
          confirmLabel={i18n.t('button.yes')}
        />
      </>
    );
  }
}
export default ClientList;
