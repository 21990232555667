import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18next';
import _orderBy from 'lodash.orderby';
import UpdateTimeOffFeedback from '../../../types/UpdateTimeOffFeedback';

class PreProcessedFileUploadFeedback extends React.PureComponent {
  static propTypes = {
    updateTimeOffFeedback: PropTypes.arrayOf(PropTypes.exact(UpdateTimeOffFeedback)).isRequired,
  };

  render() {
    return (
      <div className="grid">
        <section className="grid__item -span-8">
          <div className="tile">
            <section className="grid">
              <h6 className="grid__heading -span-2">{i18n.t('accounting.employeeNumber')}</h6>
              <h6 className="grid__heading -span-2">{i18n.t('accounting.payFileBalance')}</h6>
              <h6 className="grid__heading -span-2">{i18n.t('accounting.bambooHrBalance')}</h6>
              <h6 className="grid__heading -span-2">{i18n.t('accounting.difference')}</h6>
              <h6 className="grid__heading -span-2">{i18n.t('accounting.timeOffType')}</h6>
              <h6 className="grid__heading -span-2 -align-center">{i18n.t('accounting.success')}</h6>

              {_orderBy(this.props.updateTimeOffFeedback, x => x.employeeNumber).map((feedbackEntry, index) => {
                const icon = feedbackEntry.isSuccess ? 'check-circle' : 'times-circle';
                const color = feedbackEntry.isSuccess ? 'green' : 'red';
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <article className="grid__row" key={`${feedbackEntry.employeeNumber}_${index}`}>
                    <span className="grid__item -span-2">{feedbackEntry.employeeNumber}</span>
                    <span className="grid__item -span-2">{feedbackEntry.payFileBalance}</span>
                    <span className="grid__item -span-2">{feedbackEntry.bambooHrBalance}</span>
                    <span className="grid__item -span-2">
                      {feedbackEntry.amount ? feedbackEntry.amount.toFixed(2) : ''}
                    </span>
                    <span className="grid__item -span-2">{feedbackEntry.timeOffType}</span>
                    <span className="grid__item -span-2 -align-center">
                      <FontAwesomeIcon icon={icon} style={{ color }} />
                    </span>
                  </article>
                );
              })}
            </section>
          </div>
        </section>
      </div>
    );
  }
}

export default PreProcessedFileUploadFeedback;
