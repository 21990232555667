export default {
  approved: 'Approved',
  completed: 'Completed',
  emailToAll: 'Send email to all unsubmitted',
  fifty: '50+',
  forceRefresh: 'Force refresh',
  fourForty: '4x40+',
  hideApprovedTimesheets: 'Hide Approved Timesheets',
  hours: 'Hours',
  name: 'Name',
  submitted: 'Submitted',
  week: 'Week',
  extra: 'Extra',
};
