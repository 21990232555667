import Loader from '@vooban/loader';
import i18n from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import BugRateReportItem from './BugRateReportItem';

const secondsInHour = 3600;
const percentMultiplier = 100;

const BugRateReportList = props => (
  <section className="grid__item -span-10">
    <div className="tile">
      <section className="grid__row -custom">
        <div className="grid__heading -span-2" />
        <h6 className="grid__heading -bug-rate-heading -span-4 -align-center">
          {i18n.t('bugRateReport.internalBugs')}
        </h6>
        <div className="grid__heading -span-1" />
        <h6 className="grid__heading -bug-rate-heading -span-4 -align-center">{i18n.t('bugRateReport.bugs')}</h6>
      </section>
      <section className="grid__row -custom">
        <div className="grid__heading -span-2" />
        <h6 className="grid__heading -span-2 -bug-rate-heading-small -align-center">
          {i18n.t('bugRateReport.number')}
        </h6>
        <h6 className="grid__heading -span-3 -bug-rate-heading-small -align-center">{i18n.t('bugRateReport.time')}</h6>
        <h6 className="grid__heading -span-2 -bug-rate-heading-small -align-center">
          {i18n.t('bugRateReport.number')}
        </h6>
        <h6 className="grid__heading -span-3 -bug-rate-heading-small -align-center">{i18n.t('bugRateReport.time')}</h6>
      </section>
      <section className="grid__row -custom">
        <h6 className="grid__heading -span-2">{i18n.t('bugRateReport.project')}</h6>
        <h6 className="grid__heading -span-1 -align-center">{i18n.t('bugRateReport.total')}</h6>
        <h6 className="grid__heading -span-1 -align-center">{i18n.t('bugRateReport.ratio')}</h6>
        <h6 className="grid__heading -span-2 -align-center">{i18n.t('bugRateReport.spent')}</h6>
        <h6 className="grid__heading -span-1 -align-center">{i18n.t('bugRateReport.spentRatio')}</h6>
        <h6 className="grid__heading -span-1 -align-center">{i18n.t('bugRateReport.total')}</h6>
        <h6 className="grid__heading -span-1 -align-center">{i18n.t('bugRateReport.ratio')}</h6>
        <h6 className="grid__heading -span-2 -align-center">{i18n.t('bugRateReport.spent')}</h6>
        <h6 className="grid__heading -span-1 -align-center">{i18n.t('bugRateReport.spentRatio')}</h6>
      </section>

      {props.isFetching && <Loader />}
      {props.bugRateReportItems.map((bugRateReportItem, index) => {
        const isEven = index % 2;
        return (
          <article
            className={`grid__row -bug-rate-list-row ${!isEven && '-odd'} `}
            key={`${bugRateReportItem.projectKey}`}>
            <span className="grid__item -span-2">{bugRateReportItem.projectKey}</span>
            <span className="grid__item -span-1 -align-center">
              {bugRateReportItem.nbInternalBugs} / {bugRateReportItem.nbTotalIssues}
            </span>
            <span className="grid__item -span-1 -align-center">
              {((bugRateReportItem.nbInternalBugs / bugRateReportItem.nbTotalIssues) * percentMultiplier).toFixed(2)}%
            </span>
            <span className="grid__item -span-2 -align-center">
              {(bugRateReportItem.totalTimeSpentInternalBugs / secondsInHour).toFixed(2)} h /{' '}
              {(bugRateReportItem.totalTimeSpent / secondsInHour).toFixed(2)} h
            </span>
            <span className="grid__item -span-1 -align-center">
              {(
                (bugRateReportItem.totalTimeSpentInternalBugs / bugRateReportItem.totalTimeSpent) *
                percentMultiplier
              ).toFixed(2)}
              %
            </span>
            <span className="grid__item -span-1 -align-center">
              {bugRateReportItem.nbBugs} / {bugRateReportItem.nbTotalIssues}
            </span>
            <span className="grid__item -span-1 -align-center">
              {((bugRateReportItem.nbBugs / bugRateReportItem.nbTotalIssues) * percentMultiplier).toFixed(2)}%
            </span>
            <span className="grid__item -span-2 -align-center">
              {(bugRateReportItem.totalTimeSpentBugs / secondsInHour).toFixed(2)} h /{' '}
              {(bugRateReportItem.totalTimeSpent / secondsInHour).toFixed(2)} h
            </span>
            <span className="grid__item -span-1 -align-center">
              {((bugRateReportItem.totalTimeSpentBugs / bugRateReportItem.totalTimeSpent) * percentMultiplier).toFixed(
                2
              )}
              %
            </span>
          </article>
        );
      })}
    </div>
  </section>
);

BugRateReportList.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  bugRateReportItems: PropTypes.arrayOf(PropTypes.exact(BugRateReportItem)).isRequired,
};

export default BugRateReportList;
