import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';
import _get from 'lodash.get';
import _trim from 'lodash.trim';
import _map from 'lodash.map';

export default class Auth {
  static setSession({ accessToken, idToken, expiresIn }) {
    const oneSecondInMilliseconds = 1000;
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify(expiresIn * oneSecondInMilliseconds + new Date().getTime());
    window.localStorage.setItem('access_token', accessToken);
    window.localStorage.setItem('id_token', idToken);
    window.localStorage.setItem('expires_at', expiresAt);
  }

  static USER_GROUS_CLAIM = 'https://vooban.com/groups/';

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);

    this.auth0 = new auth0.WebAuth({
      audience: 'http://vooban.com/v1/api',
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENTID,
      redirectUri: process.env.REACT_APP_AUTH0_REDIRECTURI,
      responseType: 'token id_token',
      scope: 'openid profile write:accounting',
    });
  }

  login = () => this.auth0.authorize();

  handleAuthentication(sucessCallback, errorCallback) {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        Auth.setSession(authResult);
        sucessCallback(authResult);
      } else if (err) {
        errorCallback(err);
      }
    });
  }

  handleAuthentication = () => {
    this.auth0.parseHash((_err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken && authResult.expiresIn) {
        Auth.setSession(authResult);
        const savedUrlToRedirect = window.localStorage.getItem('requestedURL');
        window.localStorage.removeItem('requestedURL');
        const urlToRedirect = savedUrlToRedirect || '/';
        window.location.replace(urlToRedirect);
      }
    });
  };

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    this.auth0.logout({ returnTo: process.env.REACT_APP_AUTH0_RETURN_TO });
  }

  static pushLastRequestedState(to, params) {
    const state = JSON.stringify({
      to,
      params,
    });
    localStorage.setItem('requestedState', state);
  }

  static popLastRequestedState() {
    const state = localStorage.getItem('requestedState');
    localStorage.removeItem('requestedState');
    return JSON.parse(state);
  }

  static isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  static getUser() {
    const userToken = localStorage.getItem('id_token');
    if (userToken) {
      return jwtDecode(userToken);
    }
    return {};
  }

  static getAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      return accessToken;
    }
    return {};
  }

  static getUserGroups() {
    const accessToken = Auth.getAccessToken();

    if (accessToken) {
      const decodedToken = jwtDecode(accessToken);
      const groups = _get(decodedToken, Auth.USER_GROUS_CLAIM);
      return groups ? _map(groups.split(','), group => _trim(group)) : [];
    }

    return [];
  }
}
