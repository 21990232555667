import { ConfirmModal, Modal } from '@vooban/modals';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Team, Employee } from '../../types';
import TeamMemberAdditionModal from './TeamMemberAdditionModal';
import TeamMemberListRow from './TeamMemberListRow';

class TeamMemberList extends React.PureComponent {
  static propTypes = {
    onAddTeamMemberClick: PropTypes.func.isRequired,
    onRemoveTeamMemberClick: PropTypes.func.isRequired,
    teamMembers: PropTypes.arrayOf(PropTypes.exact(Employee)).isRequired,
    unassignedEmployees: PropTypes.arrayOf(PropTypes.exact(Employee)).isRequired,
    team: PropTypes.exact(Team),
  };

  static defaultProps = {
    team: {},
  };

  state = {
    teamMemberToDelete: {},
  };

  memberAdditionModalRef = React.createRef();

  memberRemovalModal = React.createRef();

  closeMemberAdditionModal = () => this.memberAdditionModalRef.current.close();

  handleAddTeamMemberClick = employee => {
    this.props.onAddTeamMemberClick(employee);
    this.closeMemberAdditionModal();
  };

  handleRemoveTeamMemberClick = () => {
    this.props.onRemoveTeamMemberClick(this.state.teamMemberToDelete);
    this.handleSetTeamMemberToDelete({});
  };

  handleSetTeamMemberToDelete = teamMemberToDelete => this.setState({ teamMemberToDelete });

  openMemberAdditionModal = () => this.memberAdditionModalRef.current.open();

  openMemberRemovalModal = teamMemberToDelete => {
    this.memberRemovalModal.current.open();
    this.handleSetTeamMemberToDelete(teamMemberToDelete);
  };

  render() {
    const { teamMemberToDelete } = this.state;

    return (
      <div className="grid -indent-top">
        <div className="grid__row -header">
          <h1 className="view__title grid__item -span-6">{i18n.t('title.teamMembers')}</h1>
          <div className="grid__item -span-2 -align-right">
            <button type="button" className="button -primary" onClick={this.openMemberAdditionModal}>
              {i18n.t('team.addMember')}
            </button>
          </div>
        </div>

        <div className="grid__row">
          <div className="grid__item -span-8">
            <div className="tile">
              <section className="grid">
                <h6 className="grid__heading -span-4">{i18n.t('team.name')}</h6>
                <h6 className="grid__heading -span-6">{i18n.t('team.email')}</h6>
                <h6 className="grid__heading -span-2">{i18n.t('team.actions')}</h6>
                {this.props.teamMembers.map(teamMember => {
                  const handleDeleteClick = () => this.openMemberRemovalModal(teamMember);
                  return (
                    <TeamMemberListRow key={teamMember.id} teamMember={teamMember} onDeleteClick={handleDeleteClick} />
                  );
                })}
              </section>
            </div>
          </div>
        </div>

        <Modal ref={this.memberAdditionModalRef} width="medium">
          <TeamMemberAdditionModal
            unassignedEmployees={this.props.unassignedEmployees}
            onCloseClick={this.closeMemberAdditionModal}
            onAddClick={this.handleAddTeamMemberClick}
          />
        </Modal>

        <ConfirmModal
          ref={this.memberRemovalModal}
          onConfirm={this.handleRemoveTeamMemberClick}
          title={i18n.t('team.confirmRemoveTeamMember', {
            memberName: `${teamMemberToDelete.firstName} ${teamMemberToDelete.lastName}`,
            teamName: this.props.team.name,
          })}
          cancelLabel={i18n.t('button.no')}
          confirmLabel={i18n.t('button.yes')}
        />
      </div>
    );
  }
}

export default TeamMemberList;
