export default {
  absenteeRatio: 'Absences',
  administration: "Ratios du département d'administration",
  billableRatio: 'Facturable',
  endDate: 'Date de fin',
  generalDates: 'Laisser la date vide pour un rapport global',
  nonBillableRatio: 'Non-facturable',
  production: 'Ratios du département de production',
  startDate: 'Date départ',
};
