import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import Scrollbox from '@vooban/scrollbox';
import IssueWorklogs from '../../../types/IssueWorklogs';

const TimeReportingModalParent = ({ parentIssueWorklogs }) => {
  let totalTimeSpentModal = 0;
  let newTimeSpentModal = 0;
  let maintenanceTimeSpentModal = 0;

  return (
    <Scrollbox innerClass="modal__body grid -tabs" innerTag="section">
      <div className="grid__row -span-12">
        <h6 className="grid__heading -span-3">{i18n.t('timeReporting.employee')}</h6>
        <h6 className="grid__heading -span-3">{i18n.t('timeReporting.totalTimeSpent')}</h6>
        <h6 className="grid__heading -span-3">{i18n.t('timeReporting.newTimeSpent')}</h6>
        <h6 className="grid__heading -span-3">{i18n.t('timeReporting.maintenanceTimeSpent')}</h6>
      </div>
      {parentIssueWorklogs.map((iwl, i) => {
        const isLastElement = parentIssueWorklogs.length - 1 === i;
        totalTimeSpentModal += iwl.totalTimeSpent;
        newTimeSpentModal += iwl.newTimeSpent;
        maintenanceTimeSpentModal += iwl.maintenanceTimeSpent;

        return (
          <article
            key={`${iwl.key}-${iwl.displayName}-parent`}
            className={`grid__row -hover -span-12 ${isLastElement ? '-last' : ''}`}>
            <span className="grid__item -span-3">{iwl.displayName}</span>
            <span className="grid__item -span-3">{iwl.totalTimeSpent.toFixed(2)}</span>
            <span className="grid__item -span-3">{iwl.newTimeSpent.toFixed(2)}</span>
            <span className="grid__item -span-3">{iwl.maintenanceTimeSpent.toFixed(2)}</span>
          </article>
        );
      })}
      <article className="grid__row -hover -span-12">
        <span className="grid__item -span-3">{i18n.t('timeReporting.total')}</span>
        <span className="grid__item -span-3">{totalTimeSpentModal.toFixed(2)}</span>
        <span className="grid__item -span-3">{newTimeSpentModal.toFixed(2)}</span>
        <span className="grid__item -span-3">{maintenanceTimeSpentModal.toFixed(2)}</span>
      </article>
    </Scrollbox>
  );
};

TimeReportingModalParent.propTypes = {
  parentIssueWorklogs: PropTypes.arrayOf(PropTypes.exact(IssueWorklogs)).isRequired,
};

export default TimeReportingModalParent;
