import { AbilityBuilder } from '@casl/ability';
import commonActions from '../abilityActions';
import { isInRole } from '../abilityHelpers';
import userRoles from '../userRoles';
import sectionsAndPages from '../../sectionsAndPages';

const { costTracking } = sectionsAndPages;

export default function getRules(user) {
  const { rules, can } = AbilityBuilder.extract();
  const { navigateTo, get } = commonActions;
  const { projectManager, admin } = userRoles;

  if (isInRole(user, [projectManager, admin])) {
    can(navigateTo, costTracking);
    can(get, costTracking);
  }

  return rules;
}
