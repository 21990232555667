import PropTypes from 'prop-types';
import IssueWorklogs from './IssueWorklogs';

const TimeSpent = {
  from: PropTypes.string,
  issueNumber: PropTypes.string,
  key: PropTypes.string,
  maintenanceTimeSpent: PropTypes.number,
  newTimeSpent: PropTypes.number,
  parentKey: PropTypes.string,
  requestDate: PropTypes.string,
  summary: PropTypes.string,
  to: PropTypes.string,
  totalTimeSpent: PropTypes.number,
  issueWorklogs: PropTypes.arrayOf(PropTypes.exact(IssueWorklogs)),
};

export default TimeSpent;
