import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import Loader from '@vooban/loader';

class UpdateCostRate extends React.PureComponent {
  static propTypes = {
    onUpdateCostRate: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
  };

  handleUpdateCostRateClick = () => this.props.onUpdateCostRate();

  render() {
    return (
      <>
        <div className="grid__item -span-8">
          <h1 className="view__title">{i18n.t('batchUpdates.updateCostRate')}</h1>
          {this.props.isFetching && <Loader />}
          <div className="tile">
            <section className="form__section grid">
              <button
                type="button"
                className="view__action button -primary grid__item -span-2"
                onClick={this.handleUpdateCostRateClick}>
                {i18n.t('button.update')}
              </button>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default UpdateCostRate;
