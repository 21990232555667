import Loader from '@vooban/loader';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { OvertimeEntry, OvertimeMarkedWeek } from '../../../types';
import LastFourWeeksOvertime from './LastFourWeeksOvertime';
import TotalOvertime from './TotalOvertime';
import WeeklyOvertime from './WeeklyOvertime';

class EmployeeOvertimeEntry extends React.PureComponent {
  static propTypes = {
    overtimeEntry: PropTypes.exact(OvertimeEntry),
    isFetching: PropTypes.bool.isRequired,
    markedWeek: PropTypes.shape(OvertimeMarkedWeek),
  };

  static defaultProps = {
    overtimeEntry: null,
    markedWeek: null,
  };

  render() {
    if (this.props.isFetching) return <Loader />;
    const { overtimeEntry, markedWeek } = this.props;

    return (
      <>
        {overtimeEntry && (
          <>
            <h1 className="view__title">
              {`${i18n.t('title.overtimeReport')} ${i18n.t('overtime.for')} ${overtimeEntry.userEmail}`}
            </h1>
            <div className="grid">
              <WeeklyOvertime overtimeWeeks={overtimeEntry.overtimeWeeks} markedWeek={markedWeek} />
              <LastFourWeeksOvertime overtimeEntry={overtimeEntry} />
              <TotalOvertime overtimeEntry={overtimeEntry} />
            </div>
          </>
        )}
        {!overtimeEntry && i18n.t('overtime.noOvertime')}
      </>
    );
  }
}

export default EmployeeOvertimeEntry;
