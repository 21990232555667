export default {
  actions: 'Actions',
  applicant: 'Demandeur',
  approver: 'Approbateur',
  confirmRemove: 'Êtes-vous sûr de vouloir supprimer cette entrée?',
  endDate: 'Date de fin',
  new: 'Nouvelle demande',
  none: 'Aucun',
  project: 'Project associé',
  reason: 'Raison',
  startDate: 'Date de début',
  status: 'Statut',
};
