export default {
  approved: 'Approuvée',
  completed: 'Complète',
  emailToAll: 'Envoyer un courriel à tous les non-soumises',
  fifty: '50+',
  forceRefresh: 'Forcer le rafraîchissement',
  fourForty: '4x40+',
  hideApprovedTimesheets: 'Cacher les feuilles de temps approuvées',
  hours: 'Heures',
  name: 'Nom',
  submitted: 'Soumise',
  week: 'Semaine',
  extra: 'Extra',
};
