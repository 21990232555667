import PropTypes from 'prop-types';

const BillableHourRatiosEmployee = {
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  employeeBillableRatio: PropTypes.number,
  employeeNonBillableRatio: PropTypes.number,
  employeeAbsenteeRatio: PropTypes.number,
};

export default BillableHourRatiosEmployee;
