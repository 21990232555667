import axios from 'axios';
import { statusType } from '../helpers';
import { pushModalMessageError, startFetching, stopFetching, pushToastMessage } from './appActions';

export const updateDefaultHourlyRate = hourlyRate => async dispatch => {
  dispatch(startFetching('updateDefaultHourlyRate'));
  try {
    await axios.post('/harvest/update-default-hourly-rates', { hourlyRate });
    dispatch(pushToastMessage(statusType.updated, 'defaultHourlyRates'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('updateDefaultHourlyRate'));
  }
};

export const updateCostRate = () => async dispatch => {
  dispatch(startFetching('updateCostRate'));
  try {
    await axios.get('/accounting/update-employees-cost-rate');
    dispatch(pushToastMessage(statusType.updated, 'costRates'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('updateCostRate'));
  }
};
