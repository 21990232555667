import axios from 'axios';
import download from 'js-file-download';
import { pushModalMessageError } from '.';
import { startFetching, stopFetching, setRouterState, pushToastMessage } from './appActions';
import sectionsAndPages from '../helpers/sectionsAndPages';
import {
  DELETE_PROJECT_MAPPING,
  SET_HARVEST_PROJECTS,
  SET_HARVEST_TASKS,
  SET_JIRA_PROJECTS,
  SET_PROJECT_MAPPING,
  SET_PROJECT_MAPPINGS,
  SET_PURCHASE_ORDERS,
  ADD_PURCHASE_ORDER,
  REMOVE_PURCHASE_ORDER,
  SET_PROJECT_MAPPING_DOCUMENTS,
  ADD_PROJECT_MAPPING_DOCUMENT,
  REMOVE_PROJECT_MAPPING_DOCUMENT,
  SET_TEMPO_ACCOUNTS,
} from './types';

export const getJiraProjects = () => async dispatch => {
  dispatch(startFetching('jiraProjects'));
  try {
    const response = await axios.get('/jira/project');
    dispatch({ type: SET_JIRA_PROJECTS, payload: response.data });
    dispatch(stopFetching('jiraProjects'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
    dispatch(stopFetching('jiraProjects'));
  }
};

export const getTempoAccounts = () => async dispatch => {
  dispatch(startFetching('tempoAccount'));
  try {
    const response = await axios.get('/node-jira/tempo-accounts');
    dispatch({ type: SET_TEMPO_ACCOUNTS, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('tempoAccount'));
  }
};

const getProjectsByParams = params => async dispatch => {
  dispatch(startFetching('projectMappings'));
  try {
    const response = await axios.get('/project-mappings', { params });
    dispatch({ type: SET_PROJECT_MAPPINGS, payload: response.data });
    dispatch(stopFetching('projectMappings'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
    dispatch(stopFetching('projectMappings'));
  }
};

export const getProjectMappings = () => async dispatch => {
  getProjectsByParams({})(dispatch);
};

export const getProjectWithJiraProject = () => async dispatch => {
  const params = { hasJiraProject: true };
  getProjectsByParams({ params })(dispatch);
};

export const getProjectMappingById = projectMappingId => async dispatch => {
  dispatch(startFetching('projectMappingById'));
  try {
    const response = await axios.get(`/project-mappings/${projectMappingId}`);
    dispatch({ type: SET_PROJECT_MAPPING, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('projectMappingById'));
  }
};

export const createProjectMapping = projectMapping => async dispatch => {
  dispatch(startFetching('projectMappings'));
  try {
    const response = await axios.post(`/project-mappings`, projectMapping);
    dispatch({ type: SET_PROJECT_MAPPING, payload: response.data });
    dispatch(setRouterState(sectionsAndPages.projectDetails, { projectId: response.data.id }));
    dispatch(pushToastMessage('projectMappingCreated'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('projectMappings'));
  }
};

export const updateProjectMapping = projectMapping => async dispatch => {
  dispatch(startFetching('updateProjectMapping'));
  try {
    const response = await axios.put(`/project-mappings/${projectMapping.id}`, projectMapping);
    dispatch({ type: SET_PROJECT_MAPPING, payload: response.data });
    dispatch(pushToastMessage('projectMappingUpdated'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('updateProjectMapping'));
  }
};

export const deleteProjectMapping = Id => async dispatch => {
  try {
    await axios.delete(`/project-mappings/${Id}`);
    dispatch({ type: DELETE_PROJECT_MAPPING, payload: Id });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  }
};

export const getHarvestProjects = () => async dispatch => {
  dispatch(startFetching('harvestProjects'));
  try {
    const params = { isActive: true };
    const response = await axios.get(`/harvest/projects`, { params });
    const newHarvestProjects = response.data.map(p => ({
      ...p,
      id: p.id.toString(),
    }));
    dispatch({ type: SET_HARVEST_PROJECTS, payload: newHarvestProjects });
    dispatch(stopFetching('harvestProjects'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
    dispatch(stopFetching('harvestProjects'));
  }
};

export const getHarvestTasks = () => async dispatch => {
  dispatch(startFetching('harvestTasks'));
  try {
    const params = { isActive: true };
    const response = await axios.get(`/harvest/tasks`, { params });
    const newHarvestTasks = response.data.map(t => ({ ...t, id: t.id.toString() }));
    dispatch({ type: SET_HARVEST_TASKS, payload: newHarvestTasks });
    dispatch(stopFetching('harvestTasks'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
    dispatch(stopFetching('harvestTasks'));
  }
};

export const setProjectPurchaseOrders = purchaseOrders => ({ type: SET_PURCHASE_ORDERS, payload: purchaseOrders });

export const getProjectPurchaseOrders = projectId => async dispatch => {
  dispatch(startFetching('projectPurchaseOrders'));
  try {
    const response = await axios.get(`/project-mappings/${projectId}/purchase-orders`);
    dispatch({ type: SET_PURCHASE_ORDERS, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('projectPurchaseOrders'));
  }
};

export const createProjectPurchaseOrder = (projectId, purchaseOrder) => async dispatch => {
  try {
    const data = new FormData();
    data.append('file', purchaseOrder.file);
    const fileResponse = await axios.post(`/file`, data);

    const purchaseOrderWithReceiptId = { ...purchaseOrder, receiptId: fileResponse.data };
    const response = await axios.post(`/project-mappings/${projectId}/purchase-orders`, purchaseOrderWithReceiptId);
    dispatch({ type: ADD_PURCHASE_ORDER, payload: response.data });
    dispatch(pushToastMessage('projectPurchaseOrderCreated'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  }
};

export const deleteProjectPurchaseOrder = (projectId, purchaseOrderId) => async dispatch => {
  try {
    await axios.delete(`/project-mappings/${projectId}/purchase-orders/${purchaseOrderId}`);
    dispatch({ type: REMOVE_PURCHASE_ORDER, payload: purchaseOrderId });
    dispatch(pushToastMessage('projectPurchaseOrderDeleted'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  }
};

export const downloadFile = fileId => async dispatch => {
  try {
    const response = await axios.get(`/file/${fileId}`);
    const file = response.data;
    download(Buffer.from(file.data.data), `${file.name}`);
  } catch (error) {
    dispatch(pushModalMessageError(error));
  }
};

export const getAssociatedDocuments = projectId => async dispatch => {
  dispatch(startFetching('projectMappingDocuments'));
  try {
    const response = await axios.get(`/file/project-mapping/${projectId}`);
    dispatch({ type: SET_PROJECT_MAPPING_DOCUMENTS, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('projectMappingDocuments'));
  }
};

export const addProjectMappingDocument = (projectId, document) => async dispatch => {
  try {
    const data = new FormData();
    data.append('projectId', projectId);
    data.append('file', document);
    const fileResponse = await axios.post(`/file/project-mapping`, data);

    dispatch({ type: ADD_PROJECT_MAPPING_DOCUMENT, payload: fileResponse.data });
    dispatch(pushToastMessage('projectMappingDocumentAdded'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  }
};

export const deleteProjectMappingDocument = documentId => async dispatch => {
  try {
    await axios.delete(`/file/${documentId}`);
    dispatch({ type: REMOVE_PROJECT_MAPPING_DOCUMENT, payload: documentId });
    dispatch(pushToastMessage('projectMappingDocumentDeleted'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  }
};
