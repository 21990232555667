export const FINISH_TRANSITION = 'FINISH_TRANSITION';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const POP_BREADCRUMB = 'POP_BREADCRUMB';
export const RESET_URL_TO_NAVIGATE = 'RESET_URL_TO_NAVIGATE';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const SET_ERROR = 'SET_ERROR';
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';
export const SET_MODAL_MESSAGE = 'SET_MODAL_MESSAGE';
export const SET_ROUTER_STATE = 'SET_ROUTER_STATE';
export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE';
export const SET_URL_TO_NAVIGATE = 'SET_URL_TO_NAVIGATE';
export const SET_USER = 'SET_USER';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const START_ASYNC_ACTION = 'START_ASYNC_ACTION';
export const START_FETCHING = 'START_FETCHING';
export const STOP_ASYNC_ACTION = 'STOP_ASYNC_ACTION';
export const STOP_FETCHING = 'STOP_FETCHING';
export const UNSET_MODAL_MESSAGE = 'UNSET_MODAL_MESSAGE';
export const UNSET_TOAST_MESSAGE = 'UNSET_TOAST_MESSAGE';

export const DELETE_PROJECT_MAPPING = 'DELETE_PROJECT_MAPPING';
export const SET_HARVEST_PROJECTS = 'SET_HARVEST_PROJECTS';
export const SET_HARVEST_TASKS = 'SET_HARVEST_TASKS';
export const SET_JIRA_PROJECTS = 'SET_JIRA_PROJECTS';
export const SET_PROJECT_MAPPING = 'SET_PROJECT_MAPPING';
export const SET_PROJECT_MAPPINGS = 'SET_PROJECT_MAPPINGS';
export const SET_PURCHASE_ORDERS = 'SET_PURCHASE_ORDERS';
export const ADD_PURCHASE_ORDER = 'ADD_PURCHASE_ORDER';
export const REMOVE_PURCHASE_ORDER = 'REMOVE_PURCHASE_ORDER';
export const SET_PROJECT_MAPPING_DOCUMENTS = 'SET_PROJECT_MAPPING_DOCUMENTS';
export const ADD_PROJECT_MAPPING_DOCUMENT = 'ADD_PROJECT_MAPPING_DOCUMENT';
export const REMOVE_PROJECT_MAPPING_DOCUMENT = 'REMOVE_PROJECT_MAPPING_DOCUMENT';

export const CLEAR_TIME_COMPARISONS = 'CLEAR_TIME_COMPARISONS';
export const SET_TIME_COMPARISONS = 'SET_TIME_COMPARISONS';
export const SET_TIME_ENTRIES = 'SET_TIME_ENTRIES';
export const SET_TIME_SPENT = 'SET_TIME_SPENT';
export const PUSH_SPENT_ON_ISSUE_QUERY = 'PUSH_SPENT_ON_ISSUE_QUERY';
export const SET_SPENT_ON_ISSUE_QUERIES = 'SET_SPENT_ON_ISSUE_QUERIES';
export const REMOVE_SPENT_ON_ISSUE_QUERY = 'REMOVE_SPENT_ON_ISSUE_QUERY';

export const GENERATE_BUG_RATE_REPORT = 'GENERATE_BUG_RATE_REPORT';

export const SET_OVERTIME_ENTRIES = 'SET_OVERTIME_ENTRIES';
export const SET_OVERTIME_MARKED_WEEKS = 'SET_OVERTIME_MARKED_WEEKS';
export const SET_UPLOAD_PAY_STUBS_FEEDBACK = 'SET_UPLOAD_PAY_STUBS_FEEDBACK';
export const SET_UPLOAD_TIME_OFF_UPDATE_FEEDBACK = 'SET_UPLOAD_TIME_OFF_UPDATE_FEEDBACK';
export const SET_BILLABLE_HOUR_RATIOS = 'SET_BILLABLE_HOUR_RATIOS';
export const SET_BILLABLE_HOUR_RATIOS_FOR_EMPLOYEES = 'SET_BILLABLE_HOUR_RATIOS_FOR_EMPLOYEES';

export const DELETE_TEAM = 'DELETE_TEAM';
export const PUSH_TEAM = 'PUSH_TEAM';
export const PUSH_TEAM_MEMBER = 'PUSH_TEAM_MEMBER';
export const REMOVE_TEAM_MEMBER = 'REMOVE_TEAM_MEMBER';
export const SET_CREATED_TEAM_ID = 'SET_CREATED_TEAM_ID';
export const SET_TEAM_MEMBERS = 'SET_TEAM_MEMBERS';
export const SET_TEAMS = 'SET_TEAMS';

export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const SET_EMPLOYEE = 'SET_EMPLOYEE';
export const RESET_EMPLOYEE = 'RESET_EMPLOYEE';
export const SET_EXISTING_BAMBOO_EMPLOYEES = 'SET_EXISTING_BAMBOO_EMPLOYEES';
export const SET_EXISTING_HARVEST_EMPLOYEES = 'SET_EXISTING_HARVEST_EMPLOYEES';
export const SET_EXISTING_AZURE_AD_EMPLOYEES = 'SET_EXISTING_AZURE_AD_EMPLOYEES';
export const SET_EXISTING_GOOGLE_APPS_EMPLOYEES = 'SET_EXISTING_GOOGLE_APPS_EMPLOYEES';
export const SET_EXISTING_UBIK_EMPLOYEES = 'SET_EXISTING_UBIK_EMPLOYEES';
export const REMOVE_EMPLOYEE = 'REMOVE_EMPLOYEE';

export const SET_CLIENTS = 'SET_CLIENTS';
export const SET_CLIENT = 'SET_CLIENT';
export const UPDATE_CLIENT_ARCHIVE = 'UPDATE_CLIENT_ARCHIVE';

export const DELETE_DESJARDINS_PROJECT_MAPPING = 'DELETE_DESJARDINS_PROJECT_MAPPING';
export const PUSH_DESJARDINS_PROJECT_MAPPING = 'PUSH_DESJARDINS_PROJECT_MAPPING';
export const SET_DESJARDINS_PROJECT_MAPPINGS = 'SET_DESJARDINS_PROJECT_MAPPINGS';

export const SET_TEMPO_ACCOUNTS = 'SET_TEMPO_ACCOUNTS';

export const SET_ISSUE_SUGGESTIONS = 'SET_ISSUE_SUGGESTIONS';

export const PUSH_OVERTIME_REQUEST = 'PUSH_OVERTIME_REQUEST';
export const REMOVE_OVERTIME_REQUEST = 'REMOVE_OVERTIME_REQUEST';
export const SET_OVERTIME_REQUESTS = 'SET_OVERTIME_REQUESTS';

export const SET_CONTACT = 'SET_CONTACT';
export const SET_CONTACTS = 'SET_CONTACTS';
export const REMOVE_CONTACT = 'REMOVE_CONTACT';
