import PropTypes from 'prop-types';

const IssueWorklogs = {
  key: PropTypes.string,
  displayName: PropTypes.string,
  maintenanceTimeSpent: PropTypes.number,
  newTimeSpent: PropTypes.number,
  totalTimeSpent: PropTypes.number,
};

export default IssueWorklogs;
