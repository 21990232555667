import Loader from '@vooban/loader';
import i18n from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  createTeam,
  deleteTeam,
  getTeams,
  getEmployees,
  getClients,
  getHarvestProjects,
  getHarvestTasks,
  getJiraProjects,
  getProjectMappings,
  createProjectMapping,
  updateProjectMapping,
  createProjectPurchaseOrder,
  getProjectPurchaseOrders,
  setProjectPurchaseOrders,
  deleteProjectPurchaseOrder,
  downloadFile,
  updateContact,
  createContact,
  deleteContact,
  getContactsWithProjectId,
  getAssociatedDocuments,
  addProjectMappingDocument,
  deleteProjectMappingDocument,
  getTempoAccounts,
} from '../../actions';
import {
  getHarvestProjectsSelector,
  getJiraProjectsSelector,
  getClientsSelector,
  getProjectMappingsSelector,
  getEmployeesSelector,
  getHarvestTasksSelector,
  getContactsSelector,
  getTempoAccountsSelector,
  getTeamsSelector,
} from '../../selectors/selectors';
import {
  HarvestProject,
  JiraProject,
  ProjectMapping,
  Employee,
  ProjectPurchaseOrder,
  Team,
  TempoAccount,
  Client,
} from '../../types';
import { isFetchingEntities } from '../../helpers';
import ProjectDetails from '../project/ProjectDetails';
import ProjectPurchaseOrders from '../project/ProjectPurchaseOrders';
import HarvestTask from '../../types/HarvestTask';
import ProjectDocuments from '../project/ProjectDocuments';
import TeamList from '../team/TeamList';
import Contact from '../../types/Contact';
import Tabs from '../common/Tabs';
import ContactList from '../contact/ContactList';

class ProjectDetailsPage extends React.PureComponent {
  static propTypes = {
    employees: PropTypes.arrayOf(PropTypes.exact(Employee)),
    getEmployees: PropTypes.func.isRequired,
    getProjectMappings: PropTypes.func.isRequired,
    getHarvestProjects: PropTypes.func.isRequired,
    getJiraProjects: PropTypes.func.isRequired,
    getClients: PropTypes.func.isRequired,
    clients: PropTypes.arrayOf(PropTypes.exact(Client)),
    harvestProjects: PropTypes.arrayOf(PropTypes.exact(HarvestProject)),
    harvestTasks: PropTypes.arrayOf(PropTypes.exact(HarvestTask)),
    getHarvestTasks: PropTypes.func.isRequired,
    jiraProjects: PropTypes.arrayOf(PropTypes.exact(JiraProject)),
    isFetching: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    projectMapping: PropTypes.exact(ProjectMapping),
    projectMappings: PropTypes.arrayOf(PropTypes.exact(ProjectMapping)),
    purchaseOrders: PropTypes.arrayOf(PropTypes.exact(ProjectPurchaseOrder)),
    updateProjectMapping: PropTypes.func.isRequired,
    createProjectMapping: PropTypes.func.isRequired,
    createProjectPurchaseOrder: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    getProjectPurchaseOrders: PropTypes.func.isRequired,
    setProjectPurchaseOrders: PropTypes.func.isRequired,
    deleteProjectPurchaseOrder: PropTypes.func.isRequired,
    updateContact: PropTypes.func.isRequired,
    createContact: PropTypes.func.isRequired,
    deleteContact: PropTypes.func.isRequired,
    createTeam: PropTypes.func.isRequired,
    getContactsWithProjectId: PropTypes.func.isRequired,
    deleteTeam: PropTypes.func.isRequired,
    getTeams: PropTypes.func.isRequired,
    contacts: PropTypes.arrayOf(PropTypes.shape(Contact)).isRequired,
    documents: PropTypes.arrayOf(PropTypes.shape()),
    getAssociatedDocuments: PropTypes.func.isRequired,
    addProjectMappingDocument: PropTypes.func.isRequired,
    deleteProjectMappingDocument: PropTypes.func.isRequired,
    teams: PropTypes.arrayOf(PropTypes.exact(Team)),
    tempoAccounts: PropTypes.arrayOf(PropTypes.shape(TempoAccount)),
    getTempoAccounts: PropTypes.func.isRequired,
  };

  static defaultProps = {
    employees: [],
    clients: [],
    teams: [],
    harvestProjects: [],
    harvestTasks: [],
    jiraProjects: [],
    projectMapping: { harvestIds: [], jiraProjects: [], isBillable: false },
    projectMappings: [],
    purchaseOrders: [],
    documents: [],
    tempoAccounts: [],
  };

  componentDidMount() {
    this.props.getEmployees();
    this.props.getClients();
    this.props.getTeams();
    this.props.getHarvestProjects();
    this.props.getJiraProjects();
    this.props.getProjectMappings();
    this.props.getHarvestTasks();
    this.props.getTempoAccounts();

    if (this.props.projectMapping.id) {
      this.props.getProjectPurchaseOrders(this.props.projectMapping.id);
      this.props.getAssociatedDocuments(this.props.projectMapping.id);
      this.props.getContactsWithProjectId(this.props.projectMapping.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.projectMapping.id && !prevProps.projectMapping.id) {
      this.props.getProjectPurchaseOrders(this.props.projectMapping.id);
      this.props.getAssociatedDocuments(this.props.projectMapping.id);
      this.props.getContactsWithProjectId(this.props.projectMapping.id);
    }

    if (!this.props.isUpdating && prevProps.isUpdating) {
      this.props.getTempoAccounts();
    }
  }

  componentWillUnmount() {
    this.props.setProjectPurchaseOrders([]);
  }

  generateTabs = () => {
    if (this.props.projectMapping.id)
      return [
        i18n.t('project.general'),
        i18n.t('project.contact'),
        i18n.t('title.purchaseOrders'),
        i18n.t('Documents'),
        i18n.t('title.teams'),
      ];

    return [i18n.t('project.general')];
  };

  getAssociatedJiraProjectIds = () => {
    let associatedJiraProjectIds = [];

    this.props.projectMappings.forEach(pm => {
      associatedJiraProjectIds = [...associatedJiraProjectIds, ...pm.jiraProjects.map(jp => jp.id)];
    });

    return associatedJiraProjectIds;
  };

  getAssociatedTeams = () => this.props.teams.filter(x => x.projectMappingId === this.props.projectMapping.id);

  handleSaveButtonClick = projectMapping =>
    this.props.projectMapping.id
      ? this.props.updateProjectMapping(projectMapping)
      : this.props.createProjectMapping(projectMapping);

  handleAddPurchaseOrder = purchaseOrder => {
    this.props.createProjectPurchaseOrder(this.props.projectMapping.id, purchaseOrder);
  };

  handleDeletePurchaseOrder = id => this.props.deleteProjectPurchaseOrder(this.props.projectMapping.id, id);

  handleDownloadDocument = documentId => this.props.downloadFile(documentId);

  handleContactSave = values => this.props.createContact({ ...values, projectId: this.props.projectMapping.id });

  handleContactDelete = contact => this.props.deleteContact(contact);

  handleContactUpdate = contact => this.props.updateContact(contact);

  handleAddDocument = document => {
    this.props.addProjectMappingDocument(this.props.projectMapping.id, document);
  };

  handleDeleteDocument = documentId => {
    this.props.deleteProjectMappingDocument(documentId);
  };

  render() {
    if (this.props.isFetching) return <Loader />;
    const tabs = this.generateTabs();

    return (
      <>
        <h1 className="view__title">{i18n.t('title.projectDetail')}</h1>
        <div className="grid">
          <section className="grid__item -span-10">
            <Tabs tabs={tabs} className="tabs__form" tabClassName="-form" keepActiveTab>
              <ProjectDetails
                employees={this.props.employees}
                clients={this.props.clients}
                harvestTasks={this.props.harvestTasks}
                projectMapping={this.props.projectMapping}
                associatedJiraProjectIds={this.getAssociatedJiraProjectIds()}
                jiraProjects={this.props.jiraProjects}
                harvestProjects={this.props.harvestProjects}
                tempoAccounts={this.props.tempoAccounts}
                onSave={this.handleSaveButtonClick}
              />
              <ContactList
                noTop
                contacts={this.props.contacts}
                onDelete={this.handleContactDelete}
                onSave={this.handleContactSave}
                onUpdate={this.handleContactUpdate}
              />
              <ProjectPurchaseOrders
                purchaseOrders={this.props.purchaseOrders}
                onAdd={this.handleAddPurchaseOrder}
                onDelete={this.handleDeletePurchaseOrder}
                onFileOpen={this.handleDownloadDocument}
              />
              <ProjectDocuments
                documents={this.props.documents}
                onAddDocument={this.handleAddDocument}
                onDeleteDocument={this.handleDeleteDocument}
                onDownloadDocument={this.handleDownloadDocument}
              />
              <TeamList
                employees={this.props.employees}
                projectMapping={this.props.projectMapping}
                teams={this.getAssociatedTeams()}
                onCreateTeamClick={this.props.createTeam}
                onDeleteTeamClick={this.props.deleteTeam}
              />
            </Tabs>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { projectId } = state.app.routerParams;

  const projectMapping = state.project.projectMappingsById[projectId];

  return {
    isFetching: isFetchingEntities(state, [
      'jiraProjects',
      'harvestProjects',
      'projectMappings',
      'projectPurchaseOrders',
      'projectMappingDocuments',
      'contactsWithProjectId',
      'updateProjectMapping',
    ]),
    isUpdating: isFetchingEntities(state, ['updateProjectMapping']),
    harvestProjects: getHarvestProjectsSelector(state),
    harvestTasks: getHarvestTasksSelector(state),
    jiraProjects: getJiraProjectsSelector(state),
    projectMapping,
    employees: getEmployeesSelector(state),
    clients: getClientsSelector(state),
    projectMappings: getProjectMappingsSelector(state),
    teams: getTeamsSelector(state),
    tempoAccounts: getTempoAccountsSelector(state),
    purchaseOrders: Object.values(state.project.purchaseOrdersById),
    contacts: getContactsSelector(state),
    documents: Object.values(state.project.documentsById),
  };
};
export default connect(
  mapStateToProps,
  {
    createTeam,
    deleteTeam,
    getTeams,
    getEmployees,
    getClients,
    getHarvestProjects,
    getHarvestTasks,
    getJiraProjects,
    getProjectMappings,
    createProjectMapping,
    updateProjectMapping,
    createProjectPurchaseOrder,
    downloadFile,
    getProjectPurchaseOrders,
    setProjectPurchaseOrders,
    deleteProjectPurchaseOrder,
    createContact,
    updateContact,
    deleteContact,
    getContactsWithProjectId,
    getAssociatedDocuments,
    addProjectMappingDocument,
    deleteProjectMappingDocument,
    getTempoAccounts,
  }
)(ProjectDetailsPage);
