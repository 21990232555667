import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { OvertimeRequest, OvertimeRequestStatus } from '../../types';
import Can from '../ability/Can';
import commonActions from '../../helpers/authorization/abilityActions';
import sectionsAndPages from '../../helpers/sectionsAndPages';

class OvertimeRequestListRow extends PureComponent {
  static propTypes = {
    onApprove: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    projectName: PropTypes.string.isRequired,
    applicantName: PropTypes.string.isRequired,
    approverName: PropTypes.string.isRequired,
    overtimeRequest: PropTypes.exact(OvertimeRequest).isRequired,
  };

  handleDeclineClick = () => this.props.onDecline(this.props.overtimeRequest);

  handleApproveClick = () => this.props.onApprove(this.props.overtimeRequest);

  onDeleteClick = e => {
    e.stopPropagation();
    this.props.onDelete(this.props.overtimeRequest);
  };

  render() {
    const isRequestApproved = this.props.overtimeRequest.status === OvertimeRequestStatus.approved;
    const isRequestDeclined = this.props.overtimeRequest.status === OvertimeRequestStatus.declined;
    return (
      <div className="grid__row">
        <div className="grid__item -span-2">{this.props.projectName}</div>
        <div className="grid__item -span-2">{this.props.applicantName}</div>
        <div className="grid__item -span-2">{this.props.approverName}</div>
        <div className="grid__item -span-2">{this.props.overtimeRequest.reason}</div>
        <div className="grid__item -span-1">{moment(this.props.overtimeRequest.startDate).format('YYYY-MM-DD')}</div>
        <div className="grid__item -span-1">{moment(this.props.overtimeRequest.endDate).format('YYYY-MM-DD')}</div>
        <div className="grid__item -span-1">{this.props.overtimeRequest.status}</div>
        <div className="grid__item -span-1">
          <Can I={commonActions.approve} a={sectionsAndPages.overtimeRequestList}>
            <button
              type="button"
              className={isRequestApproved ? 'button__icon -approved' : 'button__icon -disabled'}
              onClick={this.handleApproveClick}
              disabled={isRequestApproved}>
              <FontAwesomeIcon icon="check-circle" />
            </button>
          </Can>
          <Can I={commonActions.decline} a={sectionsAndPages.overtimeRequestList}>
            <button
              type="button"
              className={isRequestDeclined ? 'button__icon -declined' : 'button__icon -disabled'}
              onClick={this.handleDeclineClick}
              disabled={isRequestDeclined}>
              <FontAwesomeIcon icon="times-circle" />
            </button>
          </Can>
          <button type="button" className="button__icon" onClick={this.onDeleteClick}>
            <FontAwesomeIcon icon="trash-alt" />
          </button>
        </div>
      </div>
    );
  }
}

export default OvertimeRequestListRow;
