import overtime from '../components/accounting/overtime/__i18n__/fr';
import accounting from '../components/accounting/__i18n__/fr';
import project from '../components/project/__i18n__/fr';
import timeComparison from '../components/reporting/timeComparison/__i18n__/fr';
import timeReporting from '../components/reporting/timeReporting/__i18n__/fr';
import timesheet from '../components/reporting/timesheet/__i18n__/fr';
import team from '../components/team/__i18n__/fr';
import employee from '../components/employee/__i18n__/fr';
import bugRateReport from '../components/bugRateReport/__i18n__/fr';
import overtimeRequest from '../components/overtimeRequest/__i18n__/fr';
import billableHourRatios from '../components/billableHourRatios/__i18n__/fr';
import client from '../components/client/__i18n__/fr';
import contact from '../components/contact/__i18n__/fr';

export default {
  accounting,
  billableHourRatios,
  bugRateReport,
  button: {
    add: 'Ajouter',
    cancel: 'Annuler',
    clear: 'Nettoyer',
    collapse: 'Effondrer',
    create: 'Créer',
    detailsHide: 'Cacher les détails',
    detailsShow: 'Voir les détails',
    download: 'Télécharger',
    export: 'Exporter',
    extend: 'Étendre',
    generate: 'Générer',
    no: 'Non',
    remove: 'Retirer',
    retrieve: 'Récupérer',
    save: 'Sauvegarder',
    submit: 'Soumettre',
    update: 'Mettre à jour',
    yes: 'Oui',
  },
  client,
  contact,
  costTracking: {
    emptyDates: 'Laisser les dates vides pour un rapport général.',
  },
  employee,
  file: {
    choose: 'Sélectionner un fichier',
    noChosen: 'Aucun fichier choisi',
  },
  menuSections: {
    accounting: 'Comptabilité',
    operations: 'Opérations',
    reporting: 'Temps',
    settings: 'Réglages',
    logout: 'Fermer la session',
    overtimeRequests: 'Demandes de temps supplémentaire',
  },
  message: {
    created: '{{resource}} créé(e) avec succès',
    generated: 'Généré',
    global: 'Une erreur est survenue, veuillez réessayer!',
    hello: 'Bonjour',
    maxUploadLimit: 'La taille maximale pour le téléversement de fichiers est de {{maximum}} Mo.',
    treated: 'La requête de mise à jour des banques de temps a été traitée avec succès.',
    emailSent: 'Courriel envoyé avec succès',
    emailSent_plural: 'Courriels envoyés avec succès',
    updated: '{{resource}} mis à jour avec succès',
    changesSaved: 'Changements sauvegardés',
    nothingFound: 'Aucun résultat',
    searchEmployees: 'Commencez à écrire pour rechercher',
    projectMappingCreated: 'Le project a été créé avec succès',
    projectMappingUpdated: 'Le projet a été mis à jour avec succès',
    contactCreated: 'Le contact a été créé avec succès',
    contactUpdated: 'Le contact a été mis à jour avec succès',
    projectPurchaseOrderCreated: 'Les bons de commandes de projet ont été créé avec succès',
    projectPurchaseOrderDeleted: 'Les bons de commandes de projet ont été supprimé avec succès',
    projectMappingDocumentAdded: 'Le document a été ajouté au projet avec succès',
    teamCreatedSuccessfully: 'Équipe créée avec succès',
    teamDeletedSuccessfully: 'Équipe supprimée avec succès',
    teamUpdatedSuccessfully: 'Équipe modifiée avec succès',
  },
  overtime,
  overtimeRequest,
  project,
  reportPage: {
    report: 'Rapport',
  },
  resource: {
    client: 'Client',
    document: 'document',
    employee: 'Employé(e)',
    defaultHourlyRates: 'Taux horaire par défaut',
    costRates: 'Taux coutants',
  },
  search: 'Recherche',
  team,
  timeComparison,
  timeReporting,
  timesheet,
  title: {
    appTitle: 'Application Vooban',
    batchUpdates: 'Mises à jour par lots',
    billableHourRatios: 'Ratios des heures facturables',
    bugRateReport: 'Rapport du ratio de bug',
    clients: 'Clients',
    contacts: 'Contacts',
    desjardinsProjectAssociations: 'Projets Employeur D',
    home: 'Accueil',
    generate: 'Générer',
    newEmployee: 'Nouvel employé',
    overtimeReport: 'Temps supplémentaire',
    payStubs: 'Téléverser les relevés',
    payFileConversion: 'Traiter les soldes',
    payFileGeneration: 'Création du fichier de paye',
    projectDetail: 'Projet',
    projects: 'Projets',
    purchaseOrders: 'Bon de commandes',
    costTracking: 'Cost Tracking',
    team: 'Équipe',
    teamMembers: "Membres de l'équipe",
    teams: 'Équipes',
    timeComparisons: 'Comparaison Harvest/Jira',
    timeReporting: 'Temps par issue',
    timesheets: 'Soumission & Approbation',
    employees: 'Employés',
    overtimeRequest: 'Demande de temps supplémentaire',
    overtimeRequests: 'Demandes de temps supplémentaire',
    documents: 'Documents',
  },
  upload: 'Téléverser',
  version: 'Version : {{version}}',
  validation: {
    bothDatesRequired: 'Les deux dates sont requises',
    password: 'Le mot de passe doit contenir au moins une minuscule, une majuscule et un chiffre.',
    passwordMinimum: 'Minimum 8 caractères',
    passwordMaximum: 'Maximum 16 caractères',
    phoneNumber: 'Le numéro de téléphone est invalide. Le format doit être 10 chiffres',
    required: 'Requis',
    invalidZipCode: 'Code postal invalide',
    dateMustBeGreater: 'La date doit être supérieure',
    dateMustBeLesser: 'La date doit être inférieure',
    maxMustBeGreaterThanMin: 'La valeur maximale doit être plus grande que la valeur minimale',
    invalidEmail: 'Le format du courriel est invalide',
  },
};
