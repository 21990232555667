import axios from 'axios';
import { pushModalMessageError } from '.';
import { startFetching, stopFetching, setRouterState, pushToastMessage } from './appActions';
import {
  DELETE_TEAM,
  PUSH_TEAM,
  PUSH_TEAM_MEMBER,
  REMOVE_TEAM_MEMBER,
  SET_CREATED_TEAM_ID,
  SET_TEAMS,
  SET_TEAM_MEMBERS,
} from './types';
import sectionsAndPages from '../helpers/sectionsAndPages';

export const getTeams = () => async dispatch => {
  dispatch(startFetching('teams'));
  try {
    const response = await axios.get('/teams');
    dispatch({ type: SET_TEAMS, payload: response.data });
    dispatch(stopFetching('teams'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
    dispatch(stopFetching('teams'));
  }
};

export const getTeam = id => async dispatch => {
  dispatch(startFetching('team'));
  try {
    const response = await axios.get(`/teams/${id}`);
    dispatch({ type: PUSH_TEAM, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('team'));
  }
};

export const createTeam = team => async dispatch => {
  dispatch(startFetching('team'));
  try {
    const response = await axios.post(`/teams`, team);
    const createdTeam = { ...team, id: response.data.id, tempoId: response.data.tempoId };
    dispatch({ type: PUSH_TEAM, payload: createdTeam });
    dispatch({ type: SET_CREATED_TEAM_ID, payload: response.data.id });
    dispatch(setRouterState(sectionsAndPages.teamDetails, { teamId: response.data.id }));
    dispatch(pushToastMessage('teamCreatedSuccessfully'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('team'));
  }
};

export const deleteTeam = (team, projectMapping) => async dispatch => {
  dispatch(startFetching('team'));
  try {
    await axios.delete(`/teams/${team.id}`, { data: { projectHarvestIds: projectMapping.harvestIds } });
    dispatch({ type: DELETE_TEAM, payload: team.id });
    dispatch(setRouterState(sectionsAndPages.projectDetails, { projectId: team.projectMappingId }));
    dispatch(pushToastMessage('teamDeletedSuccessfully'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('team'));
  }
};

export const updateTeam = team => async dispatch => {
  dispatch(startFetching('team'));
  try {
    const response = await axios.put(`/teams/${team.id}`, team);
    dispatch({ type: PUSH_TEAM, payload: response.data });
    dispatch(setRouterState(sectionsAndPages.projectDetails, { projectId: team.projectMappingId }));
    dispatch(pushToastMessage('teamUpdatedSuccessfully'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('team'));
  }
};

export const getTeamMembers = id => async dispatch => {
  dispatch(startFetching('teamMembers'));
  try {
    const response = await axios.get(`/teams/${id}/team-members`);
    dispatch({ type: SET_TEAM_MEMBERS, payload: response.data });
    dispatch(stopFetching('teamMembers'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
    dispatch(stopFetching('teamMembers'));
  }
};

export const addTeamMember = (team, teamMember) => async dispatch => {
  try {
    await axios.post(`/teams/${team.id}/team-members`, { team, teamMember });
    await axios.post(`/teams/user-assignments`, {
      projectId: team.projectMappingId,
      employeeHarvestId: teamMember.harvestId,
    });
    dispatch({ type: PUSH_TEAM_MEMBER, payload: teamMember });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  }
};

export const removeTeamMember = (team, teamMember, projectMapping) => async dispatch => {
  try {
    await axios.put('/teams/user-assignments/archive', {
      employeeHarvestId: teamMember.harvestId,
      projectHarvestIds: projectMapping.harvestIds,
    });
    await axios.delete(`/teams/${team.id}/team-members/${teamMember.id}`);
    dispatch({ type: REMOVE_TEAM_MEMBER, payload: teamMember.id });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  }
};

export const returnToProject = projectId => dispatch => {
  dispatch(setRouterState(sectionsAndPages.projectDetails, { projectId }));
};

export const setCreatedTeamId = payload => ({ type: SET_CREATED_TEAM_ID, payload });
