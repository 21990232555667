import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import i18n from 'i18next';
import _orderBy from 'lodash.orderby';
import { Form, Formik, Field } from 'formik';
import { FormikSelect } from '@vooban/formik-inputs';
import { validate, required } from '../../../helpers/formHelpers';

import { SpentOnIssueQuery } from '../../../types';

class CustomQueryForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onDeleteSpentOnIssueQuery: PropTypes.func.isRequired,
    spentOnIssueQueries: PropTypes.arrayOf(PropTypes.exact(SpentOnIssueQuery)).isRequired,
  };

  handleSubmit = values => {
    const spentOnIssueQuery = this.props.spentOnIssueQueries.find(query => query.id === values.spentOnIssueQuery);
    const keys = spentOnIssueQuery.queryIssueKeys.map(query => query.issueKey);

    let startDate;
    let endDate;
    if (spentOnIssueQuery.startDate && spentOnIssueQuery.endDate) {
      startDate = moment(spentOnIssueQuery.startDate, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
      endDate = moment(spentOnIssueQuery.endDate, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
    }

    this.props.onSubmit({ keys, startDate, endDate });
  };

  handleDelete = (id, setFieldValue) => {
    this.props.onDeleteSpentOnIssueQuery(id);
    setFieldValue('spentOnIssueQuery', null);
  };

  render() {
    return (
      <div className="tile -no-top">
        <Formik onSubmit={this.handleSubmit} enableReinitialize>
          {({ setFieldValue, values }) => (
            <Form>
              <section className="form__section grid">
                <p className="view__subtitle">{i18n.t('timeReporting.savedQueries')}</p>
                <div className="grid__row">
                  <div className="grid__item -span-3">
                    <Field
                      component={FormikSelect}
                      name="spentOnIssueQuery"
                      label={i18n.t('overtimeRequest.project')}
                      options={_orderBy(this.props.spentOnIssueQueries, [sIQ => sIQ.name.toLowerCase()], ['ASC'])}
                      labelKey="name"
                      valueKey="id"
                      validate={validate([required])}
                    />

                    {values.spentOnIssueQuery && (
                      <>
                        <button type="submit" className="view__action button -primary">
                          {i18n.t('timeReporting.runQuery')}
                        </button>
                        <button
                          type="button"
                          className="view__action button -delete"
                          onClick={() => this.handleDelete(values.spentOnIssueQuery, setFieldValue)}>
                          {i18n.t('timeReporting.deleteQuery')}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default CustomQueryForm;
