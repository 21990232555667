import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EmployeeInfo from '../employee/EmployeeInfo';
import Employee from '../../types/Employee';
import { createEmployee, getEmployee, resetEmployee } from '../../actions/employeeActions';

class EmployeeInfoPage extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    employee: PropTypes.shape(Employee).isRequired,
    createEmployee: PropTypes.func.isRequired,
    getEmployee: PropTypes.func.isRequired,
    resetEmployee: PropTypes.func.isRequired,
  };

  static defaultProps = {
    id: '',
  };

  componentDidMount() {
    if (this.props.id) {
      this.props.getEmployee(this.props.id);
    }
  }

  componentWillUnmount() {
    this.props.resetEmployee();
  }

  handleSubmit = (values, setFormActions) => {
    this.props.createEmployee(values);
    setFormActions();
  };

  render() {
    return <EmployeeInfo id={this.props.id} employee={this.props.employee} onSubmit={this.handleSubmit} />;
  }
}

const mapStateToProps = (state, props) => ({
  id: props.transition.params().employeeId,
  employee: state.employee.employee,
});

export default connect(
  mapStateToProps,
  { createEmployee, getEmployee, resetEmployee }
)(EmployeeInfoPage);
