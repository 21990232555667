import { AbilityBuilder } from '@casl/ability';
import sectionsAndPages from '../../sectionsAndPages';
import commonActions from '../abilityActions';
import { isInRole } from '../abilityHelpers';
import userRoles from '../userRoles';

const { employeeInfo, newEmployee } = sectionsAndPages;

export default function getRules(user) {
  const { rules, can } = AbilityBuilder.extract();
  const { navigateTo, create } = commonActions;
  const { admin, projectManager } = userRoles;

  if (isInRole(user, [admin])) {
    can(navigateTo, [employeeInfo, newEmployee]);
    can(create, [employeeInfo, newEmployee]);
  }

  if (isInRole(user, [projectManager])) {
    can(navigateTo, [employeeInfo]);
    can(create, [employeeInfo]);
  }

  return rules;
}
