export default {
  alreadyPaid: 'Already paid',
  email: 'Email',
  extra: 'Extra',
  fiftyHoursRule: '50 hours per week',
  fiftyHoursRuleExplanation:
    'An employee cannot do more than 50 hours for any given week. Each hours done over this limit must be paid half time.',
  for: 'for',
  hours: 'Hours',
  lastFourWeeks: 'Last 4 weeks',
  lastFourWeeksRule: '160 hours for the last four weeks',
  lastFourWeeksRuleExplanation:
    'An employee cannot do more than 160 hours for any given block of 4 weeks. Each hour done over this limit must be paid half time. A block is considered marked if it was over 160 hours, it then becomes fixed in time. A marked week cannot be in multiple blocks.',
  noOvertime: 'This employee has not done any overtime',
  overLimit: 'Over Limit',
  overtime: 'Overtime',
  paid: 'Paid',
  total: 'Total',
  totalLastFourWeeks: 'Total last 4 weeks',
  totalOvertimeRule: 'Total of extra hours',
  totalOvertimeRuleExplanation: 'The greater total of the two rules is used for this calculation.',
  totalUnpaid: 'Total unpaid',
  totalWeekly: 'Total weekly',
  week: 'Week',
};
