export default {
  addEmployee: 'Add Employee',
  department: 'Department',
  edit: 'Edit',
  email: 'Email',
  emailAlreadyExists: 'This work email already exists. Please enter another one.',
  female: 'Female',
  firstName: 'First Name',
  gender: 'Gender',
  hireDate: 'Hire Date',
  homeEmail: 'Home Email',
  extensionRange: 'Extension Number Range',
  lastName: 'Last Name',
  id: 'ID',
  male: 'Male',
  mobilePhone: 'Mobile Phone',
  name: 'Name',
  workPhone: 'Workphone',
  extension: 'Extension',
  mobile: 'Mobile phone',
  new: 'New Employee',
  other: 'Other',
  password: 'Password',
  salary: 'Salary',
  workEmail: 'Work Email',
};
