import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Field, Formik, Form } from 'formik';
import { FormikDatePicker, FormikMultiSelect } from '@vooban/formik-inputs';
import _orderBy from 'lodash.orderby';
import { ProjectMapping } from '../../types';
import { validate, required, minDate, maxDate } from '../../helpers/formHelpers';
import { addDays } from '../../helpers/dateHelpers';

class BugRateReportForm extends PureComponent {
  static propTypes = {
    onRetrieve: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    projects: PropTypes.arrayOf(PropTypes.exact(ProjectMapping)).isRequired,
  };

  submitAction = null;

  handleSubmit = values => {
    if (this.submitAction === 'RETRIEVE') this.props.onRetrieve(values);
    else if (this.submitAction === 'EXPORT') this.props.onExport(values);

    this.submitAction = null;
  };

  handleRetrieve = () => {
    this.submitAction = 'RETRIEVE';
  };

  handleExport = () => {
    this.submitAction = 'EXPORT';
  };

  render() {
    return (
      <section className="grid__item -span-10">
        <Formik onSubmit={this.handleSubmit} initialValues={{ projectIds: [] }}>
          {({ values }) => (
            <Form>
              <div className="tile">
                <section className="form__section grid">
                  <div className="grid__row">
                    <div className="grid__item -span-3">
                      <Field
                        component={FormikDatePicker}
                        name="startDate"
                        placeholder={i18n.t('bugRateReport.startDate')}
                        validate={validate([required, maxDate(addDays(values.endDate, -1))])}
                      />
                    </div>
                    <div className="grid__item -span-3">
                      <Field
                        component={FormikDatePicker}
                        name="endDate"
                        placeholder={i18n.t('bugRateReport.endDate')}
                        validate={validate([required, minDate(addDays(values.startDate, 1))])}
                      />
                    </div>
                    <div className="grid__item -span-3">
                      <Field
                        name="projectIds"
                        component={FormikMultiSelect}
                        options={_orderBy(this.props.projects, [project => project.name], ['ASC'])}
                        labelKey="name"
                        valueKey="id"
                        placeholder={i18n.t('bugRateReport.selectProjects')}
                      />
                    </div>
                    <div className="row grid__item -span-1">
                      <button type="submit" className="view__action button -primary" onClick={this.handleRetrieve}>
                        {i18n.t('button.retrieve')}
                      </button>
                    </div>
                    <div className="row grid__item -span-1">
                      <button type="submit" className="view__action button -primary" onClick={this.handleExport}>
                        {i18n.t('button.export')}
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    );
  }
}

export default BugRateReportForm;
