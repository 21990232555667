export default {
  alreadyPaid: 'Déjà payées',
  email: 'Courriel',
  extra: 'Extra',
  fiftyHoursRule: '50 heures par semaine',
  fiftyHoursRuleExplanation:
    'Un employé ne peut pas faire plus de 50 heures pour une semaine donnée. Toutes les heures effectuées au-delà de cette limite doivent être payées à temps et demie.',
  for: 'pour',
  hours: 'Heures',
  lastFourWeeks: '4 dernières semaines',
  lastFourWeeksRule: '160 heures pour les quatre dernières semaines',
  lastFourWeeksRuleExplanation:
    "Un employé ne peut effectuer plus de 160 heures par bloc de 4 semaines. Toutes les heures effectuées au-delà de cette limite doivent être payées à mi-temps. Un bloc est considéré marqué s'il fait plus de 160 heures, il devient alors fixe dans le temps. Une semaine marquée ne peut pas être en plusieurs blocs.",
  noOvertime: "Cet employé n'a pas fait d'heures supplémentaires",
  overLimit: 'Au dessus de la limite',
  overtime: 'Heures supplémentaires',
  paid: 'Payées',
  total: 'Total',
  totalLastFourWeeks: 'Total des 4 dernières semaines',
  totalOvertimeRule: 'Total des heures extra',
  totalOvertimeRuleExplanation: 'Le plus grand total des deux règles est utilisé pour ce calcul.',
  totalUnpaid: 'Total non payé',
  totalWeekly: 'Total par semaine',
  week: 'Semaine',
};
