import PropTypes from 'prop-types';

const TempoAccount = {
  key: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  leadAccountId: PropTypes.string,
};

export default TempoAccount;
