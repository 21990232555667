import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { UISref } from '@uirouter/react';
import Client from '../../types/Client';
import sectionsAndPages from '../../helpers/sectionsAndPages';
import { Employee } from '../../types';
import ClientFormFields from './ClientFormFields';
import { formatPhoneNumber } from '../../helpers/formHelpers';

class ClientInfo extends React.PureComponent {
  static propTypes = {
    clientId: PropTypes.string.isRequired,
    client: PropTypes.shape(Client).isRequired,
    employees: PropTypes.arrayOf(PropTypes.exact(Employee)).isRequired,
    onCreate: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  get isNew() {
    return !this.props.clientId;
  }

  handleSubmit = client => {
    const validWebsiteUrl = this.convertToValidWebsiteUrl(client.website);
    const newClient = client;
    newClient.website = validWebsiteUrl;

    if (this.isNew) {
      this.props.onCreate({
        ...newClient,
        phone: formatPhoneNumber(newClient.phone),
        billingPhone: formatPhoneNumber(newClient.billingPhone),
      });
    } else {
      this.props.onSave({
        ...newClient,
        phone: formatPhoneNumber(newClient.phone),
        billingPhone: formatPhoneNumber(newClient.billingPhone),
      });
    }
  };

  convertToValidWebsiteUrl = url => {
    let [urlProtocol] = url.split(':');
    urlProtocol = urlProtocol.toUpperCase();
    const doesStartWithProtocol = urlProtocol.startsWith('HTTP');

    if (!doesStartWithProtocol) return `http://${url}`;

    return url;
  };

  render() {
    return (
      <Formik
        initialValues={this.props.client}
        enableReinitialize
        onSubmit={this.handleSubmit}
        render={formikProps => (
          <Form className="form">
            <section className="header__row">
              <div className="grid">
                <section className="grid__item -span-8">
                  <div className="grid">
                    <h1 className="view__title grid__item -span-2">
                      {i18n.t(this.isNew ? 'client.new' : 'client.edit')}
                    </h1>
                    <div className="grid__item -span-10 -align-right">
                      <UISref to={sectionsAndPages.clients}>
                        <button type="button" className="button -default">
                          {i18n.t('button.cancel')}
                        </button>
                      </UISref>
                      <button className="button -primary" type="submit" disabled={formikProps.isSubmitting}>
                        {this.isNew ? i18n.t('button.create') : i18n.t('button.save')}
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </section>
            <ClientFormFields employees={this.props.employees} client={this.props.client} {...formikProps} />
          </Form>
        )}
      />
    );
  }
}

export default ClientInfo;
