import PropTypes from 'prop-types';

const EmployeePhoneNumber = {
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  workPhone: PropTypes.string,
  workPhoneExtension: PropTypes.number,
  mobilePhone: PropTypes.string,
};

export default EmployeePhoneNumber;
