import { AbilityBuilder } from '@casl/ability';
import sectionsAndPages from '../../sectionsAndPages';
import commonActions from '../abilityActions';
import userRoles from '../userRoles';
import { isInRole } from '../abilityHelpers';

const { clients, clientInfo, newClient } = sectionsAndPages;

export default function getRules(user) {
  const { rules, can } = AbilityBuilder.extract();
  const { navigateTo } = commonActions;
  const { projectManager, admin, accountant } = userRoles;

  if (isInRole(user, [admin, projectManager, accountant])) {
    can(navigateTo, [clients, clientInfo, newClient]);
  }

  return rules;
}
