import { Ability, AbilityBuilder } from '@casl/ability';
import accountingUserAbility from './userAbility/accountingUserAbility';
import billableHourRatiosUserAbility from './userAbility/billableHourRatiosUserAbility';
import bugRateReportUserAbility from './userAbility/bugRateReportUserAbility';
import costTrackingUserAbility from './userAbility/costTrackingUserAbility';
import operationsUserAbility from './userAbility/operationsUserAbility';
import overtimeUserAbility from './userAbility/overtimeUserAbility';
import payFileConversionUserAbility from './userAbility/payFileConversionUserAbility';
import payFileGenerationUserAbility from './userAbility/payFileGenerationUserAbility';
import payStubsUserAbility from './userAbility/payStubsUserAbility';
import projectsUserAbility from './userAbility/projectsUserAbility';
import reportingUserAbility from './userAbility/reportingUserAbility';
import settingsUserAbility from './userAbility/settingsUserAbility';
import teamsUserAbility from './userAbility/teamsUserAbility';
import timeComparisonsUserAbility from './userAbility/timeComparisonsUserAbility';
import timeReportingUserAbility from './userAbility/timeReportingUserAbility';
import timesheetsUserAbility from './userAbility/timesheetsUserAbility';
import batchUpdatesUserAbility from './userAbility/batchUpdatesUserAbility';
import employeeInfoUserAbility from './userAbility/employeeInfoUserAbility';
import employeeUserAbility from './userAbility/employeeUserAbility';
import overtimeRequestAbility from './userAbility/overtimeRequestAbility';
import clientsUserAbility from './userAbility/clientsUserAbility';

export default function defineAbilitiesFor(user) {
  if (!user) return new Ability([]);

  const { rules } = AbilityBuilder.extract();

  const allRules = rules.concat(
    accountingUserAbility(user),
    batchUpdatesUserAbility(user),
    billableHourRatiosUserAbility(user),
    bugRateReportUserAbility(user),
    clientsUserAbility(user),
    costTrackingUserAbility(user),
    operationsUserAbility(user),
    employeeInfoUserAbility(user),
    overtimeUserAbility(user),
    payFileConversionUserAbility(user),
    payFileGenerationUserAbility(user),
    payStubsUserAbility(user),
    projectsUserAbility(user),
    reportingUserAbility(user),
    settingsUserAbility(user),
    teamsUserAbility(user),
    timeComparisonsUserAbility(user),
    timeReportingUserAbility(user),
    timesheetsUserAbility(user),
    employeeUserAbility(user),
    overtimeRequestAbility(user)
  );

  return new Ability(allRules);
}
