import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from '@vooban/loader';
import ClientList from '../client/ClientList';
import Client from '../../types/Client';
import { isFetchingEntities } from '../../helpers';
import { getClients, updateClientArchive } from '../../actions';

class ClientListPage extends React.PureComponent {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    clients: PropTypes.arrayOf(PropTypes.exact(Client)),
    getClients: PropTypes.func.isRequired,
    updateClientArchive: PropTypes.func.isRequired,
  };

  static defaultProps = {
    clients: [],
  };

  componentDidMount() {
    this.props.getClients();
  }

  handleArchiveClick = selectedClient => this.props.updateClientArchive(selectedClient);

  render() {
    return (
      <>
        {this.props.isFetching && <Loader />}
        <ClientList
          clients={this.props.clients}
          onArchiveClick={(event, client) => this.handleArchiveClick(event, client)}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: isFetchingEntities(state, ['clients']),
  clients: Object.values(state.client.clientsById),
});

export default connect(
  mapStateToProps,
  { getClients, updateClientArchive }
)(ClientListPage);
