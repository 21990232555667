import _keyBy from 'lodash.keyby';
import PropTypes from 'prop-types';
import _omit from 'lodash.omit';
import {
  RESET_EMPLOYEE,
  SET_EXISTING_BAMBOO_EMPLOYEES,
  SET_EXISTING_HARVEST_EMPLOYEES,
  SET_EXISTING_AZURE_AD_EMPLOYEES,
  SET_EXISTING_GOOGLE_APPS_EMPLOYEES,
  SET_EXISTING_UBIK_EMPLOYEES,
  SET_EMPLOYEE,
  SET_EMPLOYEES,
  REMOVE_EMPLOYEE,
} from '../actions/types';
import withPropTypes from '../hoc/withPropTypes';
import Employee from '../types/Employee';
import EmployeePhoneNumbers from '../types/EmployeePhoneNumbers';

const schema = PropTypes.shape({
  employeesById: PropTypes.objectOf(PropTypes.exact(Employee)).isRequired,
  employeesPhoneNumbersByEmployeeId: PropTypes.objectOf(PropTypes.exact(EmployeePhoneNumbers)).isRequired,
  employee: PropTypes.exact(Employee),
});

const initialState = {
  employeesById: {},
  employee: {},
  employeesPhoneNumbersByEmployeeId: {},
  existingBambooEmployees: [],
  existingHarvestEmployees: [],
  existingAzureADEmployees: [],
  existingGoogleAppsEmployees: [],
  existingUbikEmployees: [],
};

const EmployeeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_EMPLOYEES:
      return { ...state, employeesById: _keyBy(payload, 'id') };
    case SET_EXISTING_BAMBOO_EMPLOYEES:
      return { ...state, existingBambooEmployees: payload };
    case SET_EXISTING_HARVEST_EMPLOYEES:
      return { ...state, existingHarvestEmployees: payload };
    case SET_EXISTING_AZURE_AD_EMPLOYEES:
      return { ...state, existingAzureADEmployees: payload };
    case SET_EXISTING_GOOGLE_APPS_EMPLOYEES:
      return { ...state, existingGoogleAppsEmployees: payload };
    case SET_EXISTING_UBIK_EMPLOYEES:
      return { ...state, existingUbikEmployees: payload };
    case SET_EMPLOYEE:
      return { ...state, employee: payload };
    case RESET_EMPLOYEE:
      return { ...state, employee: initialState.employee };
    case REMOVE_EMPLOYEE: {
      return { ...state, employeesById: _omit(state.employeesById, payload) };
    }
    default:
      return state;
  }
};

export default withPropTypes('EmployeeReducer', schema)(EmployeeReducer);
