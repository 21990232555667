export default {
  project: 'Project',
  total: 'Total',
  ratio: 'Ratio',
  spent: 'Spent',
  spentRatio: 'Spent ratio',
  internalBugs: 'Internal Bugs',
  bugs: 'Bugs',
  number: 'Nombre',
  time: 'Temps',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  selectProjects: 'Sélectionner des projets',
};
