import { Transition } from '@uirouter/react';
import Loader from '@vooban/loader';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash.isempty';
import { getSpentOnIssueQueriesSelector } from '../../selectors/selectors';
import {
  addSpentOnIssueQuery,
  getHoursForIssue,
  getIssueSuggestions,
  getSpentOnIssueQueries,
  deleteSpentOnIssueQuery,
} from '../../actions/reportingActions';
import { isFetchingEntities, setObjectInUrl } from '../../helpers';
import { IssueSuggestion, TimeSpent, SpentOnIssueQuery } from '../../types';
import TimeReporting from '../reporting/timeReporting/TimeReporting';

class TimeReportingPage extends React.PureComponent {
  static propTypes = {
    addSpentOnIssueQuery: PropTypes.func.isRequired,
    deleteSpentOnIssueQuery: PropTypes.func.isRequired,
    getHoursForIssue: PropTypes.func.isRequired,
    getSpentOnIssueQueries: PropTypes.func.isRequired,
    getIssueSuggestions: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    timeSpentHistory: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.exact(TimeSpent))).isRequired,
    issueSuggestions: PropTypes.arrayOf(PropTypes.exact(IssueSuggestion)).isRequired,
    email: PropTypes.string,
    spentOnIssueQueries: PropTypes.arrayOf(PropTypes.exact(SpentOnIssueQuery)),
    transition: PropTypes.shape({ router: PropTypes.shape({ stateService: PropTypes.shape({ go: PropTypes.func }) }) }),
    routerParams: PropTypes.shape({
      keys: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  };

  static defaultProps = {
    email: '',
    transition: Transition,
    routerParams: {},
    spentOnIssueQueries: [],
  };

  constructor(props) {
    super(props);

    if (this.props.routerParams.keys) {
      const { routerParams } = this.props;
      const { keys = {} } = routerParams;
      this.timeSpentRequest = keys.constructor === Array ? routerParams : { ...routerParams, keys: [keys] };
    } else {
      this.timeSpentRequest = { keys: [] };
    }
  }

  componentDidMount() {
    if (!_isEmpty(this.timeSpentRequest.keys)) {
      this.props.getHoursForIssue(this.timeSpentRequest);
    }

    if (this.props.email) {
      this.props.getSpentOnIssueQueries(this.props.email);
    }
  }

  handleSubmit = timeSpentRequest => {
    this.timeSpentRequest = timeSpentRequest;
    setObjectInUrl(this.props.transition, timeSpentRequest);
    this.props.getHoursForIssue(timeSpentRequest);
  };

  handleGetIssueSuggestions = query => this.props.getIssueSuggestions(query);

  render() {
    return (
      <>
        {this.props.isFetching && <Loader />}
        <TimeReporting
          onAddSpentOnIssueQuery={this.props.addSpentOnIssueQuery}
          onDeleteSpentOnIssueQuery={this.props.deleteSpentOnIssueQuery}
          onSubmit={this.handleSubmit}
          timeSpentHistory={this.props.timeSpentHistory}
          isFetching={this.props.isFetching}
          onGetIssueSuggestions={this.handleGetIssueSuggestions}
          issueSuggestions={this.props.issueSuggestions}
          initialValues={this.timeSpentRequest}
          spentOnIssueQueries={this.props.spentOnIssueQueries}
          email={this.props.email}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const { timeSpentHistory, issueSuggestions } = state.reporting;

  return {
    routerParams: state.app.routerParams,
    timeSpentHistory,
    issueSuggestions,
    isFetching: isFetchingEntities(state, ['timeSpent', 'spentOnIssueQuery']),
    email: state.app.auth.user.email,
    spentOnIssueQueries: getSpentOnIssueQueriesSelector(state),
  };
};

export default connect(
  mapStateToProps,
  { addSpentOnIssueQuery, getHoursForIssue, getIssueSuggestions, getSpentOnIssueQueries, deleteSpentOnIssueQuery }
)(TimeReportingPage);
