import _keyBy from 'lodash.keyby';
import _omit from 'lodash.omit';
import PropTypes from 'prop-types';
import {
  PUSH_TEAM,
  PUSH_TEAM_MEMBER,
  DELETE_TEAM,
  REMOVE_TEAM_MEMBER,
  SET_CREATED_TEAM_ID,
  SET_TEAMS,
  SET_TEAM_MEMBERS,
} from '../actions/types';
import withPropTypes from '../hoc/withPropTypes';
import { Employee, Team } from '../types';

const schema = PropTypes.shape({
  teamMembersById: PropTypes.objectOf(PropTypes.exact(Employee)).isRequired,
  teamsById: PropTypes.objectOf(PropTypes.exact(Team)).isRequired,
  createdTeamId: PropTypes.number,
  deletedTeamSuccessful: PropTypes.bool,
});

const initialState = {
  teamMembersById: {},
  teamsById: {},
  createdTeamId: null,
  deletedTeamSuccessful: false,
};

const TeamReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_TEAM:
      return { ...state, teamsById: _omit(state.teamsById, action.payload), deletedTeamSuccessful: true };
    case SET_TEAMS:
      return { ...state, teamsById: _keyBy(action.payload, 'id') };
    case PUSH_TEAM:
      return { ...state, teamsById: { ...state.teamsById, [action.payload.id]: action.payload } };
    case SET_TEAM_MEMBERS:
      return { ...state, teamMembersById: _keyBy(action.payload, 'id') };
    case SET_CREATED_TEAM_ID:
      return { ...state, createdTeamId: action.payload };
    case PUSH_TEAM_MEMBER:
      return { ...state, teamMembersById: { ...state.teamMembersById, [action.payload.id]: action.payload } };
    case REMOVE_TEAM_MEMBER:
      return { ...state, teamMembersById: _omit(state.teamMembersById, action.payload) };
    default:
      return state;
  }
};

export default withPropTypes('TeamReducer', schema)(TeamReducer);
