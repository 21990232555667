import { InputReactSelect } from '@vooban/inputs';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import _orderBy from 'lodash.orderby';
import Employee from '../../types/Employee';

class TeamMemberAdditionModal extends React.PureComponent {
  static propTypes = {
    unassignedEmployees: PropTypes.arrayOf(PropTypes.exact(Employee)).isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onAddClick: PropTypes.func.isRequired,
  };

  state = {
    selectedEmployee: null,
  };

  resetState = () => this.setState({ selectedEmployee: null });

  arrayToOptions = array =>
    _orderBy(
      array.map(o => ({ key: o.id, label: `${o.firstName} ${o.lastName} (${o.workEmail})`, value: o })),
      'value.firstName'
    );

  handleCancelClick = () => {
    this.props.onCloseClick();
    this.resetState();
  };

  handleAddClick = () => {
    this.props.onAddClick(this.state.selectedEmployee);
    this.resetState();
  };

  handleEmployeeChange = selectedEmployee => this.setState({ selectedEmployee });

  render() {
    const employeeOptions = this.arrayToOptions(this.props.unassignedEmployees);

    return (
      <React.Fragment>
        <section className="modal__header">
          <div className="grid">
            <h1 className="view__title grid__item -span-10">{i18n.t('team.newTeamMember')}</h1>
            <button type="button" className="button grid__item -align-right" onClick={this.handleCancelClick}>
              {i18n.t('button.cancel')}
            </button>
            <button
              type="button"
              className="button -primary grid__item -align-right"
              onClick={this.handleAddClick}
              disabled={!this.state.selectedEmployee}>
              {i18n.t('button.add')}
            </button>
          </div>
        </section>

        <section className="modal__body">
          <div className="grid__row -span-12">
            <h6 className="grid__heading -span-4">{i18n.t('team.employee')}</h6>
            <div className="grid__row">
              <div className="grid__item -span-6">
                <InputReactSelect
                  isSearchable
                  options={employeeOptions}
                  onChange={this.handleEmployeeChange}
                  value={this.state.selectedEmployee}
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default TeamMemberAdditionModal;
