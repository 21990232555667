import { AbilityBuilder } from '@casl/ability';
import sectionsAndPages from '../../sectionsAndPages';
import commonActions from '../abilityActions';

const { employees } = sectionsAndPages;

export default function getRules(user) {
  const { rules, can } = AbilityBuilder.extract();
  const { navigateTo } = commonActions;

  if (user) {
    can(navigateTo, employees);
  }

  return rules;
}
