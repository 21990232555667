import axios from 'axios';
import download from 'js-file-download';
import getFileNameFromContentDisposition from '../helpers/responseHelper';
import { handleError, pushModalMessageError, startFetching, stopFetching, pushToastMessage } from './appActions';
import { statusType } from '../helpers';
import {
  SET_OVERTIME_ENTRIES,
  SET_OVERTIME_MARKED_WEEKS,
  SET_UPLOAD_PAY_STUBS_FEEDBACK,
  SET_UPLOAD_TIME_OFF_UPDATE_FEEDBACK,
  SET_DESJARDINS_PROJECT_MAPPINGS,
  PUSH_DESJARDINS_PROJECT_MAPPING,
  DELETE_DESJARDINS_PROJECT_MAPPING,
  SET_BILLABLE_HOUR_RATIOS,
  SET_BILLABLE_HOUR_RATIOS_FOR_EMPLOYEES,
} from './types';

export const uploadZipFile = zipFile => async dispatch => {
  dispatch(startFetching('uploadPayStubsFeedback'));
  try {
    const formData = new FormData();
    formData.append('file', zipFile);
    const response = await axios.post('/accounting/upload-paystubs', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    dispatch({ type: SET_UPLOAD_PAY_STUBS_FEEDBACK, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('uploadPayStubsFeedback'));
  }
};

export const convertPayFile = (year, payNumber, file, isAllIn, payEndDate) => async dispatch => {
  dispatch(startFetching('uploadTimeOffUpdateFeedback'));
  try {
    const formData = new FormData();
    formData.append('year', year);
    formData.append('payNumber', payNumber);
    formData.append('file', file);
    formData.append('payEndDate', payEndDate);
    if (isAllIn) {
      formData.append('isAllIn', isAllIn);
      const response = await axios.post('/accounting/pay-file-conversion', formData);
      dispatch({ type: SET_UPLOAD_TIME_OFF_UPDATE_FEEDBACK, payload: response.data.result });
      dispatch(pushToastMessage('treated'));
    } else {
      const response = await axios.post('/accounting/pay-file-conversion', formData, { responseType: 'arraybuffer' });
      const fileName = getFileNameFromContentDisposition(response.headers['content-disposition']);
      download(response.data, fileName, response.headers['content-type']);
    }
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('uploadTimeOffUpdateFeedback'));
  }
};

export const getOvertimeMarkedWeeks = params => async dispatch => {
  dispatch(startFetching('overtimeMarkedWeeks'));
  try {
    const response = await axios.get('/accounting/overtime-marked-weeks', { params });
    dispatch({ type: SET_OVERTIME_MARKED_WEEKS, payload: response.data });
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(stopFetching('overtimeMarkedWeeks'));
  }
};

export const getOvertimeEntries = params => async dispatch => {
  dispatch(startFetching('overtimeEntries'));
  try {
    const response = await axios.get('/accounting/overtime-entries', { params });
    dispatch({ type: SET_OVERTIME_ENTRIES, payload: response.data });
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(stopFetching('overtimeEntries'));
  }
};

export const uploadTimeOffUpdateFile = (year, payNumber, file, payEndDate) => async dispatch => {
  dispatch(startFetching('uploadTimeOffUpdateFeedback'));
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('year', year);
    formData.append('payNumber', payNumber);
    formData.append('payEndDate', payEndDate);
    const response = await axios.post('/accounting/upload-time-off', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    dispatch({ type: SET_UPLOAD_TIME_OFF_UPDATE_FEEDBACK, payload: response.data });
    dispatch(pushToastMessage('treated'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('uploadTimeOffUpdateFeedback'));
  }
};

export const generatePayFileDocument = params => async dispatch => {
  dispatch(startFetching('payFileDocument'));
  try {
    const response = await axios.get('/accounting/pay-file-generation', { params, responseType: 'arraybuffer' });
    const fileName = getFileNameFromContentDisposition(response.headers['content-disposition']);
    download(response.data, fileName, response.headers['content-type']);
    dispatch(pushToastMessage(statusType.generated, 'document'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('payFileDocument'));
  }
};

export const getDesjardinsProjectMappings = () => async dispatch => {
  dispatch(startFetching('desjardinsProjectMappings'));
  try {
    const response = await axios.get('/accounting/desjardins-project-mappings');
    dispatch({ type: SET_DESJARDINS_PROJECT_MAPPINGS, payload: response.data });
    dispatch(stopFetching('desjardinsProjectMappings'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
    dispatch(stopFetching('desjardinsProjectMappings'));
  }
};

export const saveDesjardinsProjectMapping = projectMapping => async dispatch => {
  try {
    await axios.put(`/accounting/desjardins-project-mappings`, projectMapping);
    dispatch({ type: PUSH_DESJARDINS_PROJECT_MAPPING, payload: projectMapping });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  }
};

export const deleteDesjardinsProjectMapping = havestId => async dispatch => {
  try {
    await axios.delete(`/accounting/desjardins-project-mappings/${havestId}`);
    dispatch({ type: DELETE_DESJARDINS_PROJECT_MAPPING, payload: havestId });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  }
};

export const getDepartmentBillableHourRatios = params => async dispatch => {
  dispatch(startFetching('billableHourRatiosDepartments'));
  try {
    const productionResponse = await axios.get('/billable-hour-ratios/production', { params });
    const administrationResponse = await axios.get('/billable-hour-ratios/administration', { params });
    dispatch({
      type: SET_BILLABLE_HOUR_RATIOS,
      payload: { productionRatios: productionResponse.data, administrationRatios: administrationResponse.data },
    });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('billableHourRatiosDepartments'));
  }
};

export const getAllEmployeesBillableHourRatios = params => async dispatch => {
  dispatch(startFetching('billableHourRatiosEmployees'));
  try {
    const response = await axios.get('/billable-hour-ratios/', { params });
    dispatch({
      type: SET_BILLABLE_HOUR_RATIOS_FOR_EMPLOYEES,
      payload: response.data,
    });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('billableHourRatiosEmployees'));
  }
};
