import PropTypes from 'prop-types';

const UpdateTimeOffFeedback = {
  employeeNumber: PropTypes.string,
  payFileBalance: PropTypes.number,
  bambooHrBalance: PropTypes.number,
  amount: PropTypes.number,
  timeOffType: PropTypes.string,
  isSuccess: PropTypes.bool,
};

export default UpdateTimeOffFeedback;
