import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import { UISref } from '@uirouter/react';

import { Employee, Team } from '../../types';

const TeamListRow = ({ link, onDeleteClick, team, teamLead }) => (
  <UISref to={link.to} params={link.params}>
    <article className="grid__row -linked">
      <span className="grid__item -span-6">{team.name}</span>
      <span className="grid__item -span-5">{`${teamLead.firstName} ${teamLead.lastName}`}</span>
      <span className="grid__item -span-1 -align-right">
        <button type="button" className="button__icon" onClick={onDeleteClick}>
          <FontAwesomeIcon icon="trash-alt" />
        </button>
      </span>
    </article>
  </UISref>
);

TeamListRow.propTypes = {
  link: PropTypes.exact({ to: PropTypes.string, params: PropTypes.exact({ teamId: PropTypes.number }) }).isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  team: PropTypes.exact(Team).isRequired,
  teamLead: PropTypes.exact(Employee).isRequired,
};

export default TeamListRow;
