import React from 'react';
import { createContextualCan } from '@casl/react';
import { abilitySubject } from '../../helpers';
import AbilityContext from './AbilityContext';

const ContextualCan = createContextualCan(AbilityContext.Consumer);

export default class Can extends React.PureComponent {
  static getDerivedStateFromProps(props) {
    if (!props.this) return null;
    if (!props.ofType) throw new Error('Props `ofType` is required when passing `this`');
    return { abilitySubject: abilitySubject(props.ofType, props.this) };
  }

  state = { abilitySubject: null };

  render() {
    return <ContextualCan {...this.props} this={this.state.abilitySubject} />;
  }
}

const forEachAction = actions => checkAbilityForAction => {
  if (Array.isArray(actions)) {
    return actions.some(action => checkAbilityForAction(action));
  }
  return checkAbilityForAction(actions);
};

const checkAbility = (abilities, action, that = {}, ofType, a) => {
  if (!ofType) return abilities.can(action, a || that);
  const subject = abilitySubject(ofType, that);
  return abilities.can(action, that ? subject : ofType);
};

export const CanI = (actions, { that, ofType, accordingTo, a }) =>
  forEachAction(actions)(action => checkAbility(accordingTo, action, that, ofType, a));
