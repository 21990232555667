import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Transition } from '@uirouter/react';
import { receiveError, receiveLogin, setAccessToken } from '../../actions';
import Auth from '../../helpers/authorization/auth';
import loading from './loading.svg';

class Callback extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    transition: PropTypes.shape({ router: PropTypes.shape({ stateService: PropTypes.shape({ go: PropTypes.func }) }) }),
  };

  static defaultProps = {
    transition: Transition,
  };

  componentDidMount() {
    const success = () => {
      const lastRequestedState = Auth.popLastRequestedState();
      this.props.dispatch(receiveLogin(Auth.getUser()));
      this.props.dispatch(setAccessToken(Auth.getAccessToken()));

      if (lastRequestedState) {
        this.props.transition.router.stateService.go(lastRequestedState.to.name, lastRequestedState.params);
      } else {
        this.props.transition.router.stateService.go('home');
      }
    };

    const error = errorMessage => {
      this.props.dispatch(receiveError(errorMessage));
      this.props.transition.router.stateService.go('home');
    };

    const auth = new Auth();
    auth.handleAuthentication(success, error);
  }

  render() {
    const style = {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'white',
    };

    return (
      <div style={style}>
        <img src={loading} alt="loading" />
      </div>
    );
  }
}

export default connect()(Callback);
