import { ConfirmModal } from '@vooban/modals';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { UISref } from '@uirouter/react';
import { HarvestProject, ProjectMapping, JiraProject } from '../../types';
import ProjectListRow from './ProjectListRow';
import sectionsAndPages from '../../helpers/sectionsAndPages';
import Can from '../ability/Can';
import commonActions from '../../helpers/authorization/abilityActions';

class ProjectList extends React.PureComponent {
  static propTypes = {
    harvestProjects: PropTypes.arrayOf(PropTypes.exact(HarvestProject)).isRequired,
    jiraProjects: PropTypes.arrayOf(PropTypes.exact(JiraProject)).isRequired,
    onDelete: PropTypes.func.isRequired,
    projectMappings: PropTypes.arrayOf(PropTypes.exact(ProjectMapping)).isRequired,
  };

  defaultState = {
    assignedJiraProject: null,
    selectedProjectMapping: null,
    unassociatedJiraProjects: this.unassociatedJiraProjects,
    projectMappingToDelete: {},
  };

  state = this.defaultState;

  deleteMappingModalRef = React.createRef();

  getAssociatedHarvestProjects = (projectMapping, harvestProjects) => {
    if (!projectMapping.harvestIds) return [];
    return harvestProjects.filter(hp => projectMapping.harvestIds.includes(hp.id));
  };

  handleDeleteClick = () => this.props.onDelete(this.state.projectMappingToDelete.id);

  openProjectMappingDeletionModal = (e, projectMappingToDelete) => {
    e.stopPropagation();
    this.setState({ projectMappingToDelete });
    this.deleteMappingModalRef.current.open();
  };

  render() {
    return (
      <>
        <div className="grid">
          <div className="grid__row">
            <h1 className="view__title grid__item -span-6">{i18n.t('title.projects')}</h1>
            <div className="grid__item -span-6 -align-right">
              <UISref to={sectionsAndPages.projectCreation}>
                <button type="button" className="button -primary">
                  {i18n.t('button.create')}
                </button>
              </UISref>
            </div>
          </div>

          <div className="grid__row">
            <div className="grid__item -span-12">
              <div className="tile">
                <section className="grid">
                  <h6 className="grid__heading -span-2">{i18n.t('project.name')}</h6>
                  <h6 className="grid__heading -span-2">{i18n.t('project.jiraProjects')}</h6>
                  <h6 className="grid__heading -span-2">{i18n.t('project.associatedHarvestProjects')}</h6>
                  <h6 className="grid__heading -span-1">{i18n.t('project.code')}</h6>
                  <h6 className="grid__heading -span-1">{i18n.t('project.desjardinsCode')}</h6>
                  <h6 className="grid__heading -span-1">{i18n.t('project.status')}</h6>
                  <h6 className="grid__heading -span-1">{i18n.t('project.spanMD')}</h6>
                  <Can I={commonActions.getProjectListCriticalData} a={sectionsAndPages.projects}>
                    <h6 className="grid__heading -span-1">{i18n.t('project.spanMoney')}</h6>
                  </Can>
                  <h6 className="grid__heading -span-1">{i18n.t('project.actions')}</h6>
                  {this.props.projectMappings.map(projectMapping => {
                    const handleDeleteClick = e => this.openProjectMappingDeletionModal(e, projectMapping);
                    return (
                      <ProjectListRow
                        key={projectMapping.id}
                        projectMapping={projectMapping}
                        harvestProjects={this.props.harvestProjects}
                        jiraProjects={this.props.jiraProjects}
                        onDeleteClick={handleDeleteClick}
                      />
                    );
                  })}
                </section>
              </div>
            </div>
          </div>
        </div>

        <ConfirmModal
          ref={this.deleteMappingModalRef}
          onConfirm={this.handleDeleteClick}
          title={i18n.t('project.confirmRemoveMapping', {
            projectName: this.state.projectMappingToDelete.name,
          })}
          cancelLabel={i18n.t('button.no')}
          confirmLabel={i18n.t('button.yes')}
        />
      </>
    );
  }
}

export default ProjectList;
