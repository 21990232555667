export default {
  addFile: 'Add a document',
  addPurchaseOrder: 'Add Purchase Order',
  addTask: 'Add a task',
  association: 'Association',
  associatedHarvestProjects: 'Associated Harvest Projects',
  associatedJiraProject: 'Associated Jira Project',
  billable: 'Is billable?',
  billingType: 'Billing Type',
  billingTypeFixed: 'Fixed',
  billingTypeProjectHourly: 'Project Hourly',
  billingTypeTaskHourly: 'Task Hourly',
  budget: 'Budget',
  code: 'Code',
  confirmRemoveMapping: "Do you really wish to delete the project '{{projectName}}'?",
  confirmRemovePurchaseOrder: "Do you really wish to delete the purchase order '{{purchaseOrderNumber}}'?",
  contact: 'Contact',
  contactEmail: 'Email',
  contactName: 'Name / Department',
  contactTelephone: 'Telephone',
  customer: 'Client',
  delete: 'Delete project',
  desjardinsCode: 'Desjardins code',
  description: 'Description',
  effortInDays: 'Effort in days',
  empty: 'Please click on Create to add a new project.',
  endDate: 'End date',
  file: 'File',
  fileName: 'File name',
  jiraProject: 'Jira Project',
  jiraProjects: 'Jira Projects',
  harvestProject: 'Harvest Project',
  hourlyRate: 'Hourly Rate',
  information: 'Information',
  maxBudgetDollars: 'Maximum Budget',
  minBudgetDollars: 'Minimum Budget',
  name: 'Project name',
  new: 'New Project',
  newTempoAccount: 'New Tempo Account',
  poNumber: 'Number',
  poDate: 'Date',
  poStartDate: 'Start Date',
  poEndDate: 'End Date',
  projectCode: 'Project Code',
  projectManager: 'Project Manager',
  projectStatus: 'Status',
  projectStatusDone: 'Done',
  projectStatusInProgress: 'In Progress',
  projectStatusNegotiating: 'Negotiating',
  spanMD: 'Span (Man-Days)',
  spanMoney: 'Span ($)',
  startDate: 'Start Date',
  status: 'Status',
  actions: 'Actions',
  task: 'Task',
  tempoAccount: 'Tempo Account',
  newPurchaseOrder: 'New Purchase Order',
  general: 'General',
};
