import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { InputReactSelect } from '@vooban/inputs';
import moment from 'moment';
import { getPayEndDate, getPayStartDate } from '../../helpers/dateHelpers';

const numberOfYears = 20;
const firstPayNumber = 26;

class PayPeriodAndYearSelection extends React.PureComponent {
  static propTypes = {
    formValues: PropTypes.shape({
      year: PropTypes.number.isRequired,
      payNumber: PropTypes.number.isRequired,
    }).isRequired,
    onYearChange: PropTypes.func.isRequired,
    onPayNumberChange: PropTypes.func.isRequired,
  };

  arrayToOptions = array => array.map(o => ({ key: o, label: o, value: o }));

  getYears = () => {
    const firstYear = moment().year();
    return Array.from(new Array(numberOfYears), (val, index) => firstYear - index);
  };

  getPayNumbers = () => Array.from(new Array(firstPayNumber), (val, index) => firstPayNumber - index);

  getPayNumberLabel = payNumber => {
    const endDate = getPayEndDate(this.props.formValues.year, payNumber);
    const startDate = getPayStartDate(endDate);
    return `${payNumber} (${startDate} ${i18n.t('to')} ${endDate})`;
  };

  payNumberOptions = array =>
    array.map(option => ({
      key: option,
      label: this.getPayNumberLabel(option),
      value: option,
    }));

  handleYearChange = year => this.props.onYearChange(year);

  handlePayNumberChange = payNumber => this.props.onPayNumberChange(payNumber);

  render() {
    const yearOptions = this.arrayToOptions(this.getYears());
    const payNumberOptions = this.payNumberOptions(this.getPayNumbers());
    return (
      <>
        <InputReactSelect
          isSearchable
          label={i18n.t('accounting.year')}
          options={yearOptions}
          onChange={this.handleYearChange}
          value={this.props.formValues.year}
          className="grid__item -span-2"
        />
        <InputReactSelect
          isSearchable
          label={i18n.t('accounting.payNumber')}
          options={payNumberOptions}
          onChange={this.handlePayNumberChange}
          value={this.props.formValues.payNumber}
          className="grid__item -span-4"
        />
      </>
    );
  }
}

export default PayPeriodAndYearSelection;
