import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { OvertimeEntry } from '../../../types';

const workHoursInAWeek = 35;
const hoursWarningThreshold = 10;
const hoursDangerThreshold = 20;
const workHoursInFourWeeks = 4 * workHoursInAWeek;

class LastFourWeeksOvertime extends React.PureComponent {
  static propTypes = {
    overtimeEntry: PropTypes.exact(OvertimeEntry),
  };

  static defaultProps = {
    overtimeEntry: null,
  };

  getHoursColor = (hours, warningThreshold = 0, dangerThreshold = 0) => {
    if (hours > dangerThreshold) return 'red';
    if (hours > warningThreshold) return 'orange';
    return 'black';
  };

  render() {
    const { fourWeeksHours, fourWeeksHoursOverLimit, fourWeeksExtra } = this.props.overtimeEntry;

    return (
      <div className="grid__item -span-10">
        <div className="tile">
          <section className="grid">
            <div className="grid__item -span-12">
              {/* FIXME: Create new css classes instead of overriding this one */}
              <section className="menu__content" style={{ borderTop: '0rem' }}>
                <h4 className="menu__title" style={{ margin: '0rem' }}>
                  {i18n.t('overtime.lastFourWeeksRule')}
                </h4>
                <span>{i18n.t('overtime.lastFourWeeksRuleExplanation')}</span>
              </section>
            </div>

            <h6 className="grid__heading -span-2">{}</h6>
            <h6 className="grid__heading -span-2 -align-center">{i18n.t('overtime.hours')}</h6>
            <h6 className="grid__heading -span-2 -align-center">{i18n.t('overtime.overtime')}</h6>
            <h6 className="grid__heading -span-2 -align-center">{i18n.t('overtime.overLimit')}</h6>
            <h6 className="grid__heading -span-2 -align-center">{i18n.t('overtime.extra')}</h6>
            <h6 className="grid__heading -span-2">{}</h6>

            <article className="grid__row">
              <span className="grid__item -span-2 -align-right">{i18n.t('overtime.lastFourWeeks')}</span>
              <span
                className="grid__item -span-2 -align-center"
                style={{ color: this.getHoursColor(fourWeeksHours, hoursWarningThreshold, hoursDangerThreshold) }}>
                {(workHoursInFourWeeks + fourWeeksHours).toFixed(2)}
              </span>
              <span
                className="grid__item -span-2 -align-center"
                style={{ color: this.getHoursColor(fourWeeksHours, hoursWarningThreshold, hoursDangerThreshold) }}>
                {fourWeeksHours.toFixed(2)}
              </span>
              <span className="grid__item -span-2 -align-center" style={{ color: this.getHoursColor(fourWeeksHours) }}>
                {fourWeeksHoursOverLimit.toFixed(2)}
              </span>
              <span className="grid__item -span-2 -align-center" style={{ color: this.getHoursColor(fourWeeksHours) }}>
                {fourWeeksExtra.toFixed(2)}
              </span>
              <span className="grid__item -span-2" />
            </article>
          </section>
        </div>
      </div>
    );
  }
}

export default LastFourWeeksOvertime;
