import PropTypes from 'prop-types';
import _keyBy from 'lodash.keyby';
import _omit from 'lodash.omit';
import { REMOVE_CONTACT, SET_CONTACT, SET_CONTACTS } from '../actions/types';
import withPropTypes from '../hoc/withPropTypes';
import { Contact } from '../types';

const schema = PropTypes.shape({
  contactsById: PropTypes.objectOf(PropTypes.shape(Contact)).isRequired,
  contactsByProjectId: PropTypes.objectOf(PropTypes.shape(Contact)).isRequired,
  hasUpdateSucceeded: PropTypes.number.isRequired,
});

const initialState = {
  hasUpdateSucceeded: 0,
  contactsById: {},
  contactsByProjectId: {},
};

const ContactReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REMOVE_CONTACT:
      return { ...state, contactsById: _omit(state.contactsById, payload) };
    case SET_CONTACT:
      return { ...state, contactsById: { ...state.contactsById, [payload.id]: payload } };
    case SET_CONTACTS:
      return { ...state, contactsById: _keyBy(payload, 'id') };
    default:
      return state;
  }
};

export default withPropTypes('ContactReducer', schema)(ContactReducer);
