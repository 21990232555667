export default async function(uri) {
  // eslint-disable-next-line no-undef
  const req = new XMLHttpRequest();
  req.addEventListener('load', () => {
    if (req.status < 200 || req.status >= 400) throw new Error(req.statusText);

    const container = document.createElement('div');
    container.innerHTML = req.response;
    document.body.insertBefore(container.firstChild.cloneNode(true), document.body.firstChild);
  });

  req.open('GET', uri, true);
  req.send();
}
