import PropTypes from 'prop-types';

const TimeComparison = {
  email: PropTypes.string,
  harvestHours: PropTypes.number,
  jiraHours: PropTypes.number,
  name: PropTypes.string,
  projectName: PropTypes.string,
};

export default TimeComparison;
