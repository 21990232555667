import { AbilityBuilder } from '@casl/ability';
import commonActions from '../abilityActions';
import { isInRole } from '../abilityHelpers';
import userRoles from '../userRoles';
import sectionsAndPages from '../../sectionsAndPages';

const { timesheets } = sectionsAndPages;

export default function getRules(user) {
  const { rules, can } = AbilityBuilder.extract();
  const { navigateTo, getList } = commonActions;
  const { projectManager, teamLead, admin, accountant } = userRoles;

  if (isInRole(user, [accountant, projectManager, teamLead, admin])) {
    can(navigateTo, timesheets);
    can(getList, timesheets);
  }

  return rules;
}
