import { AbilityBuilder } from '@casl/ability';
import commonActions from '../abilityActions';
import { isInRole } from '../abilityHelpers';
import userRoles from '../userRoles';
import sectionsAndPages from '../../sectionsAndPages';

const { projects, projectCreation, projectDetails } = sectionsAndPages;

export default function getRules(user) {
  const { rules, can } = AbilityBuilder.extract();
  const { navigateTo, create, getPurchaseOrderCriticalData, getProjectListCriticalData } = commonActions;
  const { projectManager, admin, accountant } = userRoles;

  if (isInRole(user, [projectManager, admin])) {
    can(navigateTo, [projects, projectCreation, projectDetails]);
    can(create, [projects, projectCreation, projectDetails]);
    can(getPurchaseOrderCriticalData, [projects, projectCreation, projectDetails]);
  }

  if (isInRole(user, [projectManager, admin, accountant])) {
    can(getProjectListCriticalData, projectDetails);
  }

  return rules;
}
