import _keyBy from 'lodash.keyby';
import _orderBy from 'lodash.orderby';
import PropTypes from 'prop-types';
import {
  SET_OVERTIME_ENTRIES,
  SET_OVERTIME_MARKED_WEEKS,
  SET_UPLOAD_PAY_STUBS_FEEDBACK,
  SET_UPLOAD_TIME_OFF_UPDATE_FEEDBACK,
  SET_BILLABLE_HOUR_RATIOS,
  SET_BILLABLE_HOUR_RATIOS_FOR_EMPLOYEES,
} from '../actions/types';
import withPropTypes from '../hoc/withPropTypes';
import {
  OvertimeEntry,
  OvertimeMarkedWeek,
  UploadFeedback,
  UpdateTimeOffFeedback,
  BillableDepartmentHourRatios,
  BillableHourRatiosEmployee,
} from '../types';

const schema = PropTypes.shape({
  billableProductionDepartmentHourRatios: PropTypes.exact(BillableDepartmentHourRatios),
  billableAdministrationDepartmentHourRatios: PropTypes.exact(BillableDepartmentHourRatios),
  billableHourRatiosByEmployees: PropTypes.objectOf(PropTypes.exact(BillableHourRatiosEmployee)),
  overtimeEntriesByEmail: PropTypes.objectOf(PropTypes.exact(OvertimeEntry)).isRequired,
  overtimeMarkedWeeksByIds: PropTypes.objectOf(PropTypes.shape(OvertimeMarkedWeek)).isRequired,
  uploadPayStubsFeedback: PropTypes.arrayOf(PropTypes.exact(UploadFeedback)),
  uploadTimeOffUpdateFeedback: PropTypes.arrayOf(PropTypes.exact(UpdateTimeOffFeedback)),
});

const initialState = {
  projectMappingsByHarvestId: {},
  overtimeEntriesByEmail: {},
  overtimeMarkedWeeksByIds: {},
  uploadPayStubsFeedback: [],
  uploadTimeOffUpdateFeedback: [],
  billableProductionDepartmentHourRatios: {},
  billableAdministrationDepartmentHourRatios: {},
  billableHourRatiosByEmployees: {},
};

const AccountingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_BILLABLE_HOUR_RATIOS: {
      return {
        ...state,
        billableProductionDepartmentHourRatios: payload.productionRatios,
        billableAdministrationDepartmentHourRatios: payload.administrationRatios,
      };
    }
    case SET_BILLABLE_HOUR_RATIOS_FOR_EMPLOYEES:
      return { ...state, billableHourRatiosByEmployees: _keyBy(payload, 'id') };
    case SET_OVERTIME_ENTRIES:
      return { ...state, overtimeEntriesByEmail: _keyBy(payload, 'userEmail') };
    case SET_OVERTIME_MARKED_WEEKS:
      return { ...state, overtimeMarkedWeeksByIds: _keyBy(payload, 'id') };
    case SET_UPLOAD_PAY_STUBS_FEEDBACK:
      return { ...state, uploadPayStubsFeedback: payload };
    case SET_UPLOAD_TIME_OFF_UPDATE_FEEDBACK:
      return { ...state, uploadTimeOffUpdateFeedback: _orderBy(payload, ['employeeId', 'timeOffType']) };
    default:
      return state;
  }
};

export default withPropTypes('AccountingReducer', schema)(AccountingReducer);
