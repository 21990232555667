import { AbilityBuilder } from '@casl/ability';
import commonActions from '../abilityActions';
import { isInRole } from '../abilityHelpers';
import userRoles from '../userRoles';
import sectionsAndPages from '../../sectionsAndPages';

const { timeReporting } = sectionsAndPages;

export default function getRules(user) {
  const { rules, can } = AbilityBuilder.extract();
  const { navigateTo, getList } = commonActions;
  const { projectManager, teamLead, admin } = userRoles;

  if (isInRole(user, [projectManager, teamLead, admin])) {
    can(navigateTo, timeReporting);
    can(getList, timeReporting);
  }

  return rules;
}
