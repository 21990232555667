import _keyBy from 'lodash.keyby';
import _omit from 'lodash.omit';
import PropTypes from 'prop-types';
import { PUSH_OVERTIME_REQUEST, SET_OVERTIME_REQUESTS, REMOVE_OVERTIME_REQUEST } from '../actions/types';
import withPropTypes from '../hoc/withPropTypes';
import OvertimeRequest from '../types/OvertimeRequest';

const schema = PropTypes.shape({
  employeesById: PropTypes.objectOf(PropTypes.exact(OvertimeRequest)),
});

const initialState = {
  overtimeRequestsById: {},
  overtimeRequestSaveSuccessful: false,
};

const OvertimeRequestReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PUSH_OVERTIME_REQUEST:
      return { ...state, overtimeRequestsById: { ...state.overtimeRequestsById, [payload.id]: payload } };
    case REMOVE_OVERTIME_REQUEST:
      return { ...state, overtimeRequestsById: _omit(state.overtimeRequestsById, payload) };
    case SET_OVERTIME_REQUESTS:
      return { ...state, overtimeRequestsById: _keyBy(payload, 'id') };
    default:
      return state;
  }
};

export default withPropTypes('OvertimeRequestReducer', schema)(OvertimeRequestReducer);
