import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getOvertimeEntries, getTimeEntries, sendTimesheetReminder } from '../../actions';
import { isFetchingEntities } from '../../helpers';
import { HarvestTimesheet, OvertimeEntry, User, HarvestWeek } from '../../types';
import TimesheetList from '../reporting/timesheet/TimesheetList';
import { getOvertimeEntriesSelector, getTimeEntriesSelector } from '../../selectors/selectors';

class TimesheetListPage extends React.PureComponent {
  static propTypes = {
    getOvertimeEntries: PropTypes.func.isRequired,
    getTimeEntries: PropTypes.func.isRequired,
    sendTimesheetReminder: PropTypes.func.isRequired,
    harvestWeeksByEmail: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.exact(HarvestWeek))).isRequired,
    isFetching: PropTypes.bool.isRequired,
    user: PropTypes.shape(User).isRequired,
    overtimeEntries: PropTypes.arrayOf(PropTypes.exact(OvertimeEntry)),
    timesheets: PropTypes.arrayOf(PropTypes.exact(HarvestTimesheet)),
  };

  static defaultProps = {
    overtimeEntries: [],
    timesheets: [],
  };

  yearStartDate = moment()
    .startOf('year')
    .startOf('week')
    .format('YYYY-MM-DD');

  endDate = moment()
    .endOf('week')
    .format('YYYY-MM-DD');

  state = { selectedStartDate: null };

  componentDidMount() {
    const defaultStartDate = moment()
      .startOf('week')
      .format('YYYY-MM-DD');

    this.setState({ selectedStartDate: defaultStartDate });
    this.handleTimeEntriesCall(defaultStartDate, this.endDate);
  }

  get weekStartDates() {
    const dateDifference = moment(this.endDate).diff(this.yearStartDate, 'week') + 1;
    const weekStartDates = [...Array(dateDifference).keys()].map(i =>
      moment(this.yearStartDate)
        .add(i, 'weeks')
        .format('YYYY-MM-DD')
        .toString()
    );

    return weekStartDates.reverse();
  }

  handleWeekStartDateChange = selectedStartDate => {
    this.setState({ selectedStartDate });
    const endDate = this.getEndDateFromStartDate(selectedStartDate);

    this.handleTimeEntriesCall(selectedStartDate, endDate);
  };

  handleForceRefresh = () => {
    const endDate = this.getEndDateFromStartDate(this.state.selectedStartDate);

    this.handleTimeEntriesCall(this.state.selectedStartDate, endDate, true);
  };

  handleTimesheetReminderEmailSending = emailRequest => this.props.sendTimesheetReminder(emailRequest);

  handleTimeEntriesCall = (startDate, endDate, forceRefresh) => {
    this.props.getOvertimeEntries({ startDate, endDate, forceRefresh });
    this.props.getTimeEntries({ startDate, endDate, forceRefresh });
  };

  getEndDateFromStartDate = startDate =>
    moment(startDate)
      .endOf('week')
      .format('YYYY-MM-DD');

  render() {
    return (
      <TimesheetList
        onRefreshClick={this.handleForceRefresh}
        timesheets={this.props.timesheets}
        harvestWeeksByEmail={this.props.harvestWeeksByEmail}
        overtimeEntries={this.props.overtimeEntries.reverse()}
        weekStartDate={this.state.selectedStartDate || this.weekStartDates[0]}
        weekStartDates={this.weekStartDates}
        onWeekStartDateChange={this.handleWeekStartDateChange}
        isFetching={this.props.isFetching}
        onSendTimesheetReminder={this.handleTimesheetReminderEmailSending}
        userName={this.props.user.name}
      />
    );
  }
}

const mapStateToProps = state => ({
  isFetching: isFetchingEntities(state, ['timeEntries', 'overtimeEntries']),
  overtimeEntries: getOvertimeEntriesSelector(state),
  timesheets: getTimeEntriesSelector(state),
  harvestWeeksByEmail: state.reporting.harvestWeeksByEmail,
  user: state.app.auth.user,
});

export default connect(
  mapStateToProps,
  { getOvertimeEntries, getTimeEntries, sendTimesheetReminder }
)(TimesheetListPage);
