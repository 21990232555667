import { AbilityBuilder } from '@casl/ability';
import commonActions from '../abilityActions';
import { isInRole } from '../abilityHelpers';
import userRoles from '../userRoles';
import sectionsAndPages from '../../sectionsAndPages';

const { payStubs } = sectionsAndPages;

export default function getRules(user) {
  const { rules, can } = AbilityBuilder.extract();
  const { navigateTo, create } = commonActions;
  const { accountant, admin } = userRoles;

  if (isInRole(user, [accountant, admin])) {
    can(navigateTo, payStubs);
    can(create, payStubs);
  }

  return rules;
}
