import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Can from '../ability/Can';

import { ProjectPurchaseOrder } from '../../types';
import sectionsAndPages from '../../helpers/sectionsAndPages';
import commonActions from '../../helpers/authorization/abilityActions';

const PurchaseOrderListRow = ({ purchaseOrder, onDelete, onFileOpen }) => {
  const handleDeleteClick = e => {
    e.stopPropagation();
    onDelete(purchaseOrder);
  };

  const handleFileOpenClick = () => onFileOpen(purchaseOrder.receiptId);

  return (
    <article className="grid__row" key={purchaseOrder.id}>
      <span className="grid__item -span-1">{purchaseOrder.number}</span>
      <span className="grid__item -span-2">{moment(purchaseOrder.date).format('YYYY-MM-DD')}</span>
      <span className="grid__item -span-2">{moment(purchaseOrder.startDate).format('YYYY-MM-DD')}</span>
      <span className="grid__item -span-2">{moment(purchaseOrder.endDate).format('YYYY-MM-DD')}</span>
      <span className="grid__item -span-2">{purchaseOrder.spanManDays}</span>
      <Can I={commonActions.getPurchaseOrderCriticalData} a={sectionsAndPages.projectDetails}>
        <span className="grid__item -span-2">{purchaseOrder.spanMoney}</span>
      </Can>
      <span className="grid__item -span-1">
        <button type="button" className="button__icon" onClick={handleFileOpenClick}>
          <FontAwesomeIcon icon="file-alt" />
        </button>
        <button type="button" className="button__icon" onClick={handleDeleteClick}>
          <FontAwesomeIcon icon="trash-alt" />
        </button>
      </span>
    </article>
  );
};

PurchaseOrderListRow.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onFileOpen: PropTypes.func.isRequired,
  purchaseOrder: PropTypes.exact(ProjectPurchaseOrder).isRequired,
};

export default PurchaseOrderListRow;
