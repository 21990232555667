import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { InputNumber } from '@vooban/inputs';
import Loader from '@vooban/loader';

class UpdateDefaultHourlyRate extends React.PureComponent {
  static propTypes = {
    onUpdateDefaultHourlyRate: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
  };

  state = {
    hourlyRate: null,
    isValid: true,
  };

  handleHourlyRateChange = e => this.setState({ hourlyRate: e.currentTarget.value || '' });

  handleUpdateDefaultHourlyRateClick = () => {
    if (this.validateForm()) this.props.onUpdateDefaultHourlyRate(this.state.hourlyRate);
  };

  validateForm = () => {
    const { hourlyRate } = this.state;
    const isValid = !!hourlyRate;
    this.setState({ isValid });

    return isValid;
  };

  render() {
    return (
      <>
        <div className="grid__item -span-8">
          <h1 className="view__title">{i18n.t('batchUpdates.defaultHourlyRate')}</h1>
          {this.props.isFetching && <Loader />}
          <div className="tile">
            <section className="form__section grid">
              <div className="grid__item -span-2">
                <InputNumber
                  name="hourlyRate"
                  value={this.state.hourlyRate}
                  onChange={this.handleHourlyRateChange}
                  label="Hourly Rate"
                  decimalScale={2}
                  max={1000}
                />
                {!this.state.isValid && (
                  <span className="input__message -error -align-right">*{i18n.t('validation.required')}</span>
                )}
              </div>
              <button
                type="button"
                className="view__action button -primary grid__item -span-2"
                onClick={this.handleUpdateDefaultHourlyRateClick}>
                {i18n.t('button.submit')}
              </button>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default UpdateDefaultHourlyRate;
