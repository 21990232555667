import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Form, Formik, Field } from 'formik';
import { FormikDatePicker } from '@vooban/formik-inputs';
import { validate, required } from '../../helpers/formHelpers';

class BillableHourRatiosDateForm extends React.PureComponent {
  static propTypes = {
    onFormSubmit: PropTypes.func.isRequired,
  };

  handleSubmit = values => this.props.onFormSubmit(values);

  render() {
    return (
      <div className="tile">
        <Formik initialValues={this.state} onSubmit={this.handleSubmit}>
          <Form className="form">
            <section className="grid">
              <div className="grid__row">
                <div className="grid__item -span-3">
                  <Field
                    component={FormikDatePicker}
                    name="startDate"
                    placeholder={i18n.t('billableHourRatios.startDate')}
                    validate={validate([required])}
                    hideKeyboardShortcutsPanel
                  />
                </div>
                <div className="grid__item -span-3">
                  <Field
                    component={FormikDatePicker}
                    name="endDate"
                    placeholder={i18n.t('billableHourRatios.endDate')}
                    validate={validate([required])}
                    hideKeyboardShortcutsPanel
                  />
                </div>
              </div>
              <div className="grid__row">
                <button type="submit" className="view__action button -primary grid__item">
                  {i18n.t('button.generate')}
                </button>
              </div>
            </section>
          </Form>
        </Formik>
      </div>
    );
  }
}

export default BillableHourRatiosDateForm;
