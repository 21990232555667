import React from 'react';
import { FormikMoney, FormikText } from '@vooban/formik-inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import i18n from 'i18next';

const TaskDetails = props => {
  const handleRemoveLine = () => props.removeTask(props.index);

  return (
    <div className="grid__row">
      <Field
        className="grid__item -span-4 -input"
        component={FormikText}
        name={`projectTasks[${props.index}].name`}
        readOnly
        label={i18n.t('project.task')}
      />
      <Field
        className="grid__item -span-4 -input"
        component={FormikMoney}
        name={`projectTasks[${props.index}].rate`}
        label={i18n.t('project.hourlyRate')}
      />
      <button type="button" onClick={handleRemoveLine}>
        <FontAwesomeIcon icon="trash-alt" />
      </button>
    </div>
  );
};

TaskDetails.propTypes = {
  index: PropTypes.number.isRequired,
  removeTask: PropTypes.func.isRequired,
};

export default TaskDetails;
