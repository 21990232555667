const getFileNameFromContentDisposition = contentDisposition => {
  const findFileName = /(filename=")(.+)(")/;
  const matches = findFileName.exec(contentDisposition);

  if (matches && matches[2]) return matches[2];

  return '';
};

export default getFileNameFromContentDisposition;
