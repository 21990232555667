import { AbilityBuilder } from '@casl/ability';
import commonActions from '../abilityActions';
import { isInRole } from '../abilityHelpers';
import userRoles from '../userRoles';
import sectionsAndPages from '../../sectionsAndPages';

const { timeComparisons } = sectionsAndPages;

export default function getRules(user) {
  const { rules, can } = AbilityBuilder.extract();
  const { navigateTo, getList } = commonActions;
  const { projectManager, admin, teamLead } = userRoles;

  if (isInRole(user, [projectManager, admin, teamLead])) {
    can(navigateTo, timeComparisons);
    can(getList, timeComparisons);
  }

  return rules;
}
