import React, { PureComponent } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime } from '@fortawesome/free-solid-svg-icons';
import TimeSpent from '../../../types/TimeSpent';

class TimeReportingItem extends PureComponent {
  static propTypes = {
    timeSpent: PropTypes.arrayOf(PropTypes.exact(TimeSpent)).isRequired,
    parentKey: PropTypes.string.isRequired,
    className: PropTypes.string,
    level: PropTypes.number,
    onWorklogsClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
    level: 1,
  };

  state = {
    isOpen: false,
  };

  handleRowClick = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  handleWorklogsClick = (e, issueNumber) => this.props.onWorklogsClick(e, issueNumber);

  render() {
    const { level, parentKey, timeSpent } = this.props;
    const parentTimeSpent = timeSpent.find(ts => ts.key === parentKey);
    const childrenTimeSpent = timeSpent.filter(ts => ts.parentKey === parentKey);
    const hasChildren = childrenTimeSpent.length !== 0;
    const className = `grid__row -hover ${this.props.className} ${hasChildren ? '-linked' : '-disabled'}`;

    return (
      <>
        <a role="presentation" className={className} onClick={this.handleRowClick} href="/#">
          <span className="grid__item -span-1">{parentTimeSpent.issueNumber}</span>
          <span className="grid__item -span-2">{parentTimeSpent.summary}</span>
          <span className="grid__item -span-2">{parentTimeSpent.totalTimeSpent.toFixed(2)}</span>
          <span className="grid__item -span-2">{parentTimeSpent.maintenanceTimeSpent.toFixed(2)}</span>
          <span className="grid__item -span-2">{parentTimeSpent.newTimeSpent.toFixed(2)}</span>
          <span className="grid__item -span-1">{parentTimeSpent.from}</span>
          <span className="grid__item -span-1">{parentTimeSpent.to}</span>
          <span className="grid__item -span-1 -align-right">
            {parentTimeSpent.totalTimeSpent !== 0 && (
              <button
                type="button"
                className="button__icon"
                onClick={e => this.handleWorklogsClick(e, parentTimeSpent.issueNumber)}
                title={i18n.t('timeReporting.worklogs')}>
                <FontAwesomeIcon icon={faBusinessTime} />
              </button>
            )}
          </span>
        </a>

        {this.state.isOpen &&
          childrenTimeSpent.map(cts => (
            <TimeReportingItem
              className={`-indent-${level}`}
              key={`${cts.requestDate}-${cts.key}`}
              parentKey={cts.key}
              timeSpent={timeSpent}
              level={level + 1}
              onWorklogsClick={this.handleWorklogsClick}
            />
          ))}
      </>
    );
  }
}

export default TimeReportingItem;
