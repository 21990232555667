import i18n from 'i18next';
import * as Yup from 'yup';

const getPhoneNumberLength = length => [length, i18n.t('validation.phoneNumber', { length })];

const getRequiredString = () =>
  Yup.string()
    .ensure()
    .required(i18n.t('validation.required'));

const getEmployeeSchema = () =>
  Yup.object().shape(
    {
      workEmail: getRequiredString().matches(/^[a-zA-Z0-9._-]+$/, i18n.t('validation.invalidEmail')),
      password: getRequiredString()
        .min(8, i18n.t('validation.passwordMinimum'))
        .max(16, i18n.t('validation.passwordMaximum'))
        .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, i18n.t('validation.password')),
      firstName: getRequiredString(),
      lastName: getRequiredString(),
      department: getRequiredString(),
      homeEmail: getRequiredString()
        .email()
        .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, i18n.t('validation.invalidEmail')),
      mobilePhone: Yup.string()
        .max(...getPhoneNumberLength(17))
        .min(...getPhoneNumberLength(17))
        .required(i18n.t('validation.required')),
      gender: getRequiredString(),
      hireDate: getRequiredString(),
      salary: 0,
      extensionNumberHundred: Yup.number().required(i18n.t('validation.required')),
    },
    ['label', 'fullAddress', 'note']
  );

export default getEmployeeSchema;
