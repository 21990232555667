export default {
  actions: 'Actions',
  applicant: 'Applicant',
  approver: 'Approver',
  confirmRemove: 'Are you sure you want to delete this entry?',
  endDate: 'End date',
  new: 'New Request',
  none: 'None',
  project: 'Associated project',
  reason: 'Reason',
  startDate: 'Start date',
  status: 'Status',
};
