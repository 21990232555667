import React, { PureComponent } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import _orderBy from 'lodash.orderby';
import { FastField, Form, Formik } from 'formik';
import { FormikDatePicker, FormikSelect } from '@vooban/formik-inputs';
import Moment from 'moment';
import { ProjectMapping } from '../../../types';
import { maxDate, minDate, required, validate } from '../../../helpers/formHelpers';
import { addDays } from '../../../helpers/dateHelpers';

class TimeComparisonForm extends PureComponent {
  static propTypes = {
    projectMappings: PropTypes.arrayOf(PropTypes.exact(ProjectMapping)).isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  initialValues = {
    startDate: Moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'), // prettier-ignore
    endDate: Moment().startOf('week').subtract(1, 'days').format('YYYY-MM-DD'), // prettier-ignore
    project: {},
  };

  render() {
    const projectMappingOptions = this.props.projectMappings.map(project => ({
      key: project.id,
      label: project.name,
      value: project,
    }));

    return (
      <section className="grid__item -span-8">
        <div className="tile">
          <Formik initialValues={this.initialValues} onSubmit={this.props.onSubmit} enableReinitialize>
            {({ values }) => (
              <Form className="form">
                <div className="grid">
                  <div className="grid__row">
                    <div className="grid__item -span-4">
                      <FastField
                        component={FormikDatePicker}
                        name="startDate"
                        placeholder={i18n.t('timeComparison.startDate')}
                        validate={validate([required, maxDate(addDays(values.endDate, -1))])}
                        hideKeyboardShortcutsPanel
                      />
                    </div>
                    <div className="grid__item -span-4">
                      <FastField
                        component={FormikDatePicker}
                        name="endDate"
                        placeholder={i18n.t('timeComparison.endDate')}
                        validate={validate([required, minDate(addDays(values.startDate, 1))])}
                        hideKeyboardShortcutsPanel
                      />
                    </div>
                  </div>
                  <div className="grid__row">
                    <div className="grid__item -span-4">
                      <FastField
                        component={FormikSelect}
                        options={_orderBy(projectMappingOptions, [pMO => pMO.label.toLowerCase()], ['ASC'])}
                        name="project"
                        label={i18n.t('timeComparison.project')}
                        validate={validate([required])}
                      />
                    </div>
                  </div>
                  <div className="grid__row">
                    <div className="grid__item -span-4">
                      <button type="submit" className="button -primary">
                        {i18n.t('button.submit')}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    );
  }
}

export default TimeComparisonForm;
