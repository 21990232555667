import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { InputRadio } from '@vooban/inputs';
import processingTypes from '../../../helpers/processingTypes';
import PayPeriodAndYearSelection from '../PayPeriodAndYearSelection';

class PayFileConversionHeader extends React.PureComponent {
  static propTypes = {
    formValues: PropTypes.shape({
      year: PropTypes.number.isRequired,
      payNumber: PropTypes.number.isRequired,
      processingType: PropTypes.string.isRequired,
    }).isRequired,
    onYearChange: PropTypes.func.isRequired,
    onPayNumberChange: PropTypes.func.isRequired,
    onProcessingTypeToggle: PropTypes.func.isRequired,
  };

  handleprocessingTypeToggle = e => this.props.onProcessingTypeToggle(e.currentTarget.value);

  handleYearChange = year => this.props.onYearChange(year);

  handlePayNumberChange = payNumber => this.props.onPayNumberChange(payNumber);

  render() {
    return (
      <>
        <h1 className="view__title">{i18n.t('accounting.processTimeOff')}</h1>
        <div className="grid">
          <section className="grid__item -span-8 tile">
            <section className="form__section grid">
              <PayPeriodAndYearSelection
                formValues={{ ...this.props.formValues }}
                onYearChange={this.handleYearChange}
                onPayNumberChange={this.handlePayNumberChange}
              />
              <InputRadio
                name="processingType"
                options={[{ label: i18n.t('accounting.manualProcess'), value: processingTypes.manualProcess }]}
                value={this.props.formValues.processingType}
                onChange={this.handleprocessingTypeToggle}
                className="grid__item -span-6 -modal -align-right"
              />
            </section>
          </section>
        </div>
      </>
    );
  }
}

export default PayFileConversionHeader;
