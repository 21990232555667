import React from 'react';
import PropTypes from 'prop-types';
import { BillableHourRatiosEmployee } from '../../types';

class BillableHourRatiosDetailsRow extends React.PureComponent {
  static propTypes = {
    employeeHourRatios: PropTypes.exact(BillableHourRatiosEmployee).isRequired,
  };

  render() {
    return (
      <div className="grid__row -highlightable">
        <div className="grid__item -span-3">
          {this.props.employeeHourRatios.firstName} {this.props.employeeHourRatios.lastName}
        </div>
        <div className="grid__item -span-2">
          {(this.props.employeeHourRatios.employeeBillableRatio * 100).toFixed(2)}%
        </div>
        <div className="grid__item -span-2">
          {(this.props.employeeHourRatios.employeeNonBillableRatio * 100).toFixed(2)}%
        </div>
        <div className="grid__item -span-2">
          {(this.props.employeeHourRatios.employeeAbsenteeRatio * 100).toFixed(2)}%
        </div>
      </div>
    );
  }
}

export default BillableHourRatiosDetailsRow;
