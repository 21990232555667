export default {
  balancing: 'Balancing',
  confirmSendToAll: 'Send an email to all unbalancing users?',
  employeeNumber: 'Employee Number',
  endDate: 'End date',
  enterProjectNumber: 'Enter Project Identifier',
  gap: 'Gap',
  harvestHours: 'Harvest Hours',
  jiraHours: 'Jira Hours',
  name: 'Name',
  project: 'Project',
  sendEmail: 'Send Email',
  startDate: 'Start date',
};
