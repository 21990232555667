import _keyBy from 'lodash.keyby';
import _omit from 'lodash.omit';
import {
  DELETE_PROJECT_MAPPING,
  SET_HARVEST_PROJECTS,
  SET_HARVEST_TASKS,
  SET_JIRA_PROJECTS,
  SET_PROJECT_MAPPING,
  SET_PROJECT_MAPPINGS,
  SET_PURCHASE_ORDERS,
  ADD_PURCHASE_ORDER,
  REMOVE_PURCHASE_ORDER,
  SET_PROJECT_MAPPING_DOCUMENTS,
  REMOVE_PROJECT_MAPPING_DOCUMENT,
  ADD_PROJECT_MAPPING_DOCUMENT,
  SET_TEMPO_ACCOUNTS,
} from '../actions/types';

const initialState = {
  jiraProjectsById: {},
  projectMappingsById: {},
  harvestProjectsById: {},
  harvestTasksById: {},
  purchaseOrdersById: {},
  documentsById: {},
  tempoAccountsByKey: {},
};

const ProjectReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_JIRA_PROJECTS:
      return { ...state, jiraProjectsById: _keyBy(payload.map(p => ({ ...p, id: +p.id })), 'id') };
    case SET_TEMPO_ACCOUNTS:
      return { ...state, tempoAccountsByKey: _keyBy(payload.map(p => ({ ...p, key: p.key })), 'key') };
    case SET_HARVEST_PROJECTS:
      return { ...state, harvestProjectsById: _keyBy(payload.map(p => ({ ...p, id: +p.id })), 'id') };
    case SET_HARVEST_TASKS:
      return { ...state, harvestTasksById: _keyBy(payload.map(p => ({ ...p, id: +p.id })), 'id') };
    case SET_PROJECT_MAPPINGS:
      return { ...state, projectMappingsById: _keyBy(payload, 'id') };
    case SET_PROJECT_MAPPING:
      return {
        ...state,
        projectMappingsById: { ...state.projectMappingsById, [payload.id]: payload },
      };
    case DELETE_PROJECT_MAPPING:
      return { ...state, projectMappingsById: _omit(state.projectMappingsById, payload) };
    case SET_PURCHASE_ORDERS:
      return { ...state, purchaseOrdersById: _keyBy(payload, 'id') };
    case ADD_PURCHASE_ORDER:
      return { ...state, purchaseOrdersById: { ...state.purchaseOrdersById, [payload.id]: payload } };
    case REMOVE_PURCHASE_ORDER:
      return { ...state, purchaseOrdersById: _omit(state.purchaseOrdersById, payload) };
    case SET_PROJECT_MAPPING_DOCUMENTS:
      return { ...state, documentsById: _keyBy(payload, 'id') };
    case REMOVE_PROJECT_MAPPING_DOCUMENT:
      return { ...state, documentsById: _omit(state.documentsById, payload) };
    case ADD_PROJECT_MAPPING_DOCUMENT:
      return { ...state, documentsById: { ...state.documentsById, [payload.id]: payload } };
    default:
      return state;
  }
};

export default ProjectReducer;
