import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pushModalMessage } from '../../actions/appActions';
import PayStubsUpload from '../accounting/payStubsUpload/PayStubsUpload';
import { uploadZipFile } from '../../actions/accountingActions';
import Can from '../ability/Can';
import commonActions from '../../helpers/authorization/abilityActions';
import sectionsAndPages from '../../helpers/sectionsAndPages';
import UploadFeedback from '../../types/UploadFeedback';
import { isFetchingEntities } from '../../helpers';

class PayStubsUploadPage extends React.PureComponent {
  static propTypes = {
    pushModalMessage: PropTypes.func.isRequired,
    uploadZipFile: PropTypes.func.isRequired,
    uploadFeedback: PropTypes.arrayOf(PropTypes.exact(UploadFeedback)).isRequired,
    isFetching: PropTypes.bool.isRequired,
  };

  handlePushModalMessage = message => {
    this.props.pushModalMessage(message);
  };

  handleFileUpload = file => {
    this.props.uploadZipFile(file);
  };

  render() {
    return (
      <Can I={commonActions.navigateTo} a={sectionsAndPages.payStubs}>
        <PayStubsUpload
          pushModalMessage={this.handlePushModalMessage}
          onUploadFile={this.handleFileUpload}
          uploadFeedback={this.props.uploadFeedback}
          isFetching={this.props.isFetching}
        />
      </Can>
    );
  }
}

const mapStateToProps = state => ({
  uploadFeedback: state.accounting.uploadPayStubsFeedback,
  isFetching: isFetchingEntities(state, ['uploadPayStubsFeedback']),
});

export default connect(
  mapStateToProps,
  { pushModalMessage, uploadZipFile }
)(PayStubsUploadPage);
