export default {
  actions: 'Actions',
  addMember: 'Ajouter un membre',
  confirmRemoveTeam: "Désirez-vous réellement supprimer l'équipe '{{teamName}}'?",
  confirmRemoveTeamMember: "Désirez-vous réellement enlever '{{memberName}}' de l'équipe '{{teamName}}'?",
  createTeam: 'Créer une équipe',
  deleteTeam: "Supprimer l'équipe",
  email: 'Courriel',
  employee: 'Employé',
  employees: 'Employés',
  id: 'Id',
  project: 'Projet',
  name: 'Nom',
  newTeam: 'Nouvelle équipe',
  newTeamMember: "Nouveau membre de l'équipe",
  team: 'Équipe',
  teamLead: "Chef d'équipe",
  teamMembers: "Membres de l'équipe",
  returnToProject: 'Retourner au projet',
};
