import _debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _orderBy from 'lodash.orderby';
import { FormikText, FormikDatePicker, FormikMultiSelect } from '@vooban/formik-inputs';
import i18n from 'i18next';
import _uniqBy from 'lodash.uniqby';
import { Form, Formik, Field } from 'formik';
import _isEmpty from 'lodash.isempty';

import { IssueSuggestion } from '../../../types';
import { validate, notEmpty, bothDatesRequired } from '../../../helpers/formHelpers';

class CustomQueryForm extends PureComponent {
  static propTypes = {
    onAddSpentOnIssueQuery: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onGetIssueSuggestions: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    issueSuggestions: PropTypes.arrayOf(PropTypes.exact(IssueSuggestion)).isRequired,
    initialValues: PropTypes.shape({
      keys: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }).isRequired,
  };

  state = {
    suggestions: [],
  };

  handleIssueInputChange = _debounce(query => {
    if (query) this.props.onGetIssueSuggestions(query);
  }, 250);

  componentDidMount() {
    this.props.initialValues.keys.forEach(key => this.handleCreateOption(key, this.props.initialValues.keys));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.issueSuggestions !== this.props.issueSuggestions) {
      this.handleAddSuggestionsOptions();
    }
  }

  handleSubmitReportRequest = values => this.props.onSubmit(values);

  handleAddSuggestionsOptions = () => {
    const formattedOptions = this.props.issueSuggestions.map(suggestion => ({
      key: suggestion.key,
      label: `${suggestion.key} - ${suggestion.summary}`,
      value: suggestion.key,
    }));

    this.setState(prevState => ({ suggestions: _uniqBy([...prevState.suggestions, ...formattedOptions], 'key') }));
  };

  handleAddSpentOnIssueQuery = values => {
    const { startDate, endDate, name } = values;
    const queryIssueKeys = values.keys.map(issueKey => ({ issueKey }));
    const spentOnIssueQuery = { name, queryIssueKeys, email: this.props.email, startDate, endDate };
    this.props.onAddSpentOnIssueQuery(spentOnIssueQuery);
  };

  handleCreateOption = (option, keys, setFieldValue = () => undefined) => {
    this.setState(prevState => ({
      suggestions: [...prevState.suggestions, { label: option, key: option, value: option }],
    }));

    setFieldValue('keys', [...keys, option]);
  };

  render() {
    return (
      <div className="tile -no-top">
        <Formik
          onSubmit={this.handleSubmitReportRequest}
          initialValues={this.props.initialValues}
          validate={values => bothDatesRequired(values.startDate, values.endDate)}>
          {({ setFieldValue, values, errors }) => (
            <Form>
              <section className="form__section grid">
                <div className="grid__row">
                  <div className="grid__item -span-12">
                    <p className="view__subtitle">{i18n.t('timeReporting.customQuery')}</p>
                  </div>
                </div>
                <div className="grid__row">
                  <div className="grid__item -span-12">
                    <p className="view__info">{i18n.t('timeReporting.emptyDates')}</p>
                  </div>
                </div>
                <div className="grid__row">
                  <div className="grid__item -span-4">
                    <Field
                      component={FormikDatePicker}
                      name="startDate"
                      placeholder={i18n.t('timeReporting.startDate')}
                      hideKeyboardShortcutsPanel
                      error={errors.dates}
                    />
                  </div>
                  <div className="grid__item -span-4">
                    <Field
                      component={FormikDatePicker}
                      name="endDate"
                      placeholder={i18n.t('timeReporting.endDate')}
                      hideKeyboardShortcutsPanel
                    />
                  </div>
                  {errors.dates && <div className="input__message -error -form-error">{errors.dates}</div>}
                </div>

                <div className="grid__row">
                  <div className="grid__item -span-4">
                    <Field
                      validate={validate([notEmpty])}
                      name="keys"
                      component={FormikMultiSelect}
                      options={_orderBy(this.state.suggestions, [sugg => sugg.key.toLowerCase()], ['ASC'])}
                      className="grid__item -span-6 -start-row"
                      label={i18n.t('timeReporting.issues')}
                      onInputChange={this.handleIssueInputChange}
                      onCreateOption={option => this.handleCreateOption(option, values.keys, setFieldValue)}
                      placeholder={i18n.t('timeReporting.startTyping')}
                      isEditable
                    />
                  </div>
                  <div className="grid__item -span-4">
                    <Field
                      className="grid__item -span-6 -input"
                      component={FormikText}
                      name="name"
                      label={i18n.t('timeReporting.queryName')}
                    />
                  </div>
                </div>

                <div className="grid__row">
                  <button type="submit" className="view__action button -primary">
                    {i18n.t('button.retrieve')}
                  </button>
                  {!_isEmpty(values.keys) && values.name && (
                    <button
                      type="button"
                      className="button -primary"
                      onClick={() => this.handleAddSpentOnIssueQuery(values)}>
                      {i18n.t('timeReporting.saveQuery')}
                    </button>
                  )}
                </div>
              </section>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default CustomQueryForm;
