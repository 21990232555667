import React, { PureComponent } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { UISref } from '@uirouter/react';
import _orderBy from 'lodash.orderby';
import { Employee } from '../../types';
import azureIcon from '../../assets/images/azure.png';
import azureGreyIcon from '../../assets/images/azure-grey.png';
import bambooIcon from '../../assets/images/bamboo.png';
import bambooGreyIcon from '../../assets/images/bamboo-grey.png';
import googleIcon from '../../assets/images/google.png';
import googleGreyIcon from '../../assets/images/google-grey.png';
import harvestIcon from '../../assets/images/harvest.png';
import harvestGreyIcon from '../../assets/images/harvest-grey.png';
import ubikIcon from '../../assets/images/ubik.png';
import ubikGreyIcon from '../../assets/images/ubik-grey.png';
import sectionsAndPages from '../../helpers/sectionsAndPages';
import commonActions from '../../helpers/authorization/abilityActions';
import trashIcon from '../../assets/images/trash.svg';
import Can from '../ability/Can';

class EmployeeList extends PureComponent {
  static propTypes = {
    employees: PropTypes.arrayOf(PropTypes.exact(Employee)).isRequired,
    existingBambooEmployees: PropTypes.arrayOf(PropTypes.string).isRequired,
    existingHarvestEmployees: PropTypes.arrayOf(PropTypes.string).isRequired,
    existingAzureADEmployees: PropTypes.arrayOf(PropTypes.string).isRequired,
    existingGoogleAppsEmployees: PropTypes.arrayOf(PropTypes.string).isRequired,
    existingUbikEmployees: PropTypes.arrayOf(PropTypes.string).isRequired,
    removeEmployee: PropTypes.func.isRequired,
  };

  state = {
    visibleEmployees: this.props.employees,
  };

  handleSearchValueChange = e => {
    const { value } = e.target;
    let newEmployeeList = this.props.employees;
    if (value && value.length > 0) {
      newEmployeeList = this.props.employees.filter(employee => this.searchEmployee(employee, value));
    }
    this.setState({
      visibleEmployees: value.length > 0 ? newEmployeeList : this.props.employees,
    });
  };

  handleRemoveClick = id => {
    this.props.removeEmployee(id);
    this.setState(state => ({ visibleEmployees: state.visibleEmployees.filter(employee => employee.id !== id) }));
  };

  searchEmployee = (employee, value) => {
    const fullName = `${employee.firstName} ${employee.lastName}`.toLowerCase();
    const email = employee.workEmail.toLowerCase();

    return fullName.includes(value.toLowerCase()) || email.includes(value.toLowerCase());
  };

  render() {
    return (
      <>
        <div className="grid">
          <section className="grid__item -span-8">
            <div className="grid">
              <aside className="grid__item -span-6">
                <h1 className="view__title ">{i18n.t('title.employees')}</h1>
              </aside>
              <Can I={commonActions.navigateTo} a={sectionsAndPages.newEmployee}>
                <aside className="grid__item -span-6 -align-right">
                  <UISref to={sectionsAndPages.newEmployee}>
                    <button type="button" className="button -primary">
                      {i18n.t('employee.new')}
                    </button>
                  </UISref>
                </aside>
              </Can>
              <div className="grid__row">
                <fieldset className="search">
                  <input
                    type="text"
                    className="search__field"
                    onChange={this.handleSearchValueChange}
                    placeholder={i18n.t('search')}
                  />
                  <label htmlFor="search" className="search__icon">
                    <FontAwesomeIcon icon="search" />
                  </label>
                </fieldset>
              </div>
            </div>

            <div className="tile">
              {!this.state.visibleEmployees.length && (
                <div className="grid">
                  <div className="grid__item -span-12 -align-center">
                    <p>{i18n.t('message.nothingFound')}</p>
                  </div>
                </div>
              )}
              {!!this.state.visibleEmployees.length && (
                <section className="grid">
                  <h6 className="grid__heading -span-4">{i18n.t('employee.name')}</h6>
                  <h6 className="grid__heading -span-4">{i18n.t('employee.email')}</h6>
                  <div className="grid__heading -span-4" />

                  {_orderBy(this.state.visibleEmployees, [emp => emp.firstName.toLowerCase()], ['ASC']).map(
                    employee => (
                      <>
                        <article className="grid__row">
                          <UISref
                            to={sectionsAndPages.employeeInfo}
                            params={{ employeeId: employee.id }}
                            key={employee.id}
                            className="grid__item">
                            <span className="grid__row -span-11 -linked">
                              <span className="grid__item -span-4">{`${employee.firstName} ${employee.lastName}`}</span>
                              <span className="grid__item -span-4">{employee.workEmail}</span>
                              <span className="grid__item -span-4">
                                <img
                                  className="grid__logo -small"
                                  src={
                                    this.props.existingBambooEmployees.includes(employee.workEmail)
                                      ? bambooIcon
                                      : bambooGreyIcon
                                  }
                                  alt="Bamboo"
                                  title="Bamboo"
                                />
                                <img
                                  className="grid__logo -small"
                                  src={
                                    this.props.existingHarvestEmployees.includes(employee.workEmail)
                                      ? harvestIcon
                                      : harvestGreyIcon
                                  }
                                  alt="Harvest"
                                  title="Harvest"
                                />
                                <img
                                  className="grid__logo -small"
                                  src={
                                    this.props.existingAzureADEmployees.includes(employee.workEmail)
                                      ? azureIcon
                                      : azureGreyIcon
                                  }
                                  alt="Azure AD"
                                  title="Azure AD"
                                />
                                <img
                                  className="grid__logo -small"
                                  src={
                                    this.props.existingGoogleAppsEmployees.includes(employee.workEmail)
                                      ? googleIcon
                                      : googleGreyIcon
                                  }
                                  alt="Google Apps"
                                  title="Google Apps"
                                />
                                <img
                                  className="grid__logo -small"
                                  src={
                                    this.props.existingUbikEmployees.includes(employee.workEmail)
                                      ? ubikIcon
                                      : ubikGreyIcon
                                  }
                                  alt="UbikTs"
                                  title="UbikTs"
                                />
                              </span>
                            </span>
                          </UISref>
                          <span className="grid__item -span-1">
                            <button type="button" onClick={() => this.handleRemoveClick(employee.id)}>
                              <img className="grid__logo -small" src={trashIcon} alt="trash" title="trash" />
                            </button>
                          </span>
                        </article>
                      </>
                    )
                  )}
                </section>
              )}
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default EmployeeList;
