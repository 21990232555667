import React, { PureComponent } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { Modal, ConfirmModal } from '@vooban/modals';
import _orderBy from 'lodash.orderby';
import AddContactModal from './AddContactModal';
import { Contact } from '../../types';
import ContactListRow from './ContactListRow';
import EditContactModal from './EditContactModal';

class ContactList extends PureComponent {
  static propTypes = {
    contacts: PropTypes.arrayOf(PropTypes.shape(Contact)),
    onDelete: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    noTop: PropTypes.bool.isRequired,
  };

  state = {
    contactToDelete: {},
    contactToEdit: {},
  };

  static defaultProps = {
    contacts: [],
  };

  createContactModalRef = React.createRef();

  updateContactModalRef = React.createRef();

  deleteContactModalRef = React.createRef();

  handleCreateClick = () => this.createContactModalRef.current.open();

  handleSaveContact = contact => {
    this.props.onSave(contact);
    this.createContactModalRef.current.close();
  };

  handleUpdateContact = contact => {
    this.props.onUpdate(contact);
    this.updateContactModalRef.current.close();
  };

  handleCloseCreationModal = () => this.createContactModalRef.current.close();

  handleCloseEditModal = () => this.updateContactModalRef.current.close();

  handleOpenDeletionModal = (e, contactToDelete) => {
    e.stopPropagation();
    this.handleSetContactToDelete(contactToDelete);
    this.deleteContactModalRef.current.open();
  };

  handleOpenEditModal = contactToEdit => {
    this.handleSetContactToEdit(contactToEdit);
    this.updateContactModalRef.current.open();
  };

  handleDeleteContact = () => this.props.onDelete(this.state.contactToDelete);

  handleSetContactToDelete = contactToDelete => this.setState({ contactToDelete });

  handleSetContactToEdit = contactToEdit => this.setState({ contactToEdit });

  render() {
    const noTop = this.props.noTop ? '-no-top' : '';
    return (
      <>
        <div className={`tile ${noTop}`}>
          <header className="tile__header">
            <h3 className="view__subtitle">{i18n.t('title.contacts')}</h3>
            <button type="button" className="button -primary" onClick={this.handleCreateClick}>
              {i18n.t('button.create')}
            </button>
          </header>
          <section className="grid">
            <div className="grid__row">
              <h6 className="grid__heading -span-3">{i18n.t('contact.name')}</h6>
              <h6 className="grid__heading -span-2">{i18n.t('contact.title')}</h6>
              <h6 className="grid__heading -span-2">{i18n.t('contact.email')}</h6>
              <h6 className="grid__heading -span-2">{i18n.t('contact.telephone')}</h6>
              <h6 className="grid__heading -span-2">{i18n.t('contact.cellphone')}</h6>
              <h6 className="grid__heading -span-1">{i18n.t('contact.actions')}</h6>
              {_orderBy(this.props.contacts, [contact => contact.name.toLowerCase()], ['ASC']).map(contact => {
                const handleDeleteClick = e => this.handleOpenDeletionModal(e, contact);
                const handleUpdateClick = () => this.handleOpenEditModal(contact);
                return (
                  <ContactListRow
                    key={contact.id}
                    contact={contact}
                    onDelete={handleDeleteClick}
                    onUpdate={handleUpdateClick}
                  />
                );
              })}
            </div>
          </section>
        </div>
        <Modal ref={this.createContactModalRef} width="medium">
          <AddContactModal onConfirm={this.handleSaveContact} onClose={this.handleCloseCreationModal} />
        </Modal>

        <Modal ref={this.updateContactModalRef} width="medium">
          <EditContactModal
            contact={this.state.contactToEdit}
            onConfirm={this.handleUpdateContact}
            onClose={this.handleCloseEditModal}
          />
        </Modal>

        <ConfirmModal
          ref={this.deleteContactModalRef}
          onConfirm={this.handleDeleteContact}
          title={i18n.t('contact.confirmRemove')}
          cancelLabel={i18n.t('button.no')}
          confirmLabel={i18n.t('button.yes')}
        />
      </>
    );
  }
}

export default ContactList;
