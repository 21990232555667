import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import { UISref } from '@uirouter/react';
import sectionsAndPages from '../../helpers/sectionsAndPages';
import { ProjectMapping, ProjectStatus, HarvestProject, JiraProject } from '../../types';
import Can from '../ability/Can';
import commonActions from '../../helpers/authorization/abilityActions';

const ProjectListRow = ({ harvestProjects, jiraProjects, projectMapping, onDeleteClick }) => {
  let associatedHarvestProjects = [];
  if (projectMapping.harvestIds && projectMapping.harvestIds.length > 0) {
    associatedHarvestProjects = harvestProjects.filter(harvestProject =>
      projectMapping.harvestIds.includes(harvestProject.id)
    );
  }

  const associatesJiraProjectNames = [];

  projectMapping.jiraProjects.forEach(p => {
    const foundProject = jiraProjects.find(jp => jp.id === p.id);
    if (foundProject) associatesJiraProjectNames.push(foundProject.name);
  });

  return (
    <UISref to={sectionsAndPages.projectDetails} params={{ projectId: projectMapping.id }}>
      <article className="grid__row -linked">
        <span className="grid__item -span-2">{projectMapping.name}</span>
        <span className="grid__item -span-2">{associatesJiraProjectNames.join(', ')}</span>
        <span className="grid__item -span-2">{associatedHarvestProjects.map(({ name }) => name).join(', ')}</span>
        <span className="grid__item -span-1">{projectMapping.code}</span>
        <span className="grid__item -span-1">{projectMapping.desjardinsCode}</span>
        <span className="grid__item -span-1">{ProjectStatus[projectMapping.status]}</span>
        <span className="grid__item -span-1">{projectMapping.effortInDays}</span>
        <Can I={commonActions.getProjectListCriticalData} a={sectionsAndPages.projects}>
          <span className="grid__item -span-1">{projectMapping.maxEffortInDollars}</span>
        </Can>
        <span className="grid__item -span-1">
          <button type="button" className="button__icon" onClick={onDeleteClick}>
            <FontAwesomeIcon icon="trash-alt" />
          </button>
        </span>
      </article>
    </UISref>
  );
};

ProjectListRow.propTypes = {
  onDeleteClick: PropTypes.func.isRequired,
  harvestProjects: PropTypes.arrayOf(PropTypes.exact(HarvestProject)).isRequired,
  jiraProjects: PropTypes.arrayOf(PropTypes.exact(JiraProject)).isRequired,
  projectMapping: PropTypes.exact(ProjectMapping).isRequired,
};

export default ProjectListRow;
