import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import _isEmpty from 'lodash.isempty';
import { BillableDepartmentHourRatios } from '../../types';

class BillableHourRatiosDepartmentTotals extends React.PureComponent {
  static propTypes = {
    billableAdministrationDepartmentHourRatios: PropTypes.exact(BillableDepartmentHourRatios).isRequired,
    billableProductionDepartmentHourRatios: PropTypes.exact(BillableDepartmentHourRatios).isRequired,
  };

  render() {
    const { billableAdministrationDepartmentHourRatios, billableProductionDepartmentHourRatios } = this.props;

    return (
      <>
        {!_isEmpty(billableAdministrationDepartmentHourRatios) && (
          <div className="grid__row">
            <div className="grid__item -span-12">
              <div className="tile">
                <section className="grid">
                  <div className="grid__row">
                    <h6 className="grid__heading">{i18n.t('billableHourRatios.administration')}</h6>
                  </div>
                  <div className="grid__row">
                    <h6 className="grid__heading -span-3">{i18n.t('billableHourRatios.billableRatio')}</h6>
                    <h6 className="grid__heading -span-3">{i18n.t('billableHourRatios.nonBillableRatio')}</h6>
                    <h6 className="grid__heading -span-3">{i18n.t('billableHourRatios.absenteeRatio')}</h6>
                  </div>
                  <div className="grid__row">
                    <div className="grid__item -span-3">
                      {(billableAdministrationDepartmentHourRatios.totalBillableRatio * 100).toFixed(2)}%
                    </div>
                    <div className="grid__item -span-3">
                      {(billableAdministrationDepartmentHourRatios.totalNonBillableRatio * 100).toFixed(2)}%
                    </div>
                    <div className="grid__item -span-3">
                      {(billableAdministrationDepartmentHourRatios.totalAbsenteeRatio * 100).toFixed(2)}%
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        )}

        {!_isEmpty(billableProductionDepartmentHourRatios) && (
          <div className="grid__row">
            <div className="grid__item -span-12">
              <div className="tile">
                <section className="grid">
                  <div className="grid__row">
                    <h6 className="grid__heading">{i18n.t('billableHourRatios.production')}</h6>
                  </div>
                  <div className="grid__row">
                    <h6 className="grid__heading -span-3">{i18n.t('billableHourRatios.billableRatio')}</h6>
                    <h6 className="grid__heading -span-3">{i18n.t('billableHourRatios.nonBillableRatio')}</h6>
                    <h6 className="grid__heading -span-3">{i18n.t('billableHourRatios.absenteeRatio')}</h6>
                  </div>
                  <div className="grid__row">
                    <div className="grid__item -span-3">
                      {(billableProductionDepartmentHourRatios.totalBillableRatio * 100).toFixed(2)}%
                    </div>
                    <div className="grid__item -span-3">
                      {(billableProductionDepartmentHourRatios.totalNonBillableRatio * 100).toFixed(2)}%
                    </div>
                    <div className="grid__item -span-3">
                      {(billableProductionDepartmentHourRatios.totalAbsenteeRatio * 100).toFixed(2)}%
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default BillableHourRatiosDepartmentTotals;
