import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CostTracking from '../reporting/costTracking/CostTracking';
import { generateCostTrackingDocument, getProjectWithJiraProject } from '../../actions';
import { isFetchingEntities } from '../../helpers';
import { ProjectMapping } from '../../types';

class CostTrackingPage extends React.PureComponent {
  static propTypes = {
    generateCostTrackingDocument: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    projectMappings: PropTypes.arrayOf(PropTypes.exact(ProjectMapping)),
    getProjects: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isFetching: false,
    projectMappings: [],
  };

  componentDidMount() {
    this.props.getProjects(this.hasJiraProject);
  }

  render() {
    return (
      <CostTracking
        onGenerateCostTrackingDocument={this.props.generateCostTrackingDocument}
        isFetching={this.props.isFetching}
        projectMappings={this.props.projectMappings}
      />
    );
  }
}

const mapStateToProps = state => ({
  isFetching: isFetchingEntities(state, ['costTrackingDocument', 'projectMappings']),
  projectMappings: Object.values(state.project.projectMappingsById),
});

export default connect(
  mapStateToProps,
  { getProjects: getProjectWithJiraProject, generateCostTrackingDocument }
)(CostTrackingPage);
