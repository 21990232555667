import i18n from 'i18next';
import _orderBy from 'lodash.orderby';
import React from 'react';
import PropTypes from 'prop-types';
import { FormikText, FormikSelect, FormikPhoneNumber } from '@vooban/formik-inputs';
import { FastField } from 'formik';
import { validate, required, email } from '../../helpers/formHelpers';
import { Employee } from '../../types';

const currencyOptions = [
  { key: 1, label: 'CAD', value: 'CAD' },
  { key: 2, label: 'USD', value: 'USD' },
  { key: 3, label: 'EUR', value: 'EUR' },
];

class ClientFormFields extends React.PureComponent {
  static propTypes = {
    employees: PropTypes.arrayOf(PropTypes.exact(Employee)).isRequired,
  };

  employeesToOptions = employees =>
    _orderBy(
      employees.map(employee => ({
        key: employee.id,
        label: `${employee.firstName} ${employee.lastName}`,
        value: employee.id,
      })),
      [emp => emp.label.toLowerCase()],
      ['ASC']
    );

  render() {
    const employeesOptions = this.employeesToOptions(this.props.employees);

    return (
      <div className="grid">
        <section className="grid__item -span-8">
          <div className="tile">
            <section className="form__section grid">
              <div className="grid__row">
                <FastField
                  className="grid__item -span-6 -input"
                  component={FormikText}
                  validate={validate([required])}
                  name="name"
                  label={i18n.t('client.name')}
                />
                <FastField
                  className="grid__item -span-6 -input"
                  component={FormikPhoneNumber}
                  validate={validate([required])}
                  name="phone"
                  label={i18n.t('client.companyPhone')}
                />
              </div>

              <div className="grid__row">
                <FastField
                  className="grid__item -span-6 -input"
                  component={FormikText}
                  placeholder="http://"
                  validate={validate([required])}
                  name="website"
                  label={i18n.t('client.website')}
                />
                <FastField
                  className="grid__item -span-6 -input"
                  component={FormikSelect}
                  validate={validate([required])}
                  options={_orderBy(currencyOptions, [cO => cO.label.toLowerCase()], ['ASC'])}
                  name="currency"
                  label={i18n.t('client.currency')}
                />
              </div>
              <div className="grid__row">
                <FastField
                  className="grid__item -span-6 -input"
                  component={FormikSelect}
                  options={employeesOptions}
                  validate={validate([required])}
                  name="responsibleEmployeeId"
                  label={i18n.t('client.responsibleEmployee')}
                />
              </div>
              <div className="grid__row">
                <FastField
                  className="grid__item -span-6 -input"
                  component={FormikText}
                  validate={validate([required])}
                  name="billingName"
                  label={i18n.t('client.billingName')}
                />
                <FastField
                  className="grid__item -span-6 -input"
                  component={FormikPhoneNumber}
                  validate={validate([required])}
                  name="billingPhone"
                  label={i18n.t('client.billingPhone')}
                />
              </div>
              <div className="grid__row">
                <FastField
                  className="grid__item -span-6 -input"
                  component={FormikText}
                  validate={validate([required, email])}
                  name="billingEmail"
                  label={i18n.t('client.billingEmail')}
                />
              </div>
              <div className="grid__row">
                <FastField
                  className="grid__item -span-6 -input"
                  component={FormikText}
                  validate={validate([required])}
                  name="address"
                  label={i18n.t('client.address')}
                />
                <FastField
                  className="grid__item -span-6 -input"
                  component={FormikText}
                  validate={validate([required])}
                  maxLength="20"
                  name="zipCode"
                  label={i18n.t('client.zipCode')}
                />
              </div>
              <div className="grid__row">
                <FastField
                  className="grid__item -span-6 -input"
                  component={FormikText}
                  validate={validate([required])}
                  name="city"
                  label={i18n.t('client.city')}
                />
                <FastField
                  className="grid__item -span-6 -input"
                  component={FormikText}
                  validate={validate([required])}
                  name="province"
                  label={i18n.t('client.province')}
                />
              </div>
              <div className="grid__row">
                <FastField
                  className="grid__item -span-6 -input"
                  component={FormikText}
                  validate={validate([required])}
                  name="country"
                  label={i18n.t('client.country')}
                />
              </div>
            </section>
          </div>
        </section>
      </div>
    );
  }
}
export default ClientFormFields;
