import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18next';
import Loader from '@vooban/loader';
import { isFetchingEntities } from '../../helpers';
import { getAllEmployeesBillableHourRatios, getDepartmentBillableHourRatios } from '../../actions';
import BillableHourRatiosDateForm from '../billableHourRatios/BillableHourRatiosDateForm';
import BillableHourRatiosDepartmentTotals from '../billableHourRatios/BillableHourRatiosDepartmentTotals';
import BillableHourRatiosDetails from '../billableHourRatios/BillableHourRatiosDetails';
import { BillableDepartmentHourRatios, BillableHourRatiosEmployee } from '../../types';

class BillableHourRatiosPage extends PureComponent {
  static propTypes = {
    billableAdministrationDepartmentHourRatios: PropTypes.exact(BillableDepartmentHourRatios).isRequired,
    billableProductionDepartmentHourRatios: PropTypes.exact(BillableDepartmentHourRatios).isRequired,
    detailedEmployeesHourRatios: PropTypes.arrayOf(PropTypes.exact(BillableHourRatiosEmployee)),
    getAllEmployeesBillableHourRatios: PropTypes.func.isRequired,
    getDepartmentBillableHourRatios: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    detailedEmployeesHourRatios: [],
  };

  state = {
    areDetailsOpened: false,
    areTotalsVisible: false,
  };

  onFormSubmit = params => {
    this.props.getDepartmentBillableHourRatios(params);
    this.props.getAllEmployeesBillableHourRatios(params);
    this.setState({ areTotalsVisible: true });
  };

  handleDetailsOpen = () => this.setState(prevState => ({ areDetailsOpened: !prevState.areDetailsOpened }));

  render() {
    return (
      <div className="grid">
        <div className="grid__row -header">
          <h1 className="view__title grid__item -span-6">{i18n.t('title.billableHourRatios')}</h1>
        </div>
        <div className="grid__item -span-8">
          <BillableHourRatiosDateForm onFormSubmit={this.onFormSubmit} />
          {this.state.areTotalsVisible && (
            <>
              {this.props.isFetching && <Loader />}
              {!this.props.isFetching && (
                <>
                  <BillableHourRatiosDepartmentTotals
                    billableAdministrationDepartmentHourRatios={this.props.billableAdministrationDepartmentHourRatios}
                    billableProductionDepartmentHourRatios={this.props.billableProductionDepartmentHourRatios}
                  />
                  <BillableHourRatiosDetails
                    detailedEmployeesHourRatios={this.props.detailedEmployeesHourRatios}
                    onDetailsOpen={this.handleDetailsOpen}
                    areDetailsOpened={this.state.areDetailsOpened}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: isFetchingEntities(state, ['billableHourRatiosDepartments', 'billableHourRatiosEmployees']),
  billableAdministrationDepartmentHourRatios: state.accounting.billableAdministrationDepartmentHourRatios,
  billableProductionDepartmentHourRatios: state.accounting.billableProductionDepartmentHourRatios,
  detailedEmployeesHourRatios: Object.values(state.accounting.billableHourRatiosByEmployees),
});

export default connect(
  mapStateToProps,
  { getAllEmployeesBillableHourRatios, getDepartmentBillableHourRatios }
)(BillableHourRatiosPage);
