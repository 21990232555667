import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ConfirmModal } from '@vooban/modals';
import i18n from 'i18next';
import { convertPayFile, uploadTimeOffUpdateFile } from '../../actions/accountingActions';
import PayFileConversion from '../accounting/payFileConversion/PayFileConversion';
import TimeOffUpdating from '../accounting/TimeOffUpdating';
import PreProcessedFileUploadFeedback from '../accounting/payFileConversion/PreProcessedFileUploadFeedback';
import { isFetchingEntities } from '../../helpers';
import UpdateTimeOffFeedback from '../../types/UpdateTimeOffFeedback';
import processingTypes from '../../helpers/processingTypes';
import PayFileConversionHeader from '../accounting/payFileConversion/PayFileConversionHeader';
import { getPayEndDate } from '../../helpers/dateHelpers';

const { allIn, manualProcess } = processingTypes;

class PayFileConversionPage extends React.PureComponent {
  static propTypes = {
    convertPayFile: PropTypes.func.isRequired,
    uploadTimeOffUpdateFile: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    updateTimeOffFeedback: PropTypes.arrayOf(PropTypes.exact(UpdateTimeOffFeedback)).isRequired,
  };

  state = {
    file: null,
    year: moment().year(),
    payNumber: Math.ceil(moment().week() / 2),
    processingType: manualProcess,
  };

  confirmationModal = React.createRef();

  get payEndDate() {
    return getPayEndDate(this.state.year, this.state.payNumber);
  }

  handlePayNumberChange = payNumber => this.setState({ payNumber });

  handleYearChange = year => this.setState({ year });

  handleProcessingTypeToggle = processingType => this.setState({ processingType });

  handlePayFileConvert = file => {
    if (this.state.processingType === manualProcess) {
      this.handleProcessFile(file);
    } else {
      this.handleAllInConfirmationModalOpen(file);
    }
  };

  handleProcessFile = file => {
    this.props.convertPayFile(
      this.state.year,
      this.state.payNumber,
      file,
      this.state.processingType === allIn,
      this.payEndDate
    );
  };

  handleAllInConfirmationModalOpen = file => {
    this.setState({ file }, () => {
      this.confirmationModal.current.open();
    });
  };

  handleAllInProcessConfirmation = () => {
    this.props.convertPayFile(
      this.state.year,
      this.state.payNumber,
      this.state.file,
      this.state.processingType === allIn,
      this.payEndDate
    );
    this.setState({ file: null });
  };

  handleCancel = () => this.setState({ file: null });

  shouldDisplayTimeOffUpdating = () => this.state.processingType === manualProcess;

  handleTimeOffUpdateFileUpload = file => {
    this.props.uploadTimeOffUpdateFile(this.state.year, this.state.payNumber, file, this.payEndDate);
  };

  render() {
    return (
      <>
        <PayFileConversionHeader
          onYearChange={this.handleYearChange}
          onPayNumberChange={this.handlePayNumberChange}
          onProcessingTypeToggle={this.handleProcessingTypeToggle}
          formValues={{ ...this.state }}
        />

        <PayFileConversion
          onFileConvert={this.handlePayFileConvert}
          isFetching={this.state.processingType === allIn && this.props.isFetching}
        />

        {this.shouldDisplayTimeOffUpdating() && (
          <TimeOffUpdating
            onFileUpload={this.handleTimeOffUpdateFileUpload}
            isFetching={this.props.isFetching}
            pushModalMessage={this.handlePushModalMessage}
            updateTimeOffFeedback={this.props.updateTimeOffFeedback}
          />
        )}

        <PreProcessedFileUploadFeedback updateTimeOffFeedback={this.props.updateTimeOffFeedback} />

        <ConfirmModal
          ref={this.confirmationModal}
          onConfirm={this.handleAllInProcessConfirmation}
          onCancel={this.handleCancel}
          title={i18n.t('accounting.confirmAllIn')}
          cancelLabel={i18n.t('button.no')}
          confirmLabel={i18n.t('button.yes')}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  updateTimeOffFeedback: state.accounting.uploadTimeOffUpdateFeedback,
  isFetching: isFetchingEntities(state, ['uploadTimeOffUpdateFeedback']),
});

export default connect(
  mapStateToProps,
  { convertPayFile, uploadTimeOffUpdateFile }
)(PayFileConversionPage);
