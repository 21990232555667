import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getOvertimeEntries, getOvertimeMarkedWeeks } from '../../actions';
import { isFetchingEntities } from '../../helpers';
import { OvertimeEntry, OvertimeMarkedWeek } from '../../types';
import EmployeeOvertimeEntry from '../accounting/overtime/EmployeeOvertimeEntry';

class EmployeeOvertimePage extends React.PureComponent {
  static propTypes = {
    employeeId: PropTypes.number.isRequired,
    getOvertimeEntries: PropTypes.func.isRequired,
    getOvertimeMarkedWeeks: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    overtimeEntry: PropTypes.exact(OvertimeEntry),
    overtimeMarkedWeek: PropTypes.exact(OvertimeMarkedWeek),
  };

  static defaultProps = {
    overtimeEntry: null,
    overtimeMarkedWeek: null,
  };

  startDate = moment()
    .startOf('week')
    .subtract(3, 'weeks')
    .format('YYYY-MM-DD');

  endDate = moment()
    .endOf('week')
    .format('YYYY-MM-DD');

  componentDidMount() {
    this.props.getOvertimeMarkedWeeks();
    this.props.getOvertimeEntries({
      startDate: this.startDate,
      endDate: this.endDate,
      employeeId: this.props.employeeId,
    });
  }

  render() {
    return (
      <EmployeeOvertimeEntry
        overtimeEntry={this.props.overtimeEntry}
        markedWeek={this.props.overtimeMarkedWeek}
        isFetching={this.props.isFetching}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  employeeId: +props.transition.params().employeeId,
  overtimeEntry: Object.values(state.accounting.overtimeEntriesByEmail)[0],
  overtimeMarkedWeek: Object.values(state.accounting.overtimeMarkedWeeksByIds)[0],
  isFetching: isFetchingEntities(state, ['overtimeEntries', 'overtimeMarkedWeeks']),
});

export default connect(
  mapStateToProps,
  { getOvertimeEntries, getOvertimeMarkedWeeks }
)(EmployeeOvertimePage);
