import { UISref } from '@uirouter/react';
import i18n from 'i18next';
import React from 'react';
import Scrollbox from '@vooban/scrollbox';
import PropTypes from 'prop-types';

import Can from '../ability/Can';
import commonActions from '../../helpers/authorization/abilityActions';
import Auth from '../../helpers/authorization/auth';
import sectionsAndPages from '../../helpers/sectionsAndPages';
import MenuSection from './MenuSection';

const user = Auth.getUser();
const { navigateTo } = commonActions;

const {
  accounting,
  // batchUpdates,
  // billableHourRatios,
  // bugRateReport,
  // clients,
  // costTracking,
  logout,
  operations,
  payFileConversion,
  payStubs,
  // projects,
  reporting,
  settings,
  // timeComparisons,
  // timeReporting,
  // timesheets,
  // employees,
} = sectionsAndPages;

// const reportingLinks = [timeReporting, costTracking, timeComparisons, timesheets];
// const accountingLinks = [payStubs, payFileConversion, payFileGeneration];
const accountingLinks = [payStubs, payFileConversion];

// const operationLinks = [bugRateReport, billableHourRatios];
// const settingLinks = [projects, employees, clients, batchUpdates];

class Menu extends React.Component {
  static propTypes = {
    abilities: PropTypes.shape().isRequired,
  };

  state = { openSection: '' };

  componentDidMount() {
    if (this.props.abilities.can(navigateTo, reporting)) this.handleMenuToggle('reporting');
    else if (this.props.abilities.can(navigateTo, accounting)) this.handleMenuToggle('accounting');
    else if (this.props.abilities.can(navigateTo, operations)) this.handleMenuToggle('operations');
    else if (this.props.abilities.can(navigateTo, settings)) this.handleMenuToggle('settings');

    this.setState({ openSection: this.handleIsOpen() });
  }

  handleMenuToggle = section => {
    this.setState(prevState => ({ openSection: prevState.openSection === section ? '' : section }));
  };

  handleIsOpen = () => {
    const currentUrl = window.location.href;
    const splittedUrl = currentUrl.split('/');

    switch (splittedUrl[3]) {
      case 'time-reporting':
      case 'cost-tracking':
      case 'time-entries':
      case 'timesheets':
        return 'reporting';
      case 'pay-stubs':
      case 'pay-file-conversion':
      case 'pay-file-generation':
        return 'accounting';
      case 'bug-rate-report':
      case 'billable-ratios':
        return 'operations';
      case 'projects':
      case 'employees':
      case 'clients':
      case 'batch-updates':
        return 'settings';
      default:
        return 'reporting';
    }
  };

  render() {
    return (
      <>
        <aside className="menu layout__sidebar">
          <Scrollbox>
            <img alt="vooban logo" src="/img/logo_vooban.png" />

            {/* <Can I={navigateTo} a={reporting}> */}
            {/*  <MenuSection */}
            {/*    title={i18n.t('menuSections.reporting')} */}
            {/*    onToggle={() => this.handleMenuToggle('reporting')} */}
            {/*    links={reportingLinks} */}
            {/*    isOpen={this.state.openSection === 'reporting'} */}
            {/*  /> */}
            {/* </Can> */}

            <Can I={navigateTo} a={accounting}>
              <MenuSection
                title={i18n.t('menuSections.accounting')}
                onToggle={() => this.handleMenuToggle('accounting')}
                links={accountingLinks}
                isOpen={this.state.openSection === 'accounting'}
              />
            </Can>

            {/* <Can I={navigateTo} a={operations}> */}
            {/*  <MenuSection */}
            {/*    title={i18n.t('menuSections.operations')} */}
            {/*    onToggle={() => this.handleMenuToggle('operations')} */}
            {/*    links={operationLinks} */}
            {/*    isOpen={this.state.openSection === 'operations'} */}
            {/*  /> */}
            {/* </Can> */}

            {/* <Can I={navigateTo} a={settings}> */}
            {/*  <MenuSection */}
            {/*    title={i18n.t('menuSections.settings')} */}
            {/*    onToggle={() => this.handleMenuToggle('settings')} */}
            {/*    links={settingLinks} */}
            {/*    isOpen={this.state.openSection === 'settings'} */}
            {/*  /> */}
            {/* </Can> */}

            <section className="menu__content menu__section">
              {/* <UISrefActive class="active"> */}
              {/*  <UISref to={overtimeRequestList}> */}
              {/*    <a className="menu__link">{i18n.t('menuSections.overtimeRequests')}</a> */}
              {/*  </UISref> */}
              {/* </UISrefActive> */}
              <UISref to={logout}>
                <a href="/#" className="menu__link">{`${i18n.t('menuSections.logout')} - ${user.name}`}</a>
              </UISref>
            </section>
            <p className="menu__version">{i18n.t('version', { version: process.env.REACT_APP_VERSION_NUMBER })}</p>
          </Scrollbox>
        </aside>
      </>
    );
  }
}

export default Menu;
