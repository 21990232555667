import Auth from './auth';

const requiresAuthHook = {
  criteria: {
    to: state => !state.allowAnonymous,
  },
  callback: transition => {
    const isAuthenticated = Auth.isAuthenticated();
    if (!isAuthenticated) {
      Auth.pushLastRequestedState(transition.to(), transition.targetState().params());
      const auth = new Auth();
      auth.login();
      return false;
    }
    return true;
  },
};

export default requiresAuthHook;
