import { FormikText, FormikRadio, FormikDatePicker, FormikSelect, FormikPhoneNumber } from '@vooban/formik-inputs';
import { FastField } from 'formik';
import i18n from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import _orderBy from 'lodash.orderby';

const numberOfHundreds = 10;

const hundredOptions = () => {
  const options = [];
  for (let i = 0; i < numberOfHundreds; i += 1) {
    options.push({ key: i + 1, label: i === 0 ? `${i}-99` : `${i}00-${i}99`, value: i + 1 });
  }
  return options;
};

const departmentOptions = [
  { key: 1, label: 'Production', value: 'Production' },
  { key: 2, label: 'Executive', value: 'Executive' },
  { key: 3, label: 'Administration', value: 'Administration' },
];

const EmployeeFormFields = props => {
  const { isNew } = props;
  return (
    <>
      <div className="grid">
        <section className="grid__item -span-8">
          <div className="tile">
            <section className="form__section grid">
              <div className="grid__row">
                <FastField
                  className="grid__item -span-6 -input"
                  component={FormikText}
                  name="firstName"
                  label={i18n.t('employee.firstName')}
                  disabled={!isNew}
                />
                <FastField
                  className="grid__item -span-6 -input"
                  component={FormikText}
                  name="lastName"
                  label={i18n.t('employee.lastName')}
                  disabled={!isNew}
                />
              </div>
              <div className="grid__row">
                <FastField
                  component={FormikText}
                  name="workEmail"
                  label={i18n.t('employee.workEmail')}
                  className="grid__item -span-4 -input"
                  disabled={!isNew}
                />
                <span className="row grid__item -span-2">@vooban.com</span>
                {isNew && (
                  <FastField
                    component={FormikText}
                    name="password"
                    label={i18n.t('employee.password')}
                    className="grid__item -span-6"
                    type="password"
                  />
                )}
              </div>
              <div className="grid__row">
                <FastField
                  component={FormikRadio}
                  name="gender"
                  options={[
                    { label: i18n.t('employee.male'), value: 'Male', key: 'male' },
                    { label: i18n.t('employee.female'), value: 'Female', key: 'female' },
                    { label: i18n.t('employee.other'), value: 'Other', key: 'other' },
                  ]}
                  className="grid__item -span-6"
                  label={i18n.t('employee.gender')}
                  disabled={!isNew}
                />
              </div>
              <div className="grid__row">
                <FastField
                  component={FormikText}
                  name="homeEmail"
                  className="grid__item -span-6 -input"
                  label={i18n.t('employee.homeEmail')}
                  disabled={!isNew}
                />
              </div>
              <div className="grid__row">
                {isNew && (
                  <FastField
                    component={FormikPhoneNumber}
                    className="grid__item -span-6"
                    name="mobilePhone"
                    label={i18n.t('employee.mobilePhone')}
                  />
                )}
                {!isNew && (
                  <FastField
                    component={FormikText}
                    name="mobilePhone"
                    className="grid__item -span-6"
                    label={i18n.t('employee.mobilePhone')}
                    disabled
                  />
                )}
                {isNew && (
                  <FastField
                    name="extensionNumberHundred"
                    className="grid__item -span-6"
                    component={FormikSelect}
                    options={hundredOptions()}
                    label={i18n.t('employee.extension')}
                  />
                )}
                {!isNew && (
                  <FastField
                    component={FormikText}
                    name="extensionNumber"
                    className="grid__item -span-6"
                    label={i18n.t('employee.extension')}
                    disabled
                  />
                )}
              </div>
              <div className="grid__row">
                <FastField
                  component={FormikDatePicker}
                  name="hireDate"
                  className="grid__item -span-6"
                  label={i18n.t('employee.hireDate')}
                  disabled={!isNew}
                />
              </div>
              <div className="grid__row">
                <FastField
                  component={FormikSelect}
                  name="department"
                  options={_orderBy(departmentOptions, [dep => dep.label.toLowerCase()], ['ASC'])}
                  className="grid__item -span-6"
                  label={i18n.t('employee.department')}
                  disabled={!isNew}
                />
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  );
};
EmployeeFormFields.propTypes = {
  isNew: PropTypes.bool.isRequired,
};
export default EmployeeFormFields;
