import PropTypes from 'prop-types';

const OvertimeRequest = {
  id: PropTypes.number,
  approverId: PropTypes.number,
  applicantId: PropTypes.number,
  reason: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  status: PropTypes.string,
  projectId: PropTypes.number,
};

export const OvertimeRequestStatus = {
  approved: 'Approved',
  declined: 'Declined',
};

export default OvertimeRequest;
