import React from 'react';
import _orderBy from 'lodash.orderby';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { InputReactSelect } from '@vooban/inputs';
import HarvestTask from '../../../types/HarvestTask';

const NewTask = props => (
  <div className="grid__row">
    <InputReactSelect
      className="grid__item -span-4 -input"
      options={_orderBy(props.harvestTasks, [task => task.name.toLowerCase()], ['ASC']).map(x => ({
        label: x.name,
        value: x,
      }))}
      value={props.selectedOption}
      onChange={props.onTaskNameChange}
      label={i18n.t('project.task')}
    />
  </div>
);

NewTask.propTypes = {
  harvestTasks: PropTypes.arrayOf(PropTypes.exact(HarvestTask)).isRequired,
  onTaskNameChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.number.isRequired,
};

export default NewTask;
