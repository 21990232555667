import i18n from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../../common/Tabs';
import TimeSpent from '../../../types/TimeSpent';
import TimeReportingModalParent from './TimeReportingModalParent';
import TimeReportingModalChild from './TimeReportingModalChild';

class TimeReportingModal extends React.PureComponent {
  static propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    timeSpent: PropTypes.arrayOf(PropTypes.exact(TimeSpent)),
    parentKey: PropTypes.string,
  };

  static defaultProps = {
    timeSpent: [],
    parentKey: '',
  };

  handleCancelClick = () => this.props.onCloseClick();

  render() {
    const { parentKey, timeSpent } = this.props;
    const parentTimeSpent = timeSpent.find(ts => ts.key === parentKey);
    const childrenTimeSpent = timeSpent.filter(ts => ts.parentKey === parentKey);
    const hasChildren = childrenTimeSpent.length !== 0;
    const parentIssueWorklogs = parentTimeSpent.issueWorklogs;
    const tabs = hasChildren
      ? [`${parentKey} : ${parentTimeSpent.summary}`, i18n.t('timeReporting.children')]
      : [parentKey];

    return (
      <>
        <header className="modal__header">
          <h1 className="view__title">{i18n.t('timeReporting.worklogs')}</h1>
          <button type="button" className="button -align-right" onClick={this.handleCancelClick}>
            {i18n.t('button.cancel')}
          </button>
        </header>
        <Tabs tabs={tabs}>
          <TimeReportingModalParent key={parentKey} parentIssueWorklogs={parentIssueWorklogs} />
          <TimeReportingModalChild key={i18n.t('timeReporting.children')} childrenTimeSpent={childrenTimeSpent} />
        </Tabs>
      </>
    );
  }
}

export default TimeReportingModal;
