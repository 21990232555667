import checkPropTypes from '../extensions/checkPropTypes';

const withPropTypes = (name, propTypesSchema) => reducer => {
  if (process.env.NODE_ENV === 'development') {
    return (state, action) => {
      const result = reducer(state, action);
      if (checkPropTypes({ state: propTypesSchema }, { state: result }, 'property', name)) {
        return state;
      }
      return result;
    };
  }
  return reducer;
};

export default withPropTypes;
