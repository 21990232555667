import PropTypes from 'prop-types';
import Loader from '@vooban/loader';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';

import { generateBugRateReport, getProjectWithJiraProject, exportBugRateReport } from '../../actions';
import { getProjectMappingsSelector } from '../../selectors/selectors';
import { isFetchingEntities } from '../../helpers';
import { ProjectMapping } from '../../types';
import BugRateReportList from '../bugRateReport/BugRateReportList';
import BugRateReportItem from '../bugRateReport/BugRateReportItem';
import BugRateReportForm from '../bugRateReport/BugRateReportForm';
import BugRateReportTotal from '../bugRateReport/BugRateReportTotal';

class BugRateReportPage extends PureComponent {
  static propTypes = {
    exportBugRateReport: PropTypes.func.isRequired,
    generateBugRateReport: PropTypes.func.isRequired,
    getProjects: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isFetchingReport: PropTypes.bool.isRequired,
    projects: PropTypes.arrayOf(PropTypes.exact(ProjectMapping)),
    bugRateReportItems: PropTypes.arrayOf(PropTypes.exact(BugRateReportItem)).isRequired,
  };

  static defaultProps = {
    projects: [],
  };

  componentDidMount() {
    this.props.getProjects();
  }

  verifySelectedProjects = report => {
    let associatedJiraProjectKeys = [];
    if (report.projectIds.length > 0) {
      const selectedProjects = this.props.projects.filter(x => report.projectIds.includes(x.id));
      const selectedJiraProjects = selectedProjects.map(project => project.jiraProjects);
      selectedJiraProjects.forEach(jp => {
        associatedJiraProjectKeys = [...associatedJiraProjectKeys, ...jp.map(project => project.key)];
      });
    }
    return { ...report, projectIds: associatedJiraProjectKeys };
  };

  handleRetrieveReport = report => this.props.generateBugRateReport(this.verifySelectedProjects(report));

  handleExportReport = report => this.props.exportBugRateReport(this.verifySelectedProjects(report));

  render() {
    if (this.props.isFetching) return <Loader />;
    return (
      <>
        <h1 className="view__title">{i18n.t('title.bugRateReport')}</h1>
        <div className="grid">
          <BugRateReportForm
            onRetrieve={this.handleRetrieveReport}
            onExport={this.handleExportReport}
            projects={this.props.projects}
          />
          <BugRateReportList
            isFetching={this.props.isFetchingReport}
            bugRateReportItems={this.props.bugRateReportItems}
          />
          <BugRateReportTotal bugRateReportItems={this.props.bugRateReportItems} />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  bugRateReportItems: state.reporting.bugRateReportItems,
  projects: getProjectMappingsSelector(state),
  isFetchingReport: isFetchingEntities(state, ['bugRateReport']),
  isFetching: isFetchingEntities(state, ['projectMappings']),
});

export default connect(
  mapStateToProps,
  { generateBugRateReport, getProjects: getProjectWithJiraProject, exportBugRateReport }
)(BugRateReportPage);
