import React, { PureComponent } from 'react';
import moment from 'moment';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { InputCheckbox, InputReactSelect } from '@vooban/inputs';

export default class TimesheetForm extends PureComponent {
  static propTypes = {
    onRefreshClick: PropTypes.func.isRequired,
    onToggleApprovedSheets: PropTypes.func.isRequired,
    onWeekStartDateChange: PropTypes.func.isRequired,
    shouldHideApprovedTimesheets: PropTypes.bool.isRequired,
    weekStartDate: PropTypes.string.isRequired,
    weekStartDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  arrayToOptions = array =>
    array.map(o => ({ key: o, label: `${o} (${i18n.t('timesheet.week')} ${moment.utc(o).week()})`, value: o }));

  handleRefreshClick = () => this.props.onRefreshClick();

  render() {
    return (
      <div className="grid__row">
        <div className="grid__item -span-10">
          <div className="tile">
            <section className="grid">
              <div className="grid__item -span-6">
                <InputReactSelect
                  isSearchable
                  label={i18n.t('timesheet.week')}
                  options={this.arrayToOptions(this.props.weekStartDates)}
                  onChange={this.props.onWeekStartDateChange}
                  value={this.props.weekStartDate}
                />
              </div>
              <aside className="row grid__item -span-3">
                <button type="button" className="button -primary" onClick={this.handleRefreshClick}>
                  {i18n.t('timesheet.forceRefresh')}
                </button>
              </aside>
              <div className="grid__item -span-3 -align-right">
                <InputCheckbox
                  name="shouldHideApprovedTimesheets"
                  value={this.props.shouldHideApprovedTimesheets}
                  label={i18n.t('timesheet.hideApprovedTimesheets')}
                  onChange={this.props.onToggleApprovedSheets}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
