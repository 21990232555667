import { createSelector } from 'reselect';
import _orderBy from 'lodash.orderby';

const contactSelector = state => state.contact.contactsById;
const clientSelector = state => state.client.clientsById;
const employeeSelector = state => state.employee.employeesById;
const harvestProjectsSelector = state => state.project.harvestProjectsById;
const harvestTasksSelector = state => state.project.harvestTasksById;
const jiraProjectsSelector = state => state.project.jiraProjectsById;
const overtimeEntriesSelector = state => state.accounting.overtimeEntriesByEmail;
const overtimeRequestsSelector = state => state.overtimeRequest.overtimeRequestsById;
const projectMappingsSelector = state => state.project.projectMappingsById;
const spentOnIssueQueriesSelector = state => state.reporting.spentOnIssueQueriesById;
const teamMembersSelector = state => state.team.teamMembersById;
const teamsSelector = state => state.team.teamsById;
const timeComparisonsSelector = state => state.reporting.timeComparisonsByEmail;
const timeEntriesSelector = state => state.reporting.timeEntriesByEmail;
const tempoAccountsSelector = state => state.project.tempoAccountsByKey;

// Accounting
export const getOvertimeEntriesSelector = createSelector(
  overtimeEntriesSelector,
  overtimeEntriesByEmail => Object.values(overtimeEntriesByEmail)
);

// Contacts
export const getContactsSelector = createSelector(
  contactSelector,
  contactsById => Object.values(contactsById)
);

// Clients
export const getClientsSelector = createSelector(
  clientSelector,
  clientsById => Object.values(clientsById)
);

// Tempo Accounts
export const getTempoAccountsSelector = createSelector(
  tempoAccountsSelector,
  tempoAccountsByKey => Object.values(tempoAccountsByKey)
);

// Employees
export const getEmployeesSelector = createSelector(
  employeeSelector,
  employeesById => Object.values(employeesById)
);

// Jira Projects
export const getJiraProjectsSelector = createSelector(
  jiraProjectsSelector,
  jiraProjectsById => Object.values(jiraProjectsById)
);

// Harvest Projects
export const getHarvestProjectsSelector = createSelector(
  harvestProjectsSelector,
  harvestProjectsById => Object.values(harvestProjectsById)
);

// Harvest Tasks
export const getHarvestTasksSelector = createSelector(
  harvestTasksSelector,
  harvestTasksById => Object.values(harvestTasksById)
);

// Overtime requests
export const getOvertimeRequestsSelector = createSelector(
  overtimeRequestsSelector,
  overtimeRequestsById => Object.values(overtimeRequestsById)
);

// Project Mappings
export const getProjectMappingsSelector = createSelector(
  projectMappingsSelector,
  projectMappingsById => Object.values(projectMappingsById)
);

// Reporting
export const getTimeEntriesSelector = createSelector(
  timeEntriesSelector,
  timeEntriesByEmail => Object.values(timeEntriesByEmail)
);

// Spent On Issue Queries
export const getSpentOnIssueQueriesSelector = createSelector(
  spentOnIssueQueriesSelector,
  spentOnIssueQueriesById => Object.values(spentOnIssueQueriesById)
);

// Time comparisons
export const getTimeComparisonsSelector = createSelector(
  timeComparisonsSelector,
  timeComparisonsByEmail => Object.values(timeComparisonsByEmail)
);
// Teams
export const getTeamsSelector = createSelector(
  teamsSelector,
  teamsById => Object.values(teamsById)
);

// Team Members
export const getOrderedTeamMembersSelector = createSelector(
  teamMembersSelector,
  teamMembersById => _orderBy(Object.values(teamMembersById), 'firstName')
);
