import React from 'react';
import { UISref } from '@uirouter/react';
import i18n from 'i18next';

import sectionsAndPages from '../../helpers/sectionsAndPages';

const OvertimeRequestListHeader = () => (
  <div className="grid">
    <aside className="grid__item -span-6">
      <h1 className="view__title ">{i18n.t('title.overtimeRequests')}</h1>
    </aside>
    <aside className="grid__item -span-6 -align-right">
      <UISref to={sectionsAndPages.newOvertimeRequest}>
        <button type="button" className="button -primary">
          {i18n.t('overtimeRequest.new')}
        </button>
      </UISref>
    </aside>
  </div>
);

export default OvertimeRequestListHeader;
