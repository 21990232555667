import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Contact } from '../../types';

const ContactListRow = ({ contact, onDelete, onUpdate }) => (
  <a role="presentation" className="grid__row -linked" onClick={onUpdate} href="/#">
    <span className="grid__item -span-3">{contact.name}</span>
    <span className="grid__item -span-2">{contact.title ? contact.title : i18n.t('contact.none')}</span>
    <span className="grid__item -span-2">{contact.email}</span>
    <span className="grid__item -span-2">{contact.telephone}</span>
    <span className="grid__item -span-2">{contact.cellphone}</span>
    <span className="grid__item -span-1">
      <button type="button" className="button__icon" onClick={onDelete}>
        <FontAwesomeIcon icon="trash-alt" />
      </button>
    </span>
  </a>
);

ContactListRow.propTypes = {
  contact: PropTypes.shape(Contact).isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default ContactListRow;
