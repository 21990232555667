import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Field, Formik, Form } from 'formik';
import { FormikText, FormikDatePicker, FormikNumber } from '@vooban/formik-inputs';
import FileInput from '../common/FileInput';

import { validate, fileSize, required, minDate, maxDate } from '../../helpers/formHelpers';
import { addDays } from '../../helpers/dateHelpers';

class AddPurchaseOrderModal extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
  };

  state = { file: null };

  resetFile = () => {
    document.querySelector('input[name="file"]').value = '';
    this.setState({ file: null });
  };

  handleCancelClick = resetForm => {
    this.resetFile();
    resetForm({});
    this.props.onClose();
  };

  handleFileChange = file => this.setState({ file });

  handleFormSubmit = (values, { resetForm }) => {
    this.props.onConfirm({ ...values, file: this.state.file });
    this.resetFile();
    resetForm({});
  };

  render() {
    return (
      <Formik onSubmit={this.handleFormSubmit} enableReinitialize>
        {({ values, resetForm }) => (
          <>
            <section className="modal__header">
              <div className="grid">
                <h1 className="view__title grid__item -span-10">{i18n.t('project.newPurchaseOrder')}</h1>
                <button
                  type="button"
                  className="button grid__item -align-right"
                  onClick={() => this.handleCancelClick(resetForm)}>
                  {i18n.t('button.cancel')}
                </button>
                <button type="submit" className="button -primary grid__item -align-right" form="addPurchaseOrderForm">
                  {i18n.t('button.add')}
                </button>
              </div>
            </section>
            <section className="modal__body">
              <Form id="addPurchaseOrderForm" className="grid__item -span-12">
                <div className="tile">
                  <section className="form__section grid">
                    <div className="grid__row">
                      <div className="grid__item -span-4">
                        <Field
                          validate={validate([required])}
                          name="number"
                          label={i18n.t('project.poNumber')}
                          component={FormikText}
                        />
                      </div>
                      <div className="grid__row">
                        <div className="grid__item -span-4">
                          <Field
                            validate={validate([required])}
                            name="date"
                            label={i18n.t('project.poDate')}
                            component={FormikDatePicker}
                          />
                        </div>
                        <div className="grid__item -span-4">
                          <Field
                            validate={validate([required, maxDate(addDays(values.endDate, -1))])}
                            name="startDate"
                            label={i18n.t('project.poStartDate')}
                            component={FormikDatePicker}
                          />
                        </div>
                        <div className="grid__item -span-4">
                          <Field
                            validate={validate([required, minDate(addDays(values.startDate, 1))])}
                            name="endDate"
                            label={i18n.t('project.poEndDate')}
                            component={FormikDatePicker}
                          />
                        </div>
                      </div>
                      <div className="grid__row">
                        <div className="grid__item -span-4">
                          <Field
                            validate={validate([required])}
                            name="spanManDays"
                            label={i18n.t('project.spanMD')}
                            component={FormikNumber}
                          />
                        </div>
                        <div className="grid__item -span-4">
                          <Field
                            validate={validate([required])}
                            name="spanMoney"
                            label={i18n.t('project.spanMoney')}
                            component={FormikNumber}
                            decimalScale={2}
                          />
                        </div>
                      </div>
                      <div className="grid__row">
                        <div className="grid__item -span-3">
                          <Field
                            label={i18n.t('project.file')}
                            component={FileInput}
                            name="file"
                            file={this.state.file}
                            onFileChange={this.handleFileChange}
                            validate={validate([required, fileSize(this.state.file)])}
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </Form>
            </section>
          </>
        )}
      </Formik>
    );
  }
}

export default AddPurchaseOrderModal;
