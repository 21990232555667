import { Form, Formik } from 'formik';
import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { UISref } from '@uirouter/react';
import getEmployeeSchema from '../../helpers/schemaHelpers';
import EmployeeFormFields from './EmployeeFormFields';
import sectionsAndPages from '../../helpers/sectionsAndPages';
import { formatPhoneNumber, cleanObject } from '../../helpers/formHelpers';
import Employee from '../../types/Employee';

class EmployeeInfo extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    employee: PropTypes.shape(Employee).isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  get isNew() {
    return !this.props.id;
  }

  get initialValues() {
    const cleanedObject = cleanObject(this.props.employee);
    if (cleanedObject.workEmail) {
      const [employeeWorkEmail] = cleanedObject.workEmail.split('@');
      cleanedObject.workEmail = employeeWorkEmail;
    }

    return { gender: 'Male', ...cleanedObject };
  }

  handleSubmit = (values, actions) => {
    this.props.onSubmit(
      {
        ...values,
        extensionNumberHundred: values.extensionNumberHundred - 1,
        mobilePhone: formatPhoneNumber(values.mobilePhone),
        workEmail: values.workEmail,
      },
      () => {
        actions.setSubmitting(false);
      }
    );
  };

  render() {
    return (
      <>
        <Formik
          initialValues={this.initialValues}
          onSubmit={this.handleSubmit}
          validateOnChange
          validateOnBlur={false}
          validationSchema={getEmployeeSchema()}
          enableReinitialize
          render={formikProps => (
            <Form className="form">
              <section className="header__row">
                <div className="grid">
                  <section className="grid__item -span-8">
                    <div className="grid">
                      <h1 className="view__title grid__item -span-2">
                        {i18n.t(this.isNew ? 'employee.new' : 'employee.edit')}
                      </h1>
                      <div className="grid__item -span-10 -align-right">
                        <UISref to={sectionsAndPages.employees}>
                          <button type="button" className="button -default">
                            {i18n.t('button.cancel')}
                          </button>
                        </UISref>
                        {this.isNew && (
                          <button className="button -primary" type="submit" disabled={formikProps.isSubmitting}>
                            {i18n.t('button.create')}
                          </button>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </section>
              <EmployeeFormFields isNew={this.isNew} {...formikProps} />
            </Form>
          )}
        />
      </>
    );
  }
}

export default EmployeeInfo;
