import _keyBy from 'lodash.keyby';
import _omit from 'lodash.omit';
import PropTypes from 'prop-types';
import { SET_CLIENTS, SET_CLIENT, UPDATE_CLIENT_ARCHIVE } from '../actions/types';
import withPropTypes from '../hoc/withPropTypes';
import Client from '../types/Client';

const schema = PropTypes.shape({
  clientsById: PropTypes.objectOf(PropTypes.exact(Client)).isRequired,
});

const initialState = {
  clientsById: {},
};

const ClientReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CLIENTS:
      return { ...state, clientsById: _keyBy(payload, 'id') };
    case SET_CLIENT:
      return { ...state, client: payload };
    case UPDATE_CLIENT_ARCHIVE:
      return { ...state, clientsById: _omit(state.clientsById, payload.id) };
    default:
      return state;
  }
};

export default withPropTypes('ClientReducer', schema)(ClientReducer);
