import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import PayFileGeneration from '../accounting/payFileGeneration/PayFileGeneration';
import { generatePayFileDocument } from '../../actions';
import { isFetchingEntities } from '../../helpers';

class PayFileGenerationPage extends React.PureComponent {
  static propTypes = {
    generatePayFileDocument: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
  };

  handleGeneratePayFileDocument = payDates => this.props.generatePayFileDocument(payDates);

  render() {
    return (
      <PayFileGeneration
        onCreatePayFileDocument={this.handleGeneratePayFileDocument}
        isFetching={this.props.isFetching}
      />
    );
  }
}

const mapStateToProps = state => ({
  isFetching: isFetchingEntities(state, ['payFileDocument']),
});

export default connect(
  mapStateToProps,
  { generatePayFileDocument }
)(PayFileGenerationPage);
