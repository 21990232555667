import React, { PureComponent } from 'react';
import Loader from '@vooban/loader';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  deleteOvertimeRequest,
  getOvertimeRequests,
  getEmployees,
  getProjectMappings,
  treatOvertimeRequest,
} from '../../actions';
import { OvertimeRequest, Employee, ProjectMapping } from '../../types';
import { isFetchingEntities } from '../../helpers';
import {
  getEmployeesSelector,
  getOvertimeRequestsSelector,
  getProjectMappingsSelector,
} from '../../selectors/selectors';
import OvertimeRequestListHeader from '../overtimeRequest/OvertimeRequestListHeader';
import OvertimeRequestList from '../overtimeRequest/OvertimeRequestList';

class OvertimeRequestListPage extends PureComponent {
  static propTypes = {
    deleteOvertimeRequest: PropTypes.func.isRequired,
    employees: PropTypes.arrayOf(PropTypes.exact(Employee)).isRequired,
    getOvertimeRequests: PropTypes.func.isRequired,
    getEmployees: PropTypes.func.isRequired,
    getProjectMappings: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    overtimeRequests: PropTypes.arrayOf(PropTypes.exact(OvertimeRequest)).isRequired,
    projects: PropTypes.arrayOf(PropTypes.exact(ProjectMapping)).isRequired,
    treatOvertimeRequest: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getOvertimeRequests();
    this.props.getEmployees();
    this.props.getProjectMappings();
  }

  handleDeleteClick = overtimeRequestId => this.props.deleteOvertimeRequest(overtimeRequestId);

  handleUpdateClick = overtimeRequest => this.props.treatOvertimeRequest(overtimeRequest);

  render() {
    if (this.props.isFetching) return <Loader />;
    return (
      <div className="grid">
        <section className="grid__item -span-12">
          <OvertimeRequestListHeader />
          <OvertimeRequestList
            overtimeRequests={this.props.overtimeRequests}
            projects={this.props.projects}
            employees={this.props.employees}
            onDeleteClick={this.handleDeleteClick}
            onUpdateClick={this.handleUpdateClick}
          />
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: isFetchingEntities(state, ['overtimeRequests', 'projectMappings', 'employees']),
  employees: getEmployeesSelector(state),
  overtimeRequests: getOvertimeRequestsSelector(state),
  projects: getProjectMappingsSelector(state),
});

export default connect(
  mapStateToProps,
  { deleteOvertimeRequest, getOvertimeRequests, getEmployees, getProjectMappings, treatOvertimeRequest }
)(OvertimeRequestListPage);
