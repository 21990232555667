export default {
  address: 'Adresse',
  billing: 'Facturation',
  billingName: 'Nom de facturation',
  billingEmail: 'Courriel de facturation',
  billingPhone: 'Téléphone de facturation',
  city: 'Ville',
  country: 'Pays',
  confirmArchive: "Voulez-vous vraiment archiver '{{clientName}}' ?",
  currency: 'Devise',
  edit: 'Modifier un client',
  name: 'Nom',
  new: 'Nouveau client',
  companyPhone: 'Téléphone de compagnie',
  province: 'Province / État',
  responsibleEmployee: 'Employé responsable',
  webiste: 'Site Internet',
  zipCode: 'Code postal',
};
