import React from 'react';
import i18n from 'i18next';
import Tooltip from '@vooban/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TimesheetListHeader = ({ isEmailToAllDisabled, onEmailToAllClick }) => (
  <>
    <h6 className="grid__heading -span-4">{i18n.t('timesheet.name')}</h6>
    <h6 className="grid__heading -span-1 -align-center">{i18n.t('timesheet.hours')}</h6>
    <h6 className="grid__heading -span-2 -align-center">{i18n.t('timesheet.submitted')}</h6>
    <h6 className="grid__heading -span-1 -align-center">{i18n.t('timesheet.approved')}</h6>
    <h6 className="grid__heading -span-1 -align-center">{i18n.t('timesheet.fifty')}</h6>
    <h6 className="grid__heading -span-1 -align-center">{i18n.t('timesheet.fourForty')}</h6>
    <h6 className="grid__heading -span-1 -align-center">{i18n.t('timesheet.extra')}</h6>
    {!isEmailToAllDisabled && (
      <h6 className="grid__heading -span-1 -align-center">
        <Tooltip content={i18n.t('timesheet.emailToAll')}>
          <button type="button" onClick={onEmailToAllClick}>
            <FontAwesomeIcon icon="envelope" className="envelope" />
          </button>
        </Tooltip>
      </h6>
    )}
  </>
);

export default TimesheetListHeader;
