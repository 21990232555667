import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { OvertimeEntry } from '../../../types';

const halfTimeMultiplier = 0.5;
const borderTopStyle = { borderTop: '1px solid black', paddingTop: '0.3rem' };

class TotalOvertime extends React.PureComponent {
  static propTypes = {
    overtimeEntry: PropTypes.exact(OvertimeEntry),
  };

  static defaultProps = {
    overtimeEntry: null,
  };

  getTotalHoursOverLimit = (fourWeeksHoursOverLimit, totalWeeklyHoursOverLimit) => {
    if (fourWeeksHoursOverLimit < totalWeeklyHoursOverLimit) return totalWeeklyHoursOverLimit;
    return fourWeeksHoursOverLimit;
  };

  getTotalWeeklyHoursOverLimit = overtimeWeeks => overtimeWeeks.reduce((sum, week) => sum + week.hoursOverLimit, 0);

  getTotalPaidWeeklyHoursOverLimit = overtimeWeeks =>
    overtimeWeeks.reduce((sum, week) => (week.isPaid ? sum + week.hoursOverLimit : sum), 0);

  render() {
    const { fourWeeksHoursOverLimit, overtimeWeeks, totalUnpaidExtra } = this.props.overtimeEntry;

    const totalWeeklyHoursOverLimit = this.getTotalWeeklyHoursOverLimit(overtimeWeeks);
    const totalPaidWeeklyHoursOverLimit = this.getTotalPaidWeeklyHoursOverLimit(overtimeWeeks);
    const totalHoursOverLimit = this.getTotalHoursOverLimit(fourWeeksHoursOverLimit, totalWeeklyHoursOverLimit);

    return (
      <div className="grid__item -span-10">
        <div className="tile">
          <section className="grid">
            <div className="grid__item -span-12">
              {/* FIXME: Create new css classes instead of overriding this one */}
              <section className="menu__content" style={{ borderTop: '0rem' }}>
                <h4 className="menu__title" style={{ margin: '0rem' }}>
                  {i18n.t('overtime.totalOvertimeRule')}
                </h4>
                <span>{i18n.t('overtime.totalOvertimeRuleExplanation')}</span>
              </section>
            </div>

            <article className="grid__row">
              <span className="grid__item -span-2 -align-right">{i18n.t('overtime.total')}</span>
              <span className="grid__item -span-6" />
              <span className="grid__item -span-2 -align-center">{(totalHoursOverLimit * 0.5).toFixed(2)}</span>
              <span className="grid__item -span-2" />
            </article>
            <article className="grid__row">
              <span className="grid__item -span-2 -align-right">{i18n.t('overtime.alreadyPaid')}</span>
              <span className="grid__item -span-6" />
              <span className="grid__item -span-2 -align-center">
                {`(${(totalPaidWeeklyHoursOverLimit * halfTimeMultiplier).toFixed(2)})`}
              </span>
              <span className="grid__item -span-2" />
            </article>
            <article className="grid__row">
              <span className="grid__item -span-2 -align-right">{i18n.t('overtime.totalUnpaid')}</span>
              <span className="grid__item -span-6" />
              <span className="grid__item -span-2 -align-center" style={borderTopStyle}>
                {totalUnpaidExtra.toFixed(2)}
              </span>
              <span className="grid__item -span-2" />
            </article>
          </section>
        </div>
      </div>
    );
  }
}

export default TotalOvertime;
