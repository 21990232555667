import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';

import { Employee } from '../../types';

const TeamMemberListRow = ({ teamMember, onDeleteClick }) => (
  <article className="grid__row" key={teamMember.id}>
    <span className="grid__item -span-4">{`${teamMember.firstName} ${teamMember.lastName}`}</span>
    <span className="grid__item -span-6">{teamMember.workEmail}</span>
    <span className="grid__item -span-2">
      <button type="button" className="button__icon" onClick={onDeleteClick}>
        <FontAwesomeIcon icon="trash-alt" />
      </button>
    </span>
  </article>
);

TeamMemberListRow.propTypes = {
  onDeleteClick: PropTypes.func.isRequired,
  teamMember: PropTypes.exact(Employee).isRequired,
};

export default TeamMemberListRow;
