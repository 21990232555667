import axios from 'axios';
import { pushModalMessageError } from '.';
import { startFetching, stopFetching, pushToastMessage } from './appActions';

import { REMOVE_CONTACT, SET_CONTACT, SET_CONTACTS } from './types';

export const getContactById = contactId => async dispatch => {
  dispatch(startFetching('contactById'));
  try {
    const response = await axios.get(`/contacts/${contactId}`);
    dispatch({ type: SET_CONTACT, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('contactById'));
  }
};

export const getContactsWithProjectId = projectId => async dispatch => {
  dispatch(startFetching('contactsWithProjectId'));
  try {
    const response = await axios.get(`/contacts/project/${projectId}`);
    dispatch({ type: SET_CONTACTS, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('contactsWithProjectId'));
  }
};

export const createContact = contact => async dispatch => {
  dispatch(startFetching('contact'));
  try {
    const response = await axios.post(`/contacts`, contact);
    dispatch({ type: SET_CONTACT, payload: response.data });
    dispatch(pushToastMessage('contactCreated'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('contact'));
  }
};

export const deleteContact = contact => async dispatch => {
  try {
    await axios.delete(`/contacts/${contact.id}`);
    dispatch({ type: REMOVE_CONTACT, payload: contact.id });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  }
};

export const updateContact = contact => async dispatch => {
  dispatch(startFetching('contact'));
  try {
    const response = await axios.put(`/contacts/${contact.id}`, contact);
    dispatch({ type: SET_CONTACT, payload: response.data });
    dispatch(pushToastMessage('contactUpdated'));
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('contact'));
  }
};

export const getContactsByClientId = clientId => async dispatch => {
  dispatch(startFetching('contacts'));
  try {
    const response = await axios.get(`/contacts/client/${clientId}`);
    dispatch({ type: SET_CONTACTS, payload: response.data });
  } catch (error) {
    dispatch(pushModalMessageError(error));
  } finally {
    dispatch(stopFetching('contacts'));
  }
};
