export default {
  action: 'Action',
  addFile: 'Ajouter un document',
  addPurchaseOrder: 'Ajouter un bon de commande',
  addTask: 'Ajouter une tâche',
  association: 'Association',
  associatedHarvestProjects: 'Projets Harvest associés',
  associatedJiraProject: 'Projet Jira associé',
  billable: 'Facturable?',
  billingType: 'Type de facturation',
  billingTypeFixed: 'Fixe',
  billingTypeProjectHourly: 'Taux par projet',
  billingTypeTaskHourly: 'Taux par tâche',
  budget: 'Budget',
  code: 'Code',
  confirmRemoveMapping: "Désirez-vous réellement supprimer le projet '{{projectName}}'?",
  confirmRemovePurchaseOrder: "Désirez-vous réellement supprimer le bon de commande '{{purchaseOrderNumber}}'?",
  contact: 'Contact',
  contactEmail: 'Courriel',
  contactName: 'Nom / Département',
  contactTelephone: 'Téléphone',
  customer: 'Client',
  delete: 'Supprimer le projet',
  desjardinsCode: 'Code Desjardins',
  description: 'Description',
  effortInDays: 'Effort en jours',
  empty: 'Veuillez cliquer sur Créer pour ajouter un nouveau projet.',
  endDate: 'Date de fin',
  file: 'Fichier',
  fileName: 'Nom du fichier',
  jiraProject: 'Projet Jira',
  jiraProjects: 'Projets Jira',
  harvestProject: 'Project harvest',
  hourlyRate: 'Taux horaire',
  information: 'Information',
  maxBudgetDollars: 'Budget maximum',
  minBudgetDollars: 'Budget minimum',
  name: 'Nom du projet',
  new: 'Nouveau projet',
  newTempoAccount: 'Nouveau compte Tempo',
  poNumber: 'Numéro',
  poDate: 'Date',
  poStartDate: 'Date de début',
  poEndDate: 'Date de fin',
  projectCode: 'Code du project',
  projectManager: 'Gestionnaire',
  projectStatus: 'Status',
  projectStatusDone: 'Terminé',
  projectStatusInProgress: 'En cours',
  projectStatusNegotiating: 'Négociation',
  spanMD: 'Envergure (Jour-personnes)',
  spanMoney: 'Envergure ($)',
  startDate: 'Date de début',
  status: 'Statut',
  actions: 'Actions',
  task: 'Tâche',
  tempoAccount: 'Compte Tempo',
  newPurchaseOrder: 'Nouveau bon de commande',
  general: 'Général',
};
