import Loader from '@vooban/loader';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  addTeamMember,
  deleteTeam,
  getEmployees,
  getProjectMappings,
  getTeamMembers,
  getTeam,
  removeTeamMember,
  returnToProject,
  updateTeam,
} from '../../actions';
import {
  getEmployeesSelector,
  getOrderedTeamMembersSelector,
  getProjectMappingsSelector,
} from '../../selectors/selectors';
import { isFetchingEntities } from '../../helpers';
import { Employee, Team, ProjectMapping } from '../../types';
import TeamMemberList from '../team/TeamMemberList';
import TeamDetails from '../team/TeamDetails';

class TeamDetailsPage extends React.PureComponent {
  static propTypes = {
    addTeamMember: PropTypes.func.isRequired,
    deleteTeam: PropTypes.func.isRequired,
    employees: PropTypes.arrayOf(PropTypes.exact(Employee)).isRequired,
    getEmployees: PropTypes.func.isRequired,
    getProjectMappings: PropTypes.func.isRequired,
    projectMappings: PropTypes.arrayOf(PropTypes.exact(ProjectMapping)).isRequired,
    removeTeamMember: PropTypes.func.isRequired,
    getTeamMembers: PropTypes.func.isRequired,
    getTeam: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    teamMembers: PropTypes.arrayOf(PropTypes.exact(Employee)).isRequired,
    updateTeam: PropTypes.func.isRequired,
    team: PropTypes.exact(Team),
    teamId: PropTypes.string,
    returnToProject: PropTypes.func.isRequired,
  };

  static defaultProps = {
    teamId: '',
    team: {},
  };

  componentDidMount() {
    this.props.getEmployees();
    this.props.getTeam(this.props.teamId);
    this.props.getTeamMembers(this.props.teamId);
    this.props.getProjectMappings();
  }

  get unassignedEmployees() {
    const { employees, teamMembers } = this.props;
    const teamMembersEmployeeIds = teamMembers.map(({ id }) => id);

    return employees.filter(e => !teamMembersEmployeeIds.includes(e.id));
  }

  getAssociatedProjectMapping = () => this.props.projectMappings.find(x => x.id === this.props.team.projectMappingId);

  handleDeleteTeamClick = team => this.props.deleteTeam(team, this.getAssociatedProjectMapping());

  handleUpdateTeamClick = team => this.props.updateTeam(team);

  handleAddTeamMemberClick = employee => this.props.addTeamMember(this.props.team, employee);

  handleRemoveTeamMemberClick = teamMember =>
    this.props.removeTeamMember(this.props.team, teamMember, this.getAssociatedProjectMapping());

  handleReturnToProjectClick = projectId => this.props.returnToProject(projectId);

  render() {
    if (this.props.isFetching) return <Loader />;

    return (
      <>
        <TeamDetails
          onAddTeamMemberClick={this.handleAddTeamMemberClick}
          onDeleteTeamClick={this.handleDeleteTeamClick}
          onUpdateTeamClick={this.handleUpdateTeamClick}
          team={this.props.team}
          employees={this.props.employees}
          projectMapping={this.getAssociatedProjectMapping()}
          unassignedEmployees={this.unassignedEmployees}
          onReturnToProjectClick={this.handleReturnToProjectClick}
        />
        <TeamMemberList
          onAddTeamMemberClick={this.handleAddTeamMemberClick}
          onRemoveTeamMemberClick={this.handleRemoveTeamMemberClick}
          team={this.props.team}
          teamMembers={this.props.teamMembers}
          unassignedEmployees={this.unassignedEmployees}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const { teamId } = state.app.routerParams;

  return {
    employees: getEmployeesSelector(state),
    isFetching: isFetchingEntities(state, ['employees', 'team']),
    projectMappings: getProjectMappingsSelector(state),
    teamMembers: getOrderedTeamMembersSelector(state),
    team: state.team.teamsById[teamId],
    teamId,
  };
};

export default connect(
  mapStateToProps,
  {
    addTeamMember,
    deleteTeam,
    getEmployees,
    getProjectMappings,
    getTeamMembers,
    getTeam,
    removeTeamMember,
    returnToProject,
    updateTeam,
  }
)(TeamDetailsPage);
