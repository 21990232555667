import Loader from '@vooban/loader';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { ConfirmModal } from '@vooban/modals';
import moment from 'moment';
import { HarvestTimesheet, HarvestWeek, OvertimeEntry } from '../../../types';
import TimesheetListItem from './TimesheetListItem';
import TimesheetForm from './TimesheetForm';
import TimesheetListHeader from './TimesheetListHeader';

class TimesheetList extends React.PureComponent {
  static propTypes = {
    onRefreshClick: PropTypes.func.isRequired,
    harvestWeeksByEmail: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.exact(HarvestWeek))).isRequired,
    isFetching: PropTypes.bool.isRequired,
    onWeekStartDateChange: PropTypes.func.isRequired,
    onSendTimesheetReminder: PropTypes.func.isRequired,
    overtimeEntries: PropTypes.arrayOf(PropTypes.exact(OvertimeEntry)).isRequired,
    timesheets: PropTypes.arrayOf(PropTypes.exact(HarvestTimesheet)).isRequired,
    weekStartDate: PropTypes.string.isRequired,
    weekStartDates: PropTypes.arrayOf(PropTypes.string).isRequired,
    userName: PropTypes.string.isRequired,
  };

  state = { shouldHideApprovedTimesheets: false };

  confirmationModal = React.createRef();

  get isEmailToAllDisabled() {
    return this.unsubmittedTimesheets.length === 0;
  }

  get unsubmittedTimesheets() {
    return this.props.timesheets.filter(timesheet => this.doesTimesheetExistAndIsUnsubmitted(timesheet));
  }

  doesTimesheetExistAndIsUnsubmitted = timesheet => {
    const harvestWeek = this.props.harvestWeeksByEmail[timesheet.userEmail].find(
      w => this.formatDate(w.startDate) === this.props.weekStartDate
    );

    return harvestWeek && !harvestWeek.isSubmitted;
  };

  formatDate = date => moment.utc(date).format('YYYY-MM-DD');

  handleToggleApprovedSheets = () => {
    this.setState(state => ({ shouldHideApprovedTimesheets: !state.shouldHideApprovedTimesheets }));
  };

  handleSendEmailToAll = () => {
    const harvestWeek = this.props.harvestWeeksByEmail[this.unsubmittedTimesheets[0].userEmail].find(
      w => this.formatDate(w.startDate) === this.props.weekStartDate
    );

    const emailInfo = this.unsubmittedTimesheets.map(timesheet => ({
      employeeEmail: timesheet.userEmail,
      employeeName: timesheet.userName,
      timesheetWeek: this.formatDate(harvestWeek.startDate),
      administrator: this.props.userName,
    }));

    this.props.onSendTimesheetReminder(emailInfo);
  };

  handleEmailToAllClick = () => this.confirmationModal.current.open();

  handleRefreshClick = () => this.props.onRefreshClick();

  render() {
    return (
      <>
        {this.props.isFetching && <Loader />}
        <div className="grid">
          <aside className="grid__item -span-6">
            <h1 className="view__title">{i18n.t('title.timesheets')}</h1>
          </aside>
        </div>
        <TimesheetForm
          weekStartDate={this.props.weekStartDate}
          weekStartDates={this.props.weekStartDates}
          onWeekStartDateChange={this.props.onWeekStartDateChange}
          onToggleApprovedSheets={this.handleToggleApprovedSheets}
          shouldHideApprovedTimesheets={this.state.shouldHideApprovedTimesheets}
          onRefreshClick={this.handleRefreshClick}
        />
        <div className="grid">
          <div className="grid__item -span-10">
            <div className="tile">
              <section className="grid">
                <TimesheetListHeader
                  isEmailToAllDisabled={this.isEmailToAllDisabled}
                  onEmailToAllClick={this.handleEmailToAllClick}
                />

                {this.props.timesheets.map(timesheet => {
                  const harvestWeek = this.props.harvestWeeksByEmail[timesheet.userEmail].find(
                    w => this.formatDate(w.startDate) === this.props.weekStartDate
                  );

                  return (
                    <TimesheetListItem
                      key={timesheet.id}
                      timesheet={timesheet}
                      harvestWeek={harvestWeek}
                      weekStartDate={this.props.weekStartDate}
                      formatDate={this.formatDate}
                      shouldHideApprovedTimesheets={this.state.shouldHideApprovedTimesheets}
                      overtimeEntries={this.props.overtimeEntries}
                      onSendTimesheetReminder={this.props.onSendTimesheetReminder}
                      userName={this.props.userName}
                    />
                  );
                })}
              </section>
            </div>
          </div>
        </div>
        <ConfirmModal
          ref={this.confirmationModal}
          onConfirm={this.handleSendEmailToAll}
          title={i18n.t('timesheet.confirmSendToAll')}
          cancelLabel={i18n.t('button.no')}
          confirmLabel={i18n.t('button.yes')}
        />
      </>
    );
  }
}

export default TimesheetList;
