import PropTypes from 'prop-types';

const HarvestWeek = {
  startDate: PropTypes.string,
  hours: PropTypes.number,
  isApproved: PropTypes.bool,
  isSubmitted: PropTypes.bool,
};

export default HarvestWeek;
