import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from '@vooban/loader';
import i18n from 'i18next';
import _isEmpty from 'lodash.isempty';
import { getProjectWithJiraProject, getTimeComparisons, sendUnbalancingTimeWarningEmail } from '../../actions';
import { getProjectMappingsSelector, getTimeComparisonsSelector } from '../../selectors/selectors';
import { isFetchingEntities } from '../../helpers';
import { ProjectMapping, User } from '../../types';
import TimeComparisonType from '../../types/TimeComparison';
import TimeComparisonList from '../reporting/timeComparison/TimeComparisonList';
import TimeComparisonForm from '../reporting/timeComparison/TimeComparisonForm';

class TimeComparisonPage extends React.PureComponent {
  static propTypes = {
    comparedProjectName: PropTypes.string.isRequired,
    getProjects: PropTypes.func.isRequired,
    getTimeComparisons: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    projectMappings: PropTypes.arrayOf(PropTypes.exact(ProjectMapping)).isRequired,
    sendUnbalancingTimeWarningEmail: PropTypes.func.isRequired,
    timeComparisons: PropTypes.arrayOf(PropTypes.exact(TimeComparisonType)).isRequired,
    user: PropTypes.shape(User).isRequired,
  };

  componentDidMount() {
    this.props.getProjects();
  }

  handleSubmit = comparisonRequest => this.props.getTimeComparisons(comparisonRequest);

  handleSendEmail = emailInfo => this.props.sendUnbalancingTimeWarningEmail(emailInfo);

  render() {
    if (this.props.isFetching) return <Loader />;

    return (
      <div className="grid">
        <aside className="grid__item -span-6">
          <h1 className="view__title ">{i18n.t('title.timeComparisons')}</h1>
        </aside>
        <div className="grid__row">
          <TimeComparisonForm onSubmit={this.handleSubmit} projectMappings={this.props.projectMappings} />
        </div>
        {!_isEmpty(this.props.timeComparisons) && (
          <div className="grid__row">
            <TimeComparisonList
              administrator={this.props.user}
              comparedProjectName={this.props.comparedProjectName}
              onSendEmail={this.handleSendEmail}
              timeComparisons={this.props.timeComparisons}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  comparedProjectName: state.reporting.comparedProjectName,
  timeComparisons: getTimeComparisonsSelector(state),
  projectMappings: getProjectMappingsSelector(state),
  isFetching: isFetchingEntities(state, ['timeComparisons', 'projectMappings']),
  user: state.app.auth.user,
});

export default connect(
  mapStateToProps,
  { getProjects: getProjectWithJiraProject, getTimeComparisons, sendUnbalancingTimeWarningEmail }
)(TimeComparisonPage);
