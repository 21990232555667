export default {
  addEmployee: 'Ajouter un employé',
  department: 'Département',
  edit: 'Modifier',
  email: 'Courriel',
  emailAlreadyExists: 'Ce courriel de travail existe déjà. Veuillez en choisir un autre.',
  female: 'Femme',
  firstName: 'Prénom',
  gender: 'Sexe',
  hireDate: "Date d'embauche",
  homeEmail: 'Courriel personnel',
  extensionRange: "Portée de l'extension téléphonique",
  lastName: 'Nom de famille',
  id: 'ID',
  male: 'Homme',
  mobilePhone: 'Numéro de téléphone mobile',
  name: 'Nom',
  workPhone: 'Téléphone de travail',
  extension: 'Extension',
  mobile: 'Téléphone cellulaire',
  new: 'Nouvel employé',
  other: 'Autre',
  password: 'Mot de passe',
  salary: 'Salaire',
  workEmail: 'Courriel de travail',
};
