import {
  FormikDatePicker,
  FormikMoney,
  FormikMultiSelect,
  FormikNumber,
  FormikSelect,
  FormikText,
  FormikCheckbox,
  FormikTextarea,
} from '@vooban/formik-inputs';
import _orderBy from 'lodash.orderby';
import _isEqual from 'lodash.isequal';
import { Field, FastField, Formik, Form } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { validate, required, minDate, maxDate, maxAmount } from '../../helpers/formHelpers';
import { HarvestProject, JiraProject, ProjectMapping, Employee, TempoAccount, Client } from '../../types';
import { addDays } from '../../helpers/dateHelpers';
import TaskList from './task/TaskList';
import HarvestTask from '../../types/HarvestTask';
import Can from '../ability/Can';
import commonActions from '../../helpers/authorization/abilityActions';
import sectionsAndPages from '../../helpers/sectionsAndPages';

class ProjectDetails extends React.PureComponent {
  static propTypes = {
    harvestProjects: PropTypes.arrayOf(PropTypes.exact(HarvestProject)).isRequired,
    harvestTasks: PropTypes.arrayOf(PropTypes.exact(HarvestTask)).isRequired,
    jiraProjects: PropTypes.arrayOf(PropTypes.exact(JiraProject)).isRequired,
    associatedJiraProjectIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    projectMapping: PropTypes.exact(ProjectMapping).isRequired,
    employees: PropTypes.arrayOf(PropTypes.exact(Employee)).isRequired,
    clients: PropTypes.arrayOf(PropTypes.exact(Client)).isRequired,
    tempoAccounts: PropTypes.arrayOf(PropTypes.shape(TempoAccount)).isRequired,
    onSave: PropTypes.func.isRequired,
  };

  getInitialJiraIds = () => this.props.projectMapping.jiraProjects.map(jp => jp.id);

  getUnassociatedJiraProjects = () => {
    const initialJiraIds = this.getInitialJiraIds();

    const unassociatedJiraProjects = this.props.jiraProjects.filter(
      ({ id }) => !this.props.associatedJiraProjectIds.includes(id) || initialJiraIds.includes(id)
    );
    return _orderBy(unassociatedJiraProjects, [project => project.name.toLowerCase()]);
  };

  handleBillingTypeChange = setFieldValue => {
    setFieldValue('projectTasks', []);
    setFieldValue('hourlyRate', null);
  };

  handleSaveClick = values => {
    const projectTasks = values.projectTasks ? values.projectTasks.filter(value => value.harvestTaskId) : undefined;
    const cleanedValues = { ...values, projectTasks };
    const jiraProjects = cleanedValues.jiraIds.map(id => {
      const jiraProject = this.props.jiraProjects.find(jp => jp.id === id) || {};
      return { id: jiraProject.id, key: jiraProject.key };
    });

    const { jiraIds, ...projectMappingWithoutTempFormFields } = cleanedValues;
    const projectMapping = { ...projectMappingWithoutTempFormFields, jiraProjects };
    this.props.onSave(projectMapping);
  };

  ensureLastRowIsBlank = (arrayHelpersPush, formArray, objectShapeToInsert) => {
    if (!_isEqual(formArray[formArray.length - 1], objectShapeToInsert)) {
      arrayHelpersPush(objectShapeToInsert);
    }
  };

  render() {
    const employeesOptions = this.props.employees.map(employee => ({
      ...employee,
      fullName: `${employee.firstName} ${employee.lastName}`,
    }));

    const clientsOptions = this.props.clients.map(client => ({
      key: client.id,
      label: client.name,
      value: client.id,
    }));

    const billingTypeOptions = [
      { label: i18n.t('project.billingTypeFixed'), value: 'FIXED' },
      { label: i18n.t('project.billingTypeProjectHourly'), value: 'HOURLY' },
      { label: i18n.t('project.billingTypeTaskHourly'), value: 'TASK_HOURLY' },
    ];

    const projectStatusOptions = [
      { label: i18n.t('project.projectStatusNegotiating'), value: 'NEGOTIATING' },
      { label: i18n.t('project.projectStatusInProgress'), value: 'IN_PROGRESS' },
      { label: i18n.t('project.projectStatusDone'), value: 'DONE' },
    ];

    return (
      <div className="tile -no-top">
        <Formik
          initialValues={{
            ...this.props.projectMapping,
            jiraIds: this.getInitialJiraIds(),
          }}
          onSubmit={this.handleSaveClick}
          enableReinitialize>
          {({ setFieldValue, values }) => (
            <Form className="grid__item -span-8">
              <header className="tile__header">
                <h3 className="view__subtitle">{i18n.t('project.general')}</h3>
              </header>
              <section className="form__section grid">
                <div className="grid__row">
                  <FastField
                    validate={validate([required])}
                    className="grid__item -span-6 -input"
                    component={FormikText}
                    name="name"
                    label={i18n.t('project.name')}
                  />
                </div>
                <div className="grid__item -span-4">
                  <FastField
                    validate={validate([required])}
                    labelKey="label"
                    valueKey="value"
                    className="grid__item -span-4 -input"
                    component={FormikSelect}
                    name="status"
                    options={_orderBy(projectStatusOptions, [status => status.label.toLowerCase()], ['ASC'])}
                    label={i18n.t('project.projectStatus')}
                  />
                </div>
                <div className="grid__row">
                  <div className="grid__item -span-2">
                    <Field name="isBillable" component={FormikCheckbox} label={i18n.t('project.billable')} />
                  </div>
                </div>
                <div className="grid__row">
                  <FastField
                    className="grid__item -span-6 -input"
                    component={FormikText}
                    name="code"
                    label={i18n.t('project.projectCode')}
                  />
                  <FastField
                    className="grid__item -span-6 -input"
                    component={FormikText}
                    name="desjardinsCode"
                    label={i18n.t('project.desjardinsCode')}
                  />
                </div>
                <div className="grid__row">
                  <FastField
                    className="grid__item -span-12 -input"
                    component={FormikTextarea}
                    name="description"
                    label={i18n.t('project.description')}
                  />
                </div>
                <div className="grid__row">
                  <div className="grid__item -span-6">
                    <Field
                      component={FormikDatePicker}
                      name="startDate"
                      validate={validate([required, maxDate(addDays(values.endDate, -1))])}
                      label={i18n.t('project.startDate')}
                    />
                  </div>
                  <div className="grid__item -span-6">
                    <Field
                      component={FormikDatePicker}
                      name="endDate"
                      validate={validate([required, minDate(addDays(values.startDate, 1))])}
                      label={i18n.t('project.endDate')}
                    />
                  </div>
                </div>
                <div className="grid__row">
                  <h6 className="grid__heading -project-section">{i18n.t('project.association')}</h6>
                </div>
                <div className="grid__row">
                  <div className="grid__item -span-6">
                    <Field
                      labelKey="name"
                      valueKey="id"
                      name="jiraIds"
                      label={i18n.t('project.associatedJiraProject')}
                      component={FormikMultiSelect}
                      options={this.getUnassociatedJiraProjects()}
                    />
                  </div>
                  <div className="grid__item -span-6">
                    <Field
                      name="harvestIds"
                      labelKey="name"
                      valueKey="id"
                      component={FormikMultiSelect}
                      options={_orderBy(this.props.harvestProjects, [project => project.name.toLowerCase()], ['ASC'])}
                      label={i18n.t('project.associatedHarvestProjects')}
                    />
                  </div>
                </div>
                <div className="grid__row">
                  <div className="grid__item -span-6">
                    <Field
                      name="tempoAccountKey"
                      labelKey="name"
                      valueKey="key"
                      component={FormikSelect}
                      options={_orderBy(this.props.tempoAccounts, [tempAcc => tempAcc.name.toLowerCase()], ['ASC'])}
                      label={i18n.t('project.tempoAccount')}
                      placeholder={i18n.t('project.newTempoAccount')}
                    />
                  </div>
                  <div className="grid__item -span-6">
                    <Field
                      name="projectManagerId"
                      labelKey="fullName"
                      valueKey="id"
                      component={FormikSelect}
                      options={_orderBy(employeesOptions, [empOpt => empOpt.fullName.toLowerCase()], ['ASC'])}
                      label={i18n.t('project.projectManager')}
                      validate={validate([required])}
                    />
                  </div>
                </div>
                <div className="grid__row">
                  <div className="grid__item -span-6">
                    <Field
                      name="clientId"
                      labelKey="label"
                      valueKey="value"
                      component={FormikSelect}
                      validate={validate([required])}
                      label={i18n.t('project.customer')}
                      options={_orderBy(clientsOptions, [client => client.label.toLowerCase()], ['ASC'])}
                    />
                  </div>
                </div>
                <div className="grid__row">
                  <h6 className="grid__heading -project-section">{i18n.t('project.budget')}</h6>
                </div>
                <div className="grid__row">
                  <FastField
                    className="grid__item -span-4 -input"
                    component={FormikNumber}
                    name="effortInDays"
                    label={i18n.t('project.effortInDays')}
                  />
                  <Field
                    className="grid__item -span-4 -input"
                    component={FormikMoney}
                    name="minEffortInDollars"
                    label={i18n.t('project.minBudgetDollars')}
                  />
                  <Field
                    className="grid__item -span-4 -input"
                    component={FormikMoney}
                    name="maxEffortInDollars"
                    label={i18n.t('project.maxBudgetDollars')}
                    validate={validate([maxAmount(values.minEffortInDollars)])}
                  />
                </div>
                <div className="grid__row">
                  <FastField
                    className="grid__item -span-4 -input"
                    labelKey="label"
                    valueKey="value"
                    component={FormikSelect}
                    onChange={() => this.handleBillingTypeChange(setFieldValue)}
                    name="billingType"
                    options={_orderBy(billingTypeOptions, [bTO => bTO.label.toLowerCase()], ['ASC'])}
                    label={i18n.t('project.billingType')}
                  />

                  <Can I={commonActions.getProjectListCriticalData} a={sectionsAndPages.projectDetails}>
                    {values.billingType === billingTypeOptions[1].value && (
                      <FastField
                        className="grid__item -span-4 -input"
                        component={FormikMoney}
                        name="hourlyRate"
                        label={i18n.t('project.hourlyRate')}
                      />
                    )}
                  </Can>
                </div>
                <Can I={commonActions.getProjectListCriticalData} a={sectionsAndPages.projectDetails}>
                  {values.billingType === billingTypeOptions[2].value && (
                    <div className="grid__row">
                      <TaskList
                        projectTasks={values.projectTasks}
                        harvestTasks={this.props.harvestTasks}
                        projectId={values.id}
                      />
                    </div>
                  )}
                </Can>
                <div className="grid__row">
                  <div className="grid__item -span-2">
                    <button type="submit" className="button -primary">
                      {i18n.t('button.save')}
                    </button>
                  </div>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default ProjectDetails;
