import PropTypes from 'prop-types';

const OvertimeWeek = {
  hours: PropTypes.number,
  hoursOverLimit: PropTypes.number,
  isPaid: PropTypes.bool,
  startDate: PropTypes.string,
  unpaidExtra: PropTypes.number,
};

export default OvertimeWeek;
