import PropTypes from 'prop-types';

const UploadFeedback = {
  employeeId: PropTypes.number,
  employeeName: PropTypes.string,
  employeeEmail: PropTypes.string,
  fileName: PropTypes.string,
  isSuccess: PropTypes.bool,
};

export default UploadFeedback;
