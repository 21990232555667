import PropTypes from 'prop-types';
import QueryIssueKey from './QueryIssueKey';

const SpentOnIssueQuery = {
  id: PropTypes.number,
  email: PropTypes.string,
  name: PropTypes.string,
  queryIssueKeys: PropTypes.arrayOf(PropTypes.exact(QueryIssueKey)),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default SpentOnIssueQuery;
