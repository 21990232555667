export default {
  actions: 'Actions',
  addMember: 'Add Member',
  confirmRemoveTeam: "Do you really wish to delete the '{{teamName}}' team?",
  confirmRemoveTeamMember: "Do you really wish to remove '{{memberName}}' from the '{{teamName}}' team?",
  createTeam: 'Create Team',
  deleteTeam: 'Delete Team',
  email: 'Email',
  employee: 'Employee',
  employees: 'Employees',
  id: 'Id',
  project: 'Project',
  name: 'Name',
  newTeam: 'New Team',
  newTeamMember: 'New Team Member',
  team: 'Team',
  teamLead: 'Team Lead',
  teamMembers: 'Team Members',
  returnToProject: 'Return to project',
};
