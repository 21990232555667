import React from 'react';
import ReactDOM from 'react-dom';

import './styles/app.scss';
import Root from './Root';
import injectSpritesheet from './helpers/spriteLoader';
import { getUser } from './helpers/authorization/authorizationHelpers';
import Auth from './helpers/authorization/auth';

const auth = new Auth();
injectSpritesheet('/static/icons.svg').catch();

window.addEventListener('load', () => {
  if (window.location.pathname.startsWith('/callback')) {
    auth.handleAuthentication();
  } else if (!Auth.isAuthenticated()) {
    window.localStorage.setItem('requestedURL', window.location.href);
    auth.login();
  } else {
    ReactDOM.render(
      <>
        <Root user={getUser()} />
      </>,
      document.getElementById('root')
    );
  }
});
