const commonActions = {
  approve: 'Approve',
  create: 'Create',
  getFullProjectDetails: 'GetFullProjectDetails',
  decline: 'Decline',
  delete: 'Delete',
  get: 'Get',
  getList: 'GetList',
  getPurchaseOrderCriticalData: 'GetPurchaseOrderCriticalData',
  getProjectListCriticalData: 'getProjectListCriticalData',
  navigateTo: 'NavigateTo',
  update: 'Update',
};

export default commonActions;
