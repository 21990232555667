import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

class fileInput extends React.PureComponent {
  static propTypes = {
    field: PropTypes.shape({ name: PropTypes.string }).isRequired,
    form: PropTypes.shape({
      setFieldValue: PropTypes.func,
      setFieldTouched: PropTypes.func,
      submitCount: PropTypes.number,
      errors: PropTypes.shape(),
      touched: PropTypes.shape(),
    }).isRequired,
    label: PropTypes.string.isRequired,
    onFileChange: PropTypes.func.isRequired,
    showLabel: PropTypes.bool,
    file: PropTypes.shape(),
  };

  static defaultProps = {
    showLabel: true,
    file: null,
  };

  state = { file: null };

  handleChange = e => {
    const file = e.currentTarget.files[0];
    this.setState({ file });
    this.props.onFileChange(file);
    this.props.form.setFieldTouched(this.props.field.name, true);
    this.props.form.setFieldValue(this.props.field.name, file ? file.name : '');
  };

  render() {
    const error = this.props.form.errors[this.props.field.name];
    const touched = !!this.props.form.touched[this.props.field.name];

    return (
      <div className="input">
        {this.props.showLabel && (
          <label className="input__label" htmlFor={this.props.field.name}>
            {this.props.label ? `${this.props.label} - ` : ''}
            {this.props.file ? this.state.file.name : i18n.t('file.noChosen')}
          </label>
        )}

        <div className="row -align-bottom -flex-children">
          <label htmlFor={this.props.field.name} className="button -primary">
            <input
              id={this.props.field.name}
              type="file"
              className="input__file"
              onChange={this.handleChange}
              name={this.props.field.name}
            />
            {i18n.t('file.choose')}
          </label>
        </div>
        {!!error && (touched || this.props.form.submitCount > 0) && (
          <span className="input__message -error">{error}</span>
        )}
      </div>
    );
  }
}

export default fileInput;
