export default {
  allIn: 'Tout-en-un',
  bambooHrBalance: 'Balance BambooHr',
  convert: 'Convertir',
  convertedFileName: 'Nom du fichier converti',
  confirmAllIn: 'Désirez-vous réellement utiliser la fonctionnality tout-en-un ?',
  difference: 'Différence',
  employeeNumber: "Numéro d'employé",
  file: 'Fichier',
  fileName: 'Nom du fichier',
  manualProcess: 'Traitement Manuel',
  name: 'Nom',
  payFileBalance: 'Balance fichier paie',
  payNumber: 'Numéro de paie',
  process: 'Traitement',
  processTimeOff: 'Traitement des banques de temps',
  success: 'Succès',
  timeOffType: 'Type banque de temps',
  update: 'Mettre à jour',
  year: 'Année',
};
