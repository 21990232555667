import _orderBy from 'lodash.orderby';
import { ConfirmModal } from '@vooban/modals';
import React, { PureComponent } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { OvertimeRequest, OvertimeRequestStatus, Employee, ProjectMapping } from '../../types';
import OvertimeRequestListRow from './OvertimeRequestListRow';

class OvertimeRequestList extends PureComponent {
  static propTypes = {
    employees: PropTypes.arrayOf(PropTypes.exact(Employee)).isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onUpdateClick: PropTypes.func.isRequired,
    overtimeRequests: PropTypes.arrayOf(PropTypes.exact(OvertimeRequest)).isRequired,
    projects: PropTypes.arrayOf(PropTypes.exact(ProjectMapping)).isRequired,
  };

  state = {
    overtimeRequestToDeleteId: null,
  };

  overtimeRequestDeletionModal = React.createRef();

  handleSetOvertimeRequestToDelete = overtimeRequestToDeleteId => this.setState({ overtimeRequestToDeleteId });

  handleDeleteOvertimeRequestClick = () => this.props.onDeleteClick(this.state.overtimeRequestToDeleteId);

  handleApproveOvertimeRequest = overtimeRequestToUpdate => {
    const overtimeRequest = { ...overtimeRequestToUpdate, status: OvertimeRequestStatus.approved };
    this.props.onUpdateClick(overtimeRequest);
  };

  handleDeclineOvertimeRequest = overtimeRequestToUpdate => {
    const overtimeRequest = { ...overtimeRequestToUpdate, status: OvertimeRequestStatus.declined };
    this.props.onUpdateClick(overtimeRequest);
  };

  getAssociatedProjectName = projectId => {
    const associatedProject = this.props.projects.find(x => x.id === projectId);
    if (!associatedProject) return '';
    return associatedProject.name;
  };

  getAssociatedEmployeeName = applicantId => {
    const associatedEmployee = this.props.employees.find(x => x.id === applicantId);
    if (!associatedEmployee) return '';
    return `${associatedEmployee.firstName} ${associatedEmployee.lastName}`;
  };

  openOvertimeRequestDeletionModal = overtimeRequestToDeleteId => {
    this.handleSetOvertimeRequestToDelete(overtimeRequestToDeleteId);
    this.overtimeRequestDeletionModal.current.open();
  };

  render() {
    const orderedOvertimeRequests = _orderBy(this.props.overtimeRequests, 'projectId');
    return (
      <div className="grid tile">
        <div className="grid__row">
          <h6 className="grid__heading -span-2">{i18n.t('overtimeRequest.project')}</h6>
          <h6 className="grid__heading -span-2">{i18n.t('overtimeRequest.applicant')}</h6>
          <h6 className="grid__heading -span-2">{i18n.t('overtimeRequest.approver')}</h6>
          <h6 className="grid__heading -span-2">{i18n.t('overtimeRequest.reason')}</h6>
          <h6 className="grid__heading -span-1">{i18n.t('overtimeRequest.startDate')}</h6>
          <h6 className="grid__heading -span-1">{i18n.t('overtimeRequest.endDate')}</h6>
          <h6 className="grid__heading -span-1">{i18n.t('overtimeRequest.status')}</h6>
          <h6 className="grid__heading -span-1">{i18n.t('overtimeRequest.actions')}</h6>
        </div>
        {orderedOvertimeRequests.map(overtimeRequest => {
          const handleDeleteClick = () => this.openOvertimeRequestDeletionModal(overtimeRequest.id);

          const projectName = this.getAssociatedProjectName(overtimeRequest.projectId);
          const applicantName = this.getAssociatedEmployeeName(overtimeRequest.applicantId);
          const approverName = overtimeRequest.approverId
            ? this.getAssociatedEmployeeName(overtimeRequest.approverId)
            : `${i18n.t('overtimeRequest.none')}`;
          return (
            <OvertimeRequestListRow
              key={overtimeRequest.id}
              projectName={projectName}
              applicantName={applicantName}
              approverName={approverName}
              overtimeRequest={overtimeRequest}
              onDelete={handleDeleteClick}
              onApprove={this.handleApproveOvertimeRequest}
              onDecline={this.handleDeclineOvertimeRequest}
            />
          );
        })}

        <ConfirmModal
          ref={this.overtimeRequestDeletionModal}
          onConfirm={this.handleDeleteOvertimeRequestClick}
          title={i18n.t('overtimeRequest.confirmRemove')}
          cancelLabel={i18n.t('button.no')}
          confirmLabel={i18n.t('button.yes')}
        />
      </div>
    );
  }
}

export default OvertimeRequestList;
