import React from 'react';
import Dropdown from '@vooban/dropdown';
import { UISref, UISrefActive } from '@uirouter/react';
import i18n from 'i18next';

import Can from '../ability/Can';
import commonActions from '../../helpers/authorization/abilityActions';

const { navigateTo } = commonActions;

const MenuSection = ({ onToggle, links, isOpen, title }) => (
  <>
    <button type="button" className="menu__title" onClick={onToggle}>
      {title}
    </button>
    <Dropdown isOpen={isOpen} contentClassName="menu__content" className="menu__section">
      {links.map(stateName => (
        <Can I={navigateTo} a={stateName} key={stateName}>
          <UISrefActive class="active" key={stateName}>
            <UISref to={stateName}>
              <a href="/#" className="menu__link">{i18n.t(`title.${stateName}`)}</a>
            </UISref>
          </UISrefActive>
        </Can>
      ))}
    </Dropdown>
  </>
);

export default MenuSection;
