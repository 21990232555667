import PropTypes from 'prop-types';

const ProjectTask = {
  projectId: PropTypes.number,
  harvestTaskId: PropTypes.number,
  name: PropTypes.string,
  rate: PropTypes.number,
};

export default ProjectTask;
