import { AbilityBuilder } from '@casl/ability';
import sectionsAndPages from '../../sectionsAndPages';
import commonActions from '../abilityActions';
import { isInRole } from '../abilityHelpers';
import userRoles from '../userRoles';

const { overtimeRequestList, newOvertimeRequest } = sectionsAndPages;

export default function getRules(user) {
  const { rules, can } = AbilityBuilder.extract();
  const { navigateTo, approve, decline } = commonActions;
  const { admin, projectManager } = userRoles;

  if (user) {
    can(navigateTo, overtimeRequestList);
    can(navigateTo, newOvertimeRequest);
  }

  if (isInRole(user, [admin, projectManager])) {
    can(approve, overtimeRequestList);
    can(decline, overtimeRequestList);
  }

  return rules;
}
