import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { InputText } from '@vooban/inputs';
import Loader from '@vooban/loader';

class TimeOffUpdating extends React.PureComponent {
  static propTypes = {
    onFileUpload: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
  };

  initialValues = {
    file: null,
  };

  state = this.initialValues;

  handleFileChange = e => {
    const file = e.currentTarget.files[0];
    this.setState({ file });
    if (!file) return;

    e.currentTarget.value = '';
  };

  handleFileUpload = () => this.props.onFileUpload(this.state.file);

  render() {
    return (
      <>
        {this.props.isFetching && <Loader />}
        <div className="grid">
          <section className="grid__item -span-8">
            <div className="tile">
              <section className="form__section grid">
                <div className="grid__item -span-8">
                  <span className="grid__item -span-10">
                    <InputText
                      name="fileName"
                      disabled
                      label={i18n.t('accounting.convertedFileName')}
                      value={(this.state.file && this.state.file.name) || ''}
                    />
                  </span>
                </div>
                <div className="grid__item -span-2">
                  <label id="addPayFileLabel" htmlFor="addPayFile" className="button -primary">
                    <input
                      type="file"
                      name="addPayFile"
                      id="addPayFile"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      onChange={this.handleFileChange}
                      className="input__file"
                    />
                    {i18n.t('file.choose')}
                  </label>
                </div>
                <div />
                <section className="grid__row">
                  <div className="grid__item -align-right">
                    <button
                      type="button"
                      className="button -primary"
                      onClick={this.handleFileUpload}
                      disabled={!this.state.file}>
                      {i18n.t('upload')}
                    </button>
                  </div>
                </section>
              </section>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default TimeOffUpdating;
