import PropTypes from 'prop-types';
import {
  FINISH_TRANSITION,
  POP_BREADCRUMB,
  RESET_URL_TO_NAVIGATE,
  SET_AUTH_USER,
  SET_BREADCRUMBS,
  SET_MODAL_MESSAGE,
  SET_ROUTER_STATE,
  SET_TOAST_MESSAGE,
  SET_URL_TO_NAVIGATE,
  START_FETCHING,
  STOP_FETCHING,
  UNSET_MODAL_MESSAGE,
  UNSET_TOAST_MESSAGE,
} from '../actions/types';
import withPropTypes from '../hoc/withPropTypes';
import Breadcrumb from '../types/Breadcrumb';
import ModalMessage from '../types/ModalMessage';
import User from '../types/User';

const schema = PropTypes.shape({
  auth: PropTypes.shape({ isAuthenticated: PropTypes.bool.isRequired, user: PropTypes.shape(User) }).isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape(Breadcrumb)).isRequired,
  fetchingActions: PropTypes.arrayOf(PropTypes.string).isRequired,
  isTransitioning: PropTypes.bool,
  modalMessages: PropTypes.arrayOf(PropTypes.shape(ModalMessage)).isRequired,
  previousRouterParams: PropTypes.shape(),
  previousStateName: PropTypes.string,
  routerParams: PropTypes.shape(),
  routerStateName: PropTypes.string,
  toastMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
  urlToNavigate: PropTypes.string,
});

const initialState = {
  auth: { isAuthenticated: false, user: null },
  breadcrumbs: [],
  fetchingActions: [],
  modalMessages: [],
  isTransitioning: false,
  previousRouterParams: {},
  previousStateName: '',
  routerParams: {},
  routerStateName: '',
  toastMessages: [],
  urlToNavigate: null,
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCHING:
      return { ...state, fetchingActions: [...state.fetchingActions, action.payload] };
    case STOP_FETCHING:
      return { ...state, fetchingActions: state.fetchingActions.filter(a => a !== action.payload) };
    case SET_BREADCRUMBS:
      return { ...state, breadcrumbs: [...state.breadcrumbs, action.payload] };
    case POP_BREADCRUMB:
      return { ...state, breadcrumbs: state.breadcrumbs.slice(0, -1) };
    case SET_URL_TO_NAVIGATE:
      return { ...state, urlToNavigate: action.payload };
    case RESET_URL_TO_NAVIGATE:
      return { ...state, urlToNavigate: null };
    case SET_AUTH_USER:
      return { ...state, auth: { user: action.payload, isAuthenticated: !!action.payload } };
    case SET_TOAST_MESSAGE:
      return { ...state, toastMessages: state.toastMessages.concat(action.payload) };
    case UNSET_TOAST_MESSAGE:
      return { ...state, toastMessages: state.toastMessages.slice(1) };
    case SET_MODAL_MESSAGE:
      return { ...state, modalMessages: state.modalMessages.concat(action.payload) };
    case UNSET_MODAL_MESSAGE:
      return { ...state, modalMessages: state.modalMessages.slice(1) };
    case SET_ROUTER_STATE: {
      return {
        ...state,
        isTransitioning: true,
        routerParams: action.payload.params,
        routerStateName: action.payload.stateName || '',
      };
    }
    case FINISH_TRANSITION: {
      const hasStateChanged = action.payload.stateName !== state.routerStateName;
      const previousStateName = hasStateChanged ? state.routerStateName : state.previousStateName;
      const previousRouterParams = hasStateChanged ? state.routerParams : state.previousRouterParams;
      return {
        ...state,
        previousRouterParams,
        previousStateName,
        isTransitioning: false,
        routerParams: action.payload.params,
        routerStateName: action.payload.stateName || '',
      };
    }
    default:
      return state;
  }
};

export default withPropTypes('AppReducer', schema)(AppReducer);
