export default {
  allIn: 'All-in',
  bambooHrBalance: 'BambooHr Balance',
  convert: 'Convert',
  convertedFileName: 'Converted File Name',
  confirmAllIn: 'Do you really wish to use the All-In functionality ?',
  difference: 'Difference',
  employeeNumber: 'Employee Number',
  file: 'File',
  fileName: 'File Name',
  manualProcess: 'Manual Process',
  name: 'Name',
  payFileBalance: 'Pay File Balance',
  payNumber: 'Pay Number',
  process: 'Process',
  processTimeOff: 'Process Time Off',
  success: 'Success',
  timeOffType: 'Type Off Type',
  update: 'Update',
  year: 'Year',
};
