export default {
  address: 'Address',
  billing: 'Billing',
  billingName: 'Billing Name',
  billingEmail: 'Billing Email',
  billingPhone: 'Billing Phone',
  city: 'City',
  country: 'Country',
  confirmArchive: "Do you really want to archive '{{clientName}}' ?",
  currency: 'Currency',
  edit: 'Edit Client',
  name: 'Name',
  new: 'New Client',
  companyPhone: 'Company Phone',
  province: 'Province / State',
  responsibleEmployee: 'Responsible Employee',
  website: 'Website',
  zipCode: 'Zip Code',
};
