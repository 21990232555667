import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loader from '@vooban/loader';
import { connect } from 'react-redux';
import { createOvertimeRequest, getProjectMappings } from '../../actions';
import { ProjectMapping } from '../../types';
import { isFetchingEntities } from '../../helpers';
import OvertimeDetailsHeader from '../overtimeRequest/OvertimeDetailsHeader';
import OvertimeDetailsForm from '../overtimeRequest/OvertimeDetailsForm';
import { getProjectMappingsSelector } from '../../selectors/selectors';

class OvertimeRequestDetailsPage extends PureComponent {
  static propTypes = {
    createOvertimeRequest: PropTypes.func.isRequired,
    getProjectMappings: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    projects: PropTypes.arrayOf(PropTypes.exact(ProjectMapping)).isRequired,
  };

  componentDidMount() {
    this.props.getProjectMappings();
  }

  handleSaveButtonClick = overtimeRequest => this.props.createOvertimeRequest(overtimeRequest);

  render() {
    if (this.props.isFetching) return <Loader />;
    return (
      <div className="grid">
        <section className="grid__item -span-8">
          <OvertimeDetailsHeader />
          <OvertimeDetailsForm onSave={this.handleSaveButtonClick} projects={this.props.projects} />
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: isFetchingEntities(state, ['projectMappings', 'overtimeRequestCreation']),
  projects: getProjectMappingsSelector(state),
});

export default connect(
  mapStateToProps,
  { createOvertimeRequest, getProjectMappings }
)(OvertimeRequestDetailsPage);
