import Loader from '@vooban/loader';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { deleteProjectMapping, getHarvestProjects, getJiraProjects, getProjectMappings } from '../../actions';
import { isFetchingEntities } from '../../helpers';
import { HarvestProject, JiraProject, ProjectMapping } from '../../types';
import ProjectList from '../project/ProjectList';
import {
  getHarvestProjectsSelector,
  getJiraProjectsSelector,
  getProjectMappingsSelector,
} from '../../selectors/selectors';

class ProjectListPage extends React.PureComponent {
  static propTypes = {
    deleteProjectMapping: PropTypes.func.isRequired,
    getHarvestProjects: PropTypes.func.isRequired,
    getJiraProjects: PropTypes.func.isRequired,
    getProjectMappings: PropTypes.func.isRequired,
    harvestProjects: PropTypes.arrayOf(PropTypes.exact(HarvestProject)),
    isFetching: PropTypes.bool.isRequired,
    jiraProjects: PropTypes.arrayOf(PropTypes.exact(JiraProject)),
    projectMappings: PropTypes.arrayOf(PropTypes.exact(ProjectMapping)),
  };

  static defaultProps = {
    harvestProjects: [],
    jiraProjects: [],
    projectMappings: [],
  };

  componentDidMount() {
    this.props.getHarvestProjects();
    this.props.getJiraProjects();
    this.props.getProjectMappings();
  }

  handleDelete = id => this.props.deleteProjectMapping(id);

  render() {
    if (this.props.isFetching) return <Loader />;
    return (
      <ProjectList
        harvestProjects={this.props.harvestProjects}
        projectMappings={this.props.projectMappings}
        jiraProjects={this.props.jiraProjects}
        onDelete={this.handleDelete}
      />
    );
  }
}

const mapStateToProps = state => ({
  jiraProjects: getJiraProjectsSelector(state),
  harvestProjects: getHarvestProjectsSelector(state),
  projectMappings: getProjectMappingsSelector(state),
  isFetching: isFetchingEntities(state, ['jiraProjects', 'harvestProjects']),
});

export default connect(
  mapStateToProps,
  { deleteProjectMapping, getJiraProjects, getHarvestProjects, getProjectMappings }
)(ProjectListPage);
