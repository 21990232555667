import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import Scrollbox from '@vooban/scrollbox';
import _isEmpty from 'lodash.isempty';
import TimeSpent from '../../../types/TimeSpent';

const TimeReportingModalChild = ({ childrenTimeSpent }) => (
  <Scrollbox innerClass="modal__body grid -tabs" innerTag="section">
    {childrenTimeSpent.map(cts => (
      <Fragment key={cts.key}>
        {!_isEmpty(cts.issueWorklogs) && (
          <>
            <h6 className="grid__heading -span-12">
              {cts.key} : {cts.summary}
            </h6>
            <div className="grid__row -indent-1 -span-12">
              <h6 className="grid__heading -span-3">{i18n.t('timeReporting.employee')}</h6>
              <h6 className="grid__heading -span-3">{i18n.t('timeReporting.totalTimeSpent')}</h6>
              <h6 className="grid__heading -span-3">{i18n.t('timeReporting.newTimeSpent')}</h6>
              <h6 className="grid__heading -span-3">{i18n.t('timeReporting.maintenanceTimeSpent')}</h6>
            </div>
            {cts.issueWorklogs.map((childrenWorklog, i) => {
              const isLastElement = cts.issueWorklogs.length - 1 === i;
              return (
                <article
                  key={`${childrenWorklog.displayName}-${childrenWorklog.key}-children`}
                  className={`grid__row -indent-1 -hover -span-12 ${isLastElement ? '-last' : ''}`}>
                  <span className="grid__item -span-3">{childrenWorklog.displayName}</span>
                  <span className="grid__item -span-3">{childrenWorklog.totalTimeSpent.toFixed(2)}</span>
                  <span className="grid__item -span-3">{childrenWorklog.newTimeSpent.toFixed(2)}</span>
                  <span className="grid__item -span-3">{childrenWorklog.maintenanceTimeSpent.toFixed(2)}</span>
                </article>
              );
            })}
          </>
        )}
      </Fragment>
    ))}
  </Scrollbox>
);

TimeReportingModalChild.propTypes = {
  childrenTimeSpent: PropTypes.arrayOf(PropTypes.exact(TimeSpent)).isRequired,
};

export default TimeReportingModalChild;
