import { Transition, UIView } from '@uirouter/react';
import Loader from '@vooban/loader';
import { ErrorModal } from '@vooban/modals';
import Scrollbox from '@vooban/scrollbox';
import Toast from '@vooban/toast';
import i18n from 'i18next';
import _isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { popModalMessage, popToastMessage, setBreadcrumbs } from '../actions/appActions';
import defineAbilitiesFor from '../helpers/authorization/abilities';
import AbilityContext from './ability/AbilityContext';
import Menu from './menu/Menu';
import Breadcrumb from '../types/Breadcrumb';
import ModalMessage from '../types/ModalMessage';
import User from '../types/User';

class App extends React.PureComponent {
  static propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.exact(Breadcrumb)).isRequired,
    isAnonymousAllowed: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    modalMessages: PropTypes.arrayOf(PropTypes.exact(ModalMessage)).isRequired,
    popModalMessage: PropTypes.func.isRequired,
    popToastMessage: PropTypes.func.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    toastMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
    user: PropTypes.shape(User),
    currentStateName: PropTypes.string.isRequired,
    isTransitioning: PropTypes.bool.isRequired,
    routerParams: PropTypes.shape(),
    transition: PropTypes.shape({ router: PropTypes.shape({ stateService: PropTypes.shape({ go: PropTypes.func }) }) }),
  };

  static defaultProps = {
    user: null,
    routerParams: null,
    transition: Transition,
  };

  errorModalRef = React.createRef();

  componentDidMount() {
    if (this.props.breadcrumbs.length === 0) {
      const defaultBreadcrumb = { label: i18n.t('report'), url: '/' };
      this.props.setBreadcrumbs(defaultBreadcrumb);
    }
  }

  componentDidUpdate(prevProps) {
    if (!_isEmpty(this.props.modalMessages) && prevProps.modalMessages !== this.props.modalMessages) {
      this.errorModalRef.current.open();
    }

    if (!_isEmpty(this.props.toastMessages) && prevProps.toastMessages !== this.props.toastMessages) {
      this.displaySuccessToast();
    }

    if (this.props.isTransitioning && this.props.transition) {
      this.props.transition.router.stateService.go(this.props.currentStateName, this.props.routerParams);
    }
  }

  get defineUserAbilities() {
    return defineAbilitiesFor(this.props.user);
  }

  get modalMessage() {
    return _isEmpty(this.props.modalMessages) ? {} : this.props.modalMessages[0];
  }

  displaySuccessToast = () => {
    toast.success(<Toast message={this.props.toastMessages[0]} type="success" />, {
      onClose: () => this.props.popToastMessage(),
    });
  };

  handleModalClose = () => this.props.popModalMessage();

  renderBasedOnUserRights = () => authenticated => {
    if (this.props.isAuthenticated) return authenticated;
    return this.props.isAnonymousAllowed ? this.renderAnonymous() : <Loader />;
  };

  renderAnonymous = () => (
    <>
      <main className="layout__main">
        <header className="layout__taskbar taskbar" />
        <Scrollbox className="layout__viewport" innerClass="view" innerTag="section">
          <div />
          {/* TODO: add view for anonymous */}
        </Scrollbox>
      </main>
    </>
  );

  render() {
    return this.renderBasedOnUserRights()(
      <AbilityContext.Provider value={this.defineUserAbilities}>
        <AbilityContext.Consumer>{abilities => <Menu abilities={abilities} />}</AbilityContext.Consumer>
        <ToastContainer newestOnTop />
        <ErrorModal
          ref={this.errorModalRef}
          onOkClick={this.handleModalClose}
          text={this.modalMessage.message}
          isWarning={this.modalMessage.isWarning}
        />

        <main className="layout__main">
          <header className="layout__taskbar taskbar">
            {/* TODO : Implement Breadcrumbs when they are fixed (not using <Link>) */}
            {/* <Breadcrumbs breadcrumbs={this.props.breadcrumbs} /> */}
          </header>
          <Scrollbox className="layout__viewport" innerClass="view" innerTag="section">
            <UIView />
          </Scrollbox>
        </main>
      </AbilityContext.Provider>
    );
  }
}

const mapStateToProps = state => {
  const { auth, breadcrumbs, modalMessages, toastMessages, isTransitioning, routerParams } = state.app;
  const { isAuthenticated, user } = auth;
  const isAnonymousAllowed = true;

  return {
    breadcrumbs,
    isAnonymousAllowed,
    isAuthenticated,
    modalMessages,
    toastMessages,
    user,
    currentStateName: state.app.routerStateName,
    isTransitioning,
    routerParams,
  };
};

export default connect(
  mapStateToProps,
  { popModalMessage, popToastMessage, setBreadcrumbs }
)(App);
