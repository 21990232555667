import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const getSuccessIcon = () => <FontAwesomeIcon icon="check-circle" style={{ color: 'green' }} />;

export const getFailureIcon = () => <FontAwesomeIcon icon="times-circle" style={{ color: 'red' }} />;

export const getWarningIcon = () => <FontAwesomeIcon icon="exclamation-triangle" style={{ color: 'orange' }} />;

export const getDangerIcon = () => <FontAwesomeIcon icon="exclamation-circle" style={{ color: 'red' }} />;
